import { Fragment, useState } from "react"
import { Link } from "react-router-dom";
import styled from "styled-components";
import DialogModal from "components/Modal/Dialog";
import RadioButton from "components/RadioButton";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from '@mui/material/Button'
import useMutationCustom from "hooks/useMutationCustom";
import { useToasts } from "react-toast-notifications";
import isPast from 'date-fns/isPast';
import isToday from 'date-fns/isToday';
import formatInTimeZone from "date-fns-tz/formatInTimeZone";

import { ReactComponent as WarningSign } from 'assets/svg/exclamation_mark-sign.svg';
import { ReactComponent as PersonIcon } from 'assets/svg/person_with_plus-circle.svg';
import { ReactComponent as ResendIcon } from 'assets/svg/refresh-arrows_circle.svg';
import { ReactComponent as CancelIcon } from 'assets/svg/close-circle.svg';
import { ReactComponent as WarningMark } from 'assets/svg/warning-mark_circles.svg';

type TMutationData = { errors?: [{ message: string }] };

export default function OnboardingInfoBox({ person, refreshEmployeeInfo }: { person: any, refreshEmployeeInfo: any }) {
  const { addToast } = useToasts();
  const [isCancelModalOpen, setCancelModalOpen] = useState<boolean>(false);
  const [isInviteInfoModalOpen, setInviteInfoModalOpen] = useState<boolean>(false);
  const [isCancelInfoModalOpen, setCancelInfoModalOpen] = useState<boolean>(false);
  const [isAllowedAccess, setAllowedAccess] = useState<string>('');
  const [isApproveModalOpen, setApproveModalOpen] = useState<boolean>(false);
  const [isApproveInfoModalOpen, setApproveInfoModalOpen] = useState<boolean>(false);

  const { mutate: reinviteOnboarding, error: reinviteError } = useMutationCustom<string[], TMutationData, {}>(["onboarding_reinvite"], {
    endpoint: 'onboarding/admin/re_invite', options: { method: "post" },
  }, {
    onSuccess: () => {
      addToast("A new Invitation Link has been sent successfully", { appearance: 'success', autoDismiss: true });
    },
    onError: () => setInviteInfoModalOpen(true),
  });

  const { mutate: cancelOnboarding, isLoading: cancelLoading, error: cancelError } = useMutationCustom<string[], TMutationData, {}>(["onboarding_cancel"], {
    endpoint: 'onboarding/admin/cancel', options: { method: "post" },
  }, {
    onSuccess: () => {
      addToast("Self-Onboarding process has been canceled", { appearance: 'success', autoDismiss: true });
      refreshEmployeeInfo();
      setCancelModalOpen(false);
    },
    onError: () => { setCancelInfoModalOpen(true); setCancelModalOpen(false); },
  });

  const { mutate: approveOnboarding, isLoading: approveLoading, error: approveError } = useMutationCustom<string[], TMutationData, {}>(["onboarding_approve"], {
    endpoint: 'onboarding/admin/approve', options: { method: "post" },
  }, {
    onSuccess: () => {
      addToast("Employee information has been approved", { appearance: 'success', autoDismiss: true });
      refreshEmployeeInfo();
      setApproveModalOpen(false);
    },
    onError: () => { setApproveInfoModalOpen(true); setApproveModalOpen(false); },
  });

  const renderStatusTitle = (status: string) => {
    if (status === 'in_progress') return 'Self-Onboarding In Progress';
    if (status === 'filled') return `${person.first_name}'s Information Is Ready for Your Review`;
    if (status === 'i9_to_sign') return `${person.first_name}'s Form I-9 is waiting for your review and signature`;
  };

  const renderStatusDescText = (status: string, i9form: boolean, documents_uploaded: boolean, firstDay: any) => {
    if (status === 'in_progress' && i9form) {
      return (
        <p>{person.first_name} has been invited to Self-Onboarding to fill in details such as Personal Information,
          Address, Contact Information, Emergency Contact, Documents and Employment Eligibility.<br />
          You will get a notification as soon as {person.first_name} finishes.
          You can then review this information, approve and sign Section 2 of Form I-9 to finalize {person.first_name}'s hiring process.</p>
      );
    };
    if (status === 'in_progress' && !i9form) {
      return (
        <p>{person.first_name} has been invited to Self-Onboarding to fill in details such as Personal Information,
          Address, Contact Information, Emergency Contact, Documents.<br />
          You will get a notification as soon as {person.first_name} finishes.
          You can then review this information and approve to finalize {person.first_name}'s hiring process.</p>
      );
    };
    if (status === 'filled' && i9form) {
      return (
        documents_uploaded ? <p>
          {person.first_name} has completed personal details, uploaded documents and signed Section 1 of Form I-9.
          Please review and approve {person.first_name}'s information to start Section 2 of Form I-9.
        </p>
          : <p>
            {person.first_name} has completed personal details and signed Section 1 of Form I-9.
            Please review and approve {person.first_name}'s information to start Section 2 of Form I-9.
          </p>
      );
    };
    if (status === 'filled' && !i9form) {
      return (
        documents_uploaded ? <p>
          {person.first_name} has completed personal details and uploaded documents.
          Please review and approve {person.first_name}'s information
        </p>
          : <p>
            {person.first_name} has completed personal details.
            Please review and approve {person.first_name}'s information
          </p>
      );
    };
    if (status === 'i9_to_sign' && firstDay) {
      if (isPast(new Date(firstDay.date)) && !isToday(new Date(firstDay.date))) {
        return <p>{person.first_name}'s first day was {formatInTimeZone(new Date(firstDay.date), 'UTC', 'PPPP')}</p>
      } else return <p>{person.first_name}'s first day is {formatInTimeZone(new Date(firstDay.date), 'UTC', 'PPPP')}</p>
    };
  };

  const renderStatusActions = (status: string) => {
    if (status === 'in_progress') {
      return (
        <Fragment>
          You Can
          {person.onboarding.onboarding_status.id_name === 'in_progress' ?
            <ActionsWithIconContainer onClick={() => reinviteOnboarding({ employee_id: person.id })}>
              <PersonIcon />
              <p>Resend the Invitation,</p>
            </ActionsWithIconContainer> : null}
          <ActionsWithIconContainer>
            <ResendIcon />
            <p><Link to={`/onboarding/update/${person.id}`}>Update Onboarding</Link></p>
          </ActionsWithIconContainer>
          or
          <ActionsWithIconContainer onClick={() => setCancelModalOpen(true)}>
            <CancelIcon />
            <p>Cancel Onboarding and Enter Details Yourself</p>
          </ActionsWithIconContainer>
        </Fragment>
      )
    };
    if (status === 'filled') {
      return (
        <Button
          variant="contained"
          size="large"
          onClick={() => setApproveModalOpen(true)}
        >
          APPROVE INFORMATION
        </Button>
      )
    };
    if (status === 'i9_to_sign') {
      return (
        <Link to={`/onboarding/review/${person.id}`}>
          <Button
            variant="contained"
            size="large"
          >
            REVIEW AND SIGN FORM I-9
          </Button>
        </Link>
      )
    };
  };

  return (
    <Fragment>
      <OnboardingInfoBoxContainer>
        <InfoBoxTitle>
          <WarningSign />
          <p>{renderStatusTitle(person.onboarding.onboarding_status.id_name)}</p>
          {renderStatusDescText(
            person.onboarding.onboarding_status.id_name,
            person.onboarding.fill_i9,
            person.onboarding.documents_uploaded,
            person.onboarding.first_day
          )}
        </InfoBoxTitle>
        <InfoBoxActionsContainer>
          {renderStatusActions(person.onboarding.onboarding_status.id_name)}
        </InfoBoxActionsContainer>
      </OnboardingInfoBoxContainer>
      <DialogModal
        open={isCancelModalOpen}
        onClose={() => { setCancelModalOpen(false); setAllowedAccess(''); }}
        withButtons
        upperPosition
        actionButton={() => cancelOnboarding({ employee_id: person.id, allow_access: isAllowedAccess === 'yes' ? true : false })}
        fullWidth
        title={`Cancel ${person.first_name}'s Self-Onboarding`}
        cancelButtonText="NO, DON'T CANCEL"
        actionButtonText="YES, CANCEL SELF-ONBOARDING"
        actionButtonTooltipText={isAllowedAccess ? '' : 'Please fill System Access option'}
        actionButtonDisabled={isAllowedAccess ? false : true}
        actionLoading={cancelLoading}
      >
        <ModalContentContainer>
          <WarningMark />
          <p>Are you sure you want to cancel the Self-Onboarding process for {person.first_name}?</p>
          <p>{person.first_name} will no longer be able to Self-Onboard. But you can still provide {person.first_name} with access to Profesto.</p>
          <RadioGroup value={isAllowedAccess} onChange={(_, value) => setAllowedAccess(value)} sx={{ paddingBlock: 2 }} row>
            <FormControlLabel value={'yes'} control={<RadioButton />} label="Allow Access to Profesto" />
            <FormControlLabel value={'no'} control={<RadioButton />} label="Disable System Access" />
          </RadioGroup>
        </ModalContentContainer>
      </DialogModal>

      <DialogModal
        open={isInviteInfoModalOpen}
        onClose={() => setInviteInfoModalOpen(false)}
        withButtons
        upperPosition
        actionButton={() => setInviteInfoModalOpen(false)}
        fullWidth
        actionButtonText="CLOSE"
      >
        <ModalContentContainer>
          <WarningMark />
          <p>A new invitation Link can't be sent</p>
          <p>{reinviteError?.errors?.[0].message}</p>
        </ModalContentContainer>
      </DialogModal>

      <DialogModal
        open={isCancelInfoModalOpen}
        onClose={() => { setCancelInfoModalOpen(false); refreshEmployeeInfo(); }}
        withButtons
        upperPosition
        actionButton={() => { setCancelInfoModalOpen(false); refreshEmployeeInfo(); }}
        fullWidth
        actionButtonText="CLOSE"
      >
        <ModalContentContainer>
          <WarningMark />
          <p>Self-Onboarding process can't be canceled!</p>
          <p>{cancelError?.errors?.[0].message}</p>
        </ModalContentContainer>
      </DialogModal>

      <DialogModal
        open={isApproveModalOpen}
        onClose={() => setApproveModalOpen(false)}
        title={`Approve ${person.first_name}'s Information`}
        withButtons
        upperPosition
        actionButton={() => approveOnboarding({ employee_id: person.id })}
        fullWidth
        cancelButtonText="NO, NOT YET"
        actionButtonText="YES, APPROVE"
        actionLoading={approveLoading}
      >
        <ModalContentContainer>
          <WarningMark />
          <p style={{ fontSize: 14 }}>Are you sure you want to approve {person.first_name}'s information?</p>
        </ModalContentContainer>
      </DialogModal>

      <DialogModal
        open={isApproveInfoModalOpen}
        onClose={() => { setApproveInfoModalOpen(false); refreshEmployeeInfo(); }}
        withButtons
        upperPosition
        actionButton={() => { setApproveInfoModalOpen(false); refreshEmployeeInfo(); }}
        fullWidth
        actionButtonText="CLOSE"
      >
        <ModalContentContainer>
          <WarningMark />
          <p>Employee information can't be approved!</p>
          <p>{approveError?.errors?.[0].message}</p>
        </ModalContentContainer>
      </DialogModal>
    </Fragment>
  )
};

const OnboardingInfoBoxContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 188px;
  background-color: #FCF2E4;
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 10px 20px;
`;

const InfoBoxTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  & > svg {
    margin-bottom: 14px;
  };
  & > p:first-of-type {
    font-size: 14px;
    font-family: 'Aspira Demi';
    color: #414141;
    margin-bottom: 14px;
  }
  & > p:last-of-type {
    font-size: 13px;
    color: #414141;
    margin-bottom: 14px;
  };
`;

const InfoBoxActionsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #414141;
  font-size: 13px;
`;

const ActionsWithIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-inline: 5px;
  cursor: pointer;
  & > svg {
    margin-right: 4px;
  };
  & > p {
    font-size: 13px;
    font-family: 'Aspira Demi';
    color: #F4906A;
    text-decoration: underline;
  };
  &:hover > svg {
    path:not(:first-child) {
      fill: #B46041;
    };
  };
  &:hover > p {
      color: #B46041;
  };
`;

const ModalContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 30px 20px;
  & > svg {
    margin-bottom: 25px;
  };
  & > p:first-of-type {
    font-size: 14px;
    font-family: 'Aspira Demi';
    color: #414141;
    margin-bottom: 14px;
  }
  & > p:last-of-type {
    font-size: 13px;
    color: #676767;
    margin-bottom: 14px;
  };
`;