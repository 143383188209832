import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { saveStorageObject } from "utils/storage";
import {
    createCompany,
    getCurrentUser,
    getCompanies,
    switchCompany
} from "services";
import { useForm } from "react-hook-form";
import Popover from '@mui/material/Popover';
import DialogModal from "components/Modal/Dialog";
import { useToasts } from "react-toast-notifications";
import UniversalInput from 'components/Input/UniversalInput';
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import { setAuthorizationToken } from "services/mainAxios";
import { setCurrentUser, setDomain, setToken } from "redux/authSlice";
import { currentUserSelector } from "redux/selectors";

import { ReactComponent as FourSquareIcon } from "assets/svg/four-square_circle.svg";
import { ReactComponent as ArrowDownIcon } from "assets/svg/arrow.svg";
import { ReactComponent as SelectedIcon } from 'assets/svg/mark_circle.svg';
import { ReactComponent as PlusIcon } from 'assets/svg/plus.svg';

const CompanyActions = () => {
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        setError,
        clearErrors,
        formState: { errors }
    } = useForm({
        shouldFocusError: true,
        defaultValues: {
            company_name: '',
        } as any
    });
    const [companyOpen, setCompanyOpen] = useState<boolean>(false)
    const [addCompanyOpen, setAddCompanyOpen] = useState<boolean>(false)
    const [companies, setCompanies] = useState([]);
    const dispatch = useDispatch();
    const [loadingRequest, setLoadingRequest] = useState<boolean>(false)
    const watchCompany = watch('company_name');
    const { addToast } = useToasts();
    const [companyAnchorEl, setCompanyAnchorEl] = useState<HTMLButtonElement | null>(null);
    const currentUser = useSelector(currentUserSelector)

    const companyClick = (event: any) => {
        setCompanyAnchorEl(event.currentTarget)
        setCompanyOpen(true)
    };

    const switchCompanyAction = (id: number) => {
        setCompanyOpen(false)
        switchCompany(id).then((res: any) => {
            reloadToCompany(res.data)
        })
    };

    const onSubmit = (data: any) => {
        setLoadingRequest(true)

        createCompany(data).then(res => {
            setLoadingRequest(false)
            closeCompanyAdd()
            reloadToCompany(res.data)
        }).catch(err => {
            setLoadingRequest(false)
            err.response.data.errors.forEach((item: any) => {
                if (item.field && item.field === 'base') {
                    setError(item.field, { type: 'string', message: item.message })
                } else {
                    addToast(item.message, { appearance: 'error', autoDismiss: true });
                }
            });
        });
    };

    const reloadToCompany = (data: any) => {
        saveStorageObject('token', data.token);
        saveStorageObject('refresh_token', data.refresh_token);
        setAuthorizationToken(data.token);
        dispatch(setToken(data.token));
        getCurrentUser().then(res => {
            dispatch(setDomain(res.data.company.id));
            dispatch(setCurrentUser(res.data));
        })
        window.location.href = "/people"
    };

    const companyAddClick = () => {
        setCompanyOpen(false)
        setAddCompanyOpen(true)
    };

    const closeCompanyAdd = () => {
        setCompanyOpen(false)
        setAddCompanyOpen(false)
    }

    useEffect(() => {
        if (companyOpen) {
            setValue('company_name', null)
            clearErrors()
            getCompanies().then(res => {
                setCompanies(res.data)
            })
        }
    }, [companyOpen])

    const onError = (err: any) => {
        if (err) {
            addToast(<ToastContentContainer>
                <b>There were some errors with your form.</b> Please Fix The Highlighted Fields Below
            </ToastContentContainer>, {
                appearance: 'error',
                autoDismiss: true,
                placement: 'top-center'
            });
        }
    };

    return (
        <Fragment>
            <CompanyWrapper $active={companyOpen} onClick={(e) => companyClick(e)}>
                <div style={{ width: 35 }}><FourSquareIcon /></div>
                <p>{currentUser?.company?.name}</p>
                <div style={{ width: 14, height: 38, display: 'flex', alignItems: 'center', marginLeft: 'auto', marginRight: 2 }}>
                    <StyledArrowDownIcon $active={companyOpen} />
                </div>
            </CompanyWrapper>

            <Popover
                open={companyOpen}
                anchorEl={companyAnchorEl}
                onClose={() => setCompanyOpen(false)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: -18,
                    horizontal: 'right',
                }}
            >
                <CompanyListWrapper>
                    <CompanyHeader>
                        <p>Switch To Another Company</p>
                        <div>{companies.length > 1 ?
                            `You Have Roles In ${companies.length} Companies. To Navigate To A Company’s System Select The Company Below` :
                            `You have an access to only one company. Need to add another company?`}
                        </div>
                    </CompanyHeader>

                    {companies.length ? companies.map((item: any) =>
                        <div key={item.id}>
                            <Divider />
                            <CompanyBody current={item.current}
                                onClick={() => item.current ? '' : switchCompanyAction(item.id)}>
                                <p>{item.name}</p>
                                <div>
                                    {item.current && <SelectedIcon style={{ marginRight: 7 }} />}
                                    {item.current ? 'Currently Signed In As ' : 'Sign In As '}{item.role}
                                </div>
                            </CompanyBody>
                        </div>
                    ) : <LinearProgress />}
                    <Divider style={{ marginBottom: 10 }} />

                    <div style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: 12 }}>
                        <Button startIcon={<StyledPlusIcon />} type="button" size='large' variant='contained'
                            style={{ width: 200 }} onClick={() => companyAddClick()}>
                            Add a New Company
                        </Button>
                    </div>
                </CompanyListWrapper>
            </Popover>

            {addCompanyOpen && <DialogModal
                open={addCompanyOpen}
                title='Add Company'
                onClose={() => closeCompanyAdd()}
                actionButton={handleSubmit(onSubmit, onError)}
                withButtons
                cancelButtonText='CANCEL'
                actionButtonText='SUBMIT'
                actionLoading={loadingRequest}
                upperPosition
            >
                <AddCompanyWrapper>
                    <div style={{ textAlign: 'center' }}>
                        <HeaderText>Add a New Company</HeaderText>
                        <AddText>Need to add another company to Profesto? We're ready to set up your
                            <span style={{ fontWeight: 'bold' }}> BASIC </span> Package for the new company!</AddText>
                    </div>

                    <InputWrapper>
                        <UniversalInput
                            size='small'
                            maxLength='250'
                            placeholder='Company Name'
                            visiblePlaceholder={watchCompany ? true : false}
                            errorText={
                                errors.company_name ? 'Please Enter Your Company Name' : ""
                            }
                            {...register("company_name", { required: true, maxLength: 250 })}
                        />
                    </InputWrapper>
                    <TOSContainer>
                        By creating an account, you agree to the <a target={'_blank'} rel="noreferrer" href='https://profesto.net/terms-of-service/'>
                            Terms and Conditions
                        </a>
                    </TOSContainer>
                </AddCompanyWrapper>
            </DialogModal>}
        </Fragment>
    );
};

export default CompanyActions;

const ToastContentContainer = styled.div`
    & > b {
        font-family: 'Aspira Demi';
    }
`;

const CompanyWrapper = styled('div')<{ $active?: boolean }>(({ theme, $active }) => ({
    width: "16rem",
    height: 40,
    background: $active ? '#215549' : '#243844',
    borderRadius: 30,
    paddingRight: 10,
    color: "#FFFFFF",
    fontSize: 13,
    marginRight: 12,
    alignItems: "center",
    display: "flex",
    lineHeight: 1,
    cursor: "pointer",
    overflow: "hidden",
    "&:hover": { background: "#215549" },
    "& > p": {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        paddingRight: 5
    },
}));

const StyledArrowDownIcon = styled(ArrowDownIcon) <{ $active?: boolean }>`
    transform: ${(props) => { return props.$active ? 'rotate(180deg)' : 'rotate(0deg)' }};
    width: 15px;
    height: 15px;
    top: 3px;
    path{
      fill: #FFF;
    }
`;

const CompanyListWrapper = styled.div`
    width: 412px;
    padding: 15px 0px;
`;

const CompanyHeader = styled.div`
    color: #676767;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 12px;
    
    p {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 4px;
        padding-left: 3px;
    }
    
    div {
        font-size: 12px;
        padding-left: 3px;
        padding-right: 3px;
    }
`

const CompanyBody = styled.div <{ current?: boolean }>`
    cursor: ${(props) => { return props.current ? 'auto' : 'pointer' }};
    padding-top: 13px;
    padding-bottom: 15px;
    padding-left: 12px;
    padding-right: 12px;
    
    &:hover {
        background: #DCEEE5;
    }
    
    p {
        color: #339966;
        font-weight: 500; 
        padding-left: 3px;
        font-size: 14px;
    }
    
    div {
        color:  ${(props) => { return props.current ? '#77B448' : '#00101A' }};
        font-size: 12px;
        padding-left: 3px;
        display: flex;
        align-items: center;
        height: 15px;
        margin-top: 2px;
    }
`

const Divider = styled.div`
    border-top: 1px solid #F1F1F1;
    margin-left: 12px;
    margin-right: 12px;
`

const StyledPlusIcon = styled(PlusIcon)`
    margin-right: 5px;
    path{
        fill: #FFF;
    }
`;

const AddCompanyWrapper = styled.div`
    padding: 20px 40px;
    color: #676767;
    width: 500px;
`;

const HeaderText = styled.div`
    font-size: 20px;
    margin-bottom: 16px;
    font-family: 'Aspira Demi';
`

const AddText = styled.div`
    color: #676767;
    font-size: 13px;
    margin-bottom: 45px;
    line-height: 1.5;
`

const InputWrapper = styled.div`
    margin-bottom: 15px;
`

const TOSContainer = styled('div')`
  text-align: center;
  font-size: 14px;
  color: #172B37;
  & > a {
    font-family: 'Aspira Demi';
    text-decoration: underline;
    cursor: pointer;
  }
`;