import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  GridColDef,
  GridExceljsProcessInput,
  GridInitialState,
  GridLinkOperator,
  GridRenderCellParams,
  GridState,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridPreferencePanelParams,
  GridToolbarQuickFilter,
  GridToolbarExportContainer,
  useGridApiContext,
  useGridApiRef,
  GridExportMenuItemProps,
  GridExcelExportOptions,
  gridFilterModelSelector,
} from "@mui/x-data-grid-premium";
import LoadingButton from "@mui/lab/LoadingButton";
import MenuItem from "@mui/material/MenuItem";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import queryString from 'query-string';
import DataGrid from "components/DataLists/DataGrid";
import useQuery from "hooks/useQueryCustom";
import EmployeeCard from "components/Employee/Card";
import { PageHeaderTitle } from "components/DesignUIComponents";
import { uniqBy, isEmpty } from "lodash";
import format from "date-fns/format";
import { formatInTimeZone } from 'date-fns-tz'
import { setPeopleDirPath } from "redux/personSlice";
import { saveStorageObject } from "utils/storage";
import { useSelector } from "react-redux";
import { currentUserSelector } from "redux/selectors";
import ExceededModal from "containers/people/ExceededModal";

import { ReactComponent as ListIcon } from 'assets/svg/list-icon.svg';
import { ReactComponent as DirectoryIcon } from 'assets/svg/directory-icon.svg';
import { ReactComponent as MagnifierIcon } from 'assets/svg/magnifier.svg';
import { ReactComponent as CloseIcon } from 'assets/svg/close-icon.svg';
import { ReactComponent as ActionRequiredIcon } from 'assets/svg/calendar-circle.svg';

const renderAvatarCell = (params: GridRenderCellParams, type: string) => {
  return params.row[type].first_name ? <EmployeeCard fontSize={14} employee={params.row[type]} /> : "-"
};

const columns: GridColDef[] = [
  {
    field: 'employee',
    headerName: 'Employee',
    renderCell: (params) => renderAvatarCell(params, 'employee'),
    valueGetter: ({ value }) => value.first_name ? `${value.first_name} ${value.last_name}` : '',
    minWidth: 150,
    flex: 1
  },
  { field: 'employee_id', headerName: 'Employee #', minWidth: 100, flex: 0.6 },
  { field: 'status', headerName: 'Status', type: 'singleSelect', valueOptions: ['Hiring', 'Active', 'Terminated'], minWidth: 100, flex: 0.6 },
  { field: 'employment_status', headerName: 'Employment Status', minWidth: 100, flex: 1 },
  { field: 'job_title', headerName: 'Job Title', minWidth: 100, flex: 1 },
  { field: 'department', headerName: 'Department', minWidth: 100, flex: 1 },
  { field: 'division', headerName: 'Division', minWidth: 100, flex: 1 },
  { field: 'location', headerName: 'Location', minWidth: 100, flex: 1 },
  { field: 'first_name', headerName: 'First Name', minWidth: 100, flex: 1 },
  { field: 'middle_name', headerName: 'Middle Name', minWidth: 100, flex: 1 },
  { field: 'last_name', headerName: 'Last Name', minWidth: 100, flex: 1 },
  { field: 'preferred_name', headerName: 'Preferred Name', minWidth: 100, flex: 1 },
  { field: 'has_photo', headerName: 'Photo Uploaded', type: 'boolean', valueFormatter: ({ value }) => value ? 'Yes' : "No", minWidth: 100, flex: 0.6 },
  {
    field: 'birth_date',
    headerName: 'Birth Date',
    type: 'date',
    valueGetter: ({ value }) => value && new Date(value),
    valueFormatter: ({ value }) => value && formatInTimeZone(new Date(value), 'UTC', 'P'),
    minWidth: 100, flex: 1
  },
  {
    field: 'birth_day',
    headerName: 'Birthday',
    type: 'date',
    valueGetter: ({ value }) => value && new Date(value),
    valueFormatter: ({ value }) => formatInTimeZone(new Date(value), 'UTC', 'MMM dd'),
    minWidth: 100,
    flex: 0.6
  },
  { field: 'age', headerName: 'Age', minWidth: 100, flex: 0.6 },
  { field: 'place_of_birth', headerName: 'Place of Birth', minWidth: 100, flex: 1 },
  { field: 'gender_name', headerName: 'Gender', minWidth: 100, flex: 1 },
  { field: 'marital_status_name', headerName: 'Marital Status', minWidth: 100, flex: 1 },
  { field: 'nationality_name', headerName: 'Nationality', minWidth: 100, flex: 1 },
  { field: 'citizenship_name', headerName: 'Citizenship', minWidth: 100, flex: 1 },
  { field: 'preferred_language_name', headerName: 'Preferred Language', minWidth: 100, flex: 1 },
  { field: 'shirt_size_name', headerName: 'Shirt size', minWidth: 100, flex: 1 },
  { field: 'allergies', headerName: 'Allergies', minWidth: 100, flex: 1 },
  { field: 'dietary_restrictions', headerName: 'Dietary Restrictions', minWidth: 100, flex: 1 },
  { field: 'home_address_country', headerName: 'Country (Home Address)', minWidth: 100, flex: 1 },
  { field: 'home_address_name', headerName: 'Address line 1 (Home Address)', minWidth: 100, flex: 1 },
  { field: 'home_address_details', headerName: 'Address line 2 (Home Address)', minWidth: 100, flex: 1 },
  { field: 'home_city', headerName: 'City (Home Address)', minWidth: 100, flex: 1 },
  { field: 'home_state', headerName: 'State / Province / Region (Home Address)', minWidth: 100, flex: 1 },
  { field: 'home_postal_code', headerName: 'Zip / Postal Code (Home Address)', minWidth: 100, flex: 1 },
  { field: 'mailing_address_country', headerName: 'Country (Mailing Address)', minWidth: 100, flex: 1 },
  { field: 'mailing_address_name', headerName: 'Address line 1 (Mailing Address)', minWidth: 100, flex: 1 },
  { field: 'mailing_address_details', headerName: 'Address line 2 (Mailing Address)', minWidth: 100, flex: 1 },
  { field: 'mailing_city', headerName: 'City (Mailing Address)', minWidth: 100, flex: 1 },
  { field: 'mailing_state', headerName: 'State / Province / Region (Mailing Address)', minWidth: 100, flex: 1 },
  { field: 'mailing_postal_code', headerName: 'Zip / Postal Code (Mailing Address)', minWidth: 100, flex: 1 },
  { field: 'work_phone', headerName: 'Work Phone', minWidth: 100, flex: 1 },
  { field: 'work_phone_ext', headerName: 'Work Phone Ext', minWidth: 100, flex: 1 },
  { field: 'mobile_phone', headerName: 'Mobile Phone', minWidth: 100, flex: 1 },
  { field: 'home_phone', headerName: 'Home Phone', minWidth: 100, flex: 1 },
  { field: 'work_email', headerName: 'Work Email', minWidth: 100, flex: 1 },
  { field: 'personal_email', headerName: 'Personal Email', minWidth: 100, flex: 1 },
  { field: 'linkedin', headerName: 'LinkedIn URL', minWidth: 100, flex: 1 },
  { field: 'twitter', headerName: 'Twitter Feed', minWidth: 100, flex: 1 },
  { field: 'facebook', headerName: 'Facebook URL', minWidth: 100, flex: 1 },
  {
    field: 'hire_date',
    headerName: 'Hire Date',
    type: 'date',
    valueGetter: ({ value }) => value && new Date(value),
    valueFormatter: ({ value }) => value && formatInTimeZone(new Date(value), 'UTC', 'P'),
    minWidth: 100, flex: 0.6
  },
  {
    field: 'probation_end_date',
    headerName: 'Probation End Date',
    type: 'date',
    valueGetter: ({ value }) => value && new Date(value),
    valueFormatter: ({ value }) => value && formatInTimeZone(new Date(value), 'UTC', 'P'),
    minWidth: 100, flex: 0.6
  },
  {
    field: 'contract_end_date',
    headerName: 'Contract End Date',
    type: 'date',
    valueGetter: ({ value }) => value && new Date(value),
    valueFormatter: ({ value }) => value && formatInTimeZone(new Date(value), 'UTC', 'P'),
    minWidth: 100, flex: 0.6
  },
  {
    field: 'job_effective_date',
    headerName: 'Job Effective Date',
    type: 'date',
    valueGetter: ({ value }) => value && new Date(value),
    valueFormatter: ({ value }) => value && formatInTimeZone(new Date(value), 'UTC', 'P'),
    minWidth: 100, flex: 0.6
  },
  { field: 'experience', headerName: 'Experience', minWidth: 100, flex: 1 },
  { field: 'experience_years', headerName: 'Experience (Years)', minWidth: 100, flex: 0.6 },
  {
    field: 'manager',
    headerName: 'Reports To (Manager)',
    renderCell: (params) => renderAvatarCell(params, 'manager'),
    valueGetter: ({ value }) => value.first_name ? `${value.first_name} ${value.last_name}` : '',
    minWidth: 100, flex: 1
  },
  { field: 'direct_reports', headerName: 'Direct Reports', minWidth: 100, flex: 1 },
  { field: 'job_change_reason', headerName: 'Job Change Reason', minWidth: 100, flex: 1 },
  { field: 'job_change_comment', headerName: 'Job Change Comment', minWidth: 100, flex: 1 },
  {
    field: 'termination_date',
    headerName: 'Termination Date',
    type: 'date',
    valueGetter: ({ value }) => value && new Date(value),
    valueFormatter: ({ value }) => value && formatInTimeZone(new Date(value), 'UTC', 'P'),
    minWidth: 100, flex: 0.6
  },
  { field: 'job_termination_type', headerName: 'Termination Type', minWidth: 100, flex: 1 },
  { field: 'job_termination_reason', headerName: 'Termination Reason', minWidth: 100, flex: 1 },
  { field: 'rehire_eligibility', headerName: 'Eligible for Rehire', minWidth: 100, flex: 1 },
  { field: 'termination_comment', headerName: 'Termination Comment', minWidth: 100, flex: 1 },
  {
    field: 'compensation_effective_date',
    headerName: 'Compensation Effective Date',
    type: 'date',
    valueGetter: ({ value }) => value && new Date(value),
    valueFormatter: ({ value }) => value && formatInTimeZone(new Date(value), 'UTC', 'P'),
    minWidth: 100, flex: 1
  },
  {
    field: 'pay_amount',
    headerName: 'Pay Rate (Amount)',
    valueGetter: ({ value }) => Number(value).toFixed(2),
    type: 'number',
    minWidth: 100, flex: 1
  },
  { field: 'currency', headerName: 'Pay Rate (Currency)', minWidth: 100, flex: 1 },
  { field: 'payment_period', headerName: 'Pay Rate (Period)', minWidth: 100, flex: 1 },
  { field: 'payment_schedule', headerName: 'Pay Schedule', minWidth: 100, flex: 1 },
  { field: 'payment_type', headerName: 'Pay Type', minWidth: 100, flex: 1 },
  { field: 'overtime_status', headerName: 'Overtime Status', minWidth: 100, flex: 1 },
  { field: 'compensation_change_reason', headerName: 'Compensation Change Reason', minWidth: 100, flex: 1 },
  { field: 'compensation_comment', headerName: 'Compensation Change Comment', minWidth: 100, flex: 1 },
  { field: 'em_contact_name', headerName: 'Emergency Contact Name', minWidth: 100, flex: 1 },
  { field: 'em_contact_relationship', headerName: 'Emergency Contact Relationship', minWidth: 100, flex: 1 },
  { field: 'em_contact_work_phone', headerName: 'Emergency Contact Work Phone', minWidth: 100, flex: 1 },
  { field: 'em_contact_work_phone_ext', headerName: 'Emergency Contact Work Phone Ext', minWidth: 100, flex: 1 },
  { field: 'em_contact_mobile_phone', headerName: 'Emergency Contact Mobile Phone', minWidth: 100, flex: 1 },
  { field: 'em_contact_home_phone', headerName: 'Emergency Contact Home Phone', minWidth: 100, flex: 1 },
  { field: 'em_contact_email', headerName: 'Emergency Contact Email', minWidth: 100, flex: 1 },
  { field: 'em_contact_country', headerName: 'Emergency Contact Country', minWidth: 100, flex: 1 },
  { field: 'em_contact_address', headerName: 'Emergency Contact Address line 1', minWidth: 100, flex: 1 },
  { field: 'em_contact_address_details', headerName: 'Emergency Contact Address line 2', minWidth: 100, flex: 1 },
  { field: 'em_contact_city', headerName: 'Emergency Contact City', minWidth: 100, flex: 1 },
  { field: 'em_contact_state', headerName: 'Emergency Contact State / Province / Region', minWidth: 100, flex: 1 },
  { field: 'em_contact_postal_code', headerName: 'Emergency Contact Zip / Postal Code', minWidth: 100, flex: 1 },
  { field: 'action_required', headerName: 'Action Required', type: 'singleSelect', valueOptions: ['Yes', 'No'], minWidth: 130, flex: 0.6 },
  { field: 'employee_user_status', headerName: 'User Status', minWidth: 100, flex: 1 },
  { field: 'role', headerName: 'User Role', minWidth: 100, flex: 1 },
  {
    field: 'last_login_date',
    headerName: 'Last Login',
    type: 'dateTime',
    valueGetter: ({ value }) => value && new Date(value),
    valueFormatter: ({ value }) => value && format(new Date(value), 'Ppp'),
    minWidth: 100, flex: 0.6
  },
  {
    field: 'join_date',
    headerName: 'Date of Joining',
    type: 'dateTime',
    valueGetter: ({ value }) => value && new Date(value),
    valueFormatter: ({ value }) => value && format(new Date(value), 'Ppp'),
    minWidth: 100, flex: 0.6
  },
  {
    field: 'created_by',
    headerName: 'Created by User',
    renderCell: (params) => renderAvatarCell(params, 'created_by'),
    valueGetter: ({ value }) => value.first_name ? `${value.first_name} ${value.last_name}` : '',
    minWidth: 100,
    flex: 1,
  },
  {
    field: 'created_at',
    headerName: 'Created at',
    type: 'dateTime',
    valueGetter: ({ value }) => value && new Date(value),
    valueFormatter: ({ value }) => value && format(new Date(value), 'Ppp'),
    minWidth: 100, flex: 0.6
  },
];

const CustomToolbar: React.FunctionComponent<{
  setPanelsButtonEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>,
  openedPanelValue?: string | undefined,
  isActionRequired?: boolean,
}> = ({ setPanelsButtonEl, openedPanelValue, isActionRequired, ...rest }) => {
  const currentUser = useSelector(currentUserSelector);
  const dispatch = useDispatch();

  const [isFilterSelect, setIsFilterSelected] = useState<boolean>(false);
  const apiRef = useGridApiContext();
  useEffect(() => {
    return apiRef.current.subscribeEvent('stateChange', (
      ({ filter: { filterModel: { items } } }) => setIsFilterSelected(!isEmpty(items))
    ))
  }, [apiRef]);

  const handlePeopleDirectoryChange = (directory: string) => {
    saveStorageObject('people-path', directory);
    dispatch(setPeopleDirPath(directory))
  };

  const exceljsPreProcess = ({ workbook, worksheet }: GridExceljsProcessInput): any => {
    workbook.creator = 'Profesto';
    workbook.created = new Date();
    worksheet.properties.defaultRowHeight = 30;

    worksheet.getCell('A1').value = 'People List';
    worksheet.getCell('A1').font = {
      name: 'Arial Black',
      bold: true,
      size: 12,
    };
    worksheet.getCell('A1').alignment = {
      vertical: 'top',
      horizontal: 'left',
      wrapText: true,
    };
    worksheet.getCell('A2').value = currentUser.company.name;
    worksheet.getCell('A2').font = {
      name: 'Arial',
      size: 10,
    };
    worksheet.getCell('A3').value = format(new Date(), "MM.dd.yyyy HH:mm:ss");
    worksheet.getCell('A3').font = {
      name: 'Arial',
      size: 10,
    };
    worksheet.addRow({});
  };

  const exceljsPostProcess = ({ worksheet }: GridExceljsProcessInput): any => {
    worksheet.getRow(5).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'cccccc' }
    };
    worksheet.getRow(5).font = {
      size: 12,
      bold: true
    };
    worksheet.getRow(5).alignment = {
      vertical: 'middle',
      horizontal: 'center',
    };
    try {
      worksheet.getColumn('pay_amount').alignment = {
        horizontal: 'right',
      };
    } catch {
      return;
    }
  };

  const excelOptions = {
    exceljsPreProcess,
    exceljsPostProcess,
    fileName: `${format(new Date(), "yyyy-MM-dd'T'HH:mm")} Profesto - People List`,
  };

  const ExcelExportVariantsMenuItem = (props: GridExportMenuItemProps<{}>) => {
    const apiRef = useGridApiContext();
    const { hideMenu } = props;

    const handleExport = (options: GridExcelExportOptions) => apiRef.current.exportDataAsExcel(options);

    return (
      <>
        <MenuItem onClick={() => { handleExport({ ...excelOptions, allColumns: false }); hideMenu?.(); }}>
          Visible Columns
        </MenuItem>
        <MenuItem onClick={() => { handleExport({ ...excelOptions, allColumns: true }); hideMenu?.(); }}>
          All Columns
        </MenuItem>
      </>
    );
  };

  const CustomExportButton = ({ ...other }) => (
    <GridToolbarExportContainer
      sx={{ "&:focus": { backgroundColor: "#DCEEE5", color: '#396 !important', circle: { fill: '#396' } } }} {...other}
    >
      <ExcelExportVariantsMenuItem />
    </GridToolbarExportContainer>
  );

  const buttonStyle = (value: string, type?: string) => {
    if (type === 'bg') {
      if (openedPanelValue === value) {
        return '#DCEEE5';
      }
      if (isFilterSelect && value !== 'columns') {
        return '#F2F2F4';
      }
      return 'transparent';
    } else {
      if (openedPanelValue === value) {
        return '#339966';
      }
    }
  };

  let actionRequiredFilterId = gridFilterModelSelector(apiRef).items.find(e => e.columnField === 'action_required')?.id;

  return (
    <GridToolbarContainer {...rest}>
      <div style={{ display: 'flex', flex: 1, justifyContent: 'space-between' }}>
        <StyledGridToolbarQuickFilter
          placeholder='Search By Name, Title, Department, Division, Location'
          variant="outlined"
          size="small"
          debounceMs={500}
        />
        <div style={{ display: 'flex', marginLeft: 20, gap: 10 }}>
          {isActionRequired ? <StyledActionRequiredButton
            type="button"
            isActive={!!actionRequiredFilterId}
            onClick={() => {
              if (!actionRequiredFilterId) {
                apiRef.current.upsertFilterItems([{ columnField: 'action_required', value: 'Yes', operatorValue: 'is', id: 'act_rqr' }]);
              }
            }}
          >
            <ActionRequiredIcon id='required_icon' />
            <span>{isActionRequired}</span>
            Action Required
            {actionRequiredFilterId ? <CloseIcon id='close_icon' onClick={(e) => {
              e.stopPropagation();
              apiRef.current.deleteFilterItem({ columnField: 'action_required', id: actionRequiredFilterId });
            }} /> : null}
          </StyledActionRequiredButton> : null}
          <FilterButtonContainer $isFilterSelect={isFilterSelect || openedPanelValue === 'filters'}>
            {isFilterSelect ? <StyledCloseIcon onClick={() => apiRef.current.upsertFilterItems([])} /> : null}
            <GridToolbarFilterButton
              ref={setPanelsButtonEl}
              sx={{
                paddingRight: isFilterSelect ? "30px !important" : '16px',
                backgroundColor: buttonStyle('filters', 'bg'),
                color: isFilterSelect || openedPanelValue === 'filters' ? "#339966 !important" : '#364852',
              }}
            />
          </FilterButtonContainer>
          <GridToolbarColumnsButton
            sx={{
              backgroundColor: buttonStyle('columns', 'bg'),
              circle: { fill: buttonStyle('columns') },
              color: openedPanelValue === 'columns' ? '#396 !important' : '#364852',
            }}
          />
          <CustomExportButton />
          <ListDirectoryButtons>
            <StyledNavLink
              activeClassName="navlink_active"
              to='/list'
              onClick={() => handlePeopleDirectoryChange('list')}
            >
              <ListIcon /><span>List</span>
            </StyledNavLink>
            <StyledNavLink
              activeClassName="navlink_active"
              to='/people'
              onClick={() => handlePeopleDirectoryChange('people')}
              style={{ borderRadius: '0px 4px 4px 0px' }}
            >
              <DirectoryIcon /><span>Directory</span>
            </StyledNavLink>
          </ListDirectoryButtons>
        </div>
      </div>
    </GridToolbarContainer>
  )
};

const PeopleList = () => {
  const [openedPanelValue, setOpenedPanelValue] = useState<string | undefined>('');
  const [panelsButtonEl, setPanelsButtonEl] = useState<HTMLButtonElement | null>(null);
  const [numberOfEmployees, setNumberOfEmployees] = useState<number>(0);
  const [exceededModal, setExceededModal] = useState<boolean>(false);
  const apiRef = useGridApiRef();
  const history = useHistory();
  const { search } = useLocation();
  const queryParams = queryString.parse(search);

  useEffect(() => {
    if (!isEmpty(queryParams)) {
      if (queryParams.status === 'none') {
        apiRef.current.deleteFilterItem({ id: 1, columnField: 'status' });
      };
    }
  }, [apiRef, queryParams]);

  const { data, isFetching, refetch } = useQuery<any>(["subscription_info_on_press"], {
    endpoint: 'billing/subscription',
    options: { method: "get" },
  }, { enabled: false });

  const onClickNewEmployeeCreate = async () => {
    const { data } = await refetch();
    if (data?.status && data?.status !== 'trialing' && data?.employee_count >= data?.count) {
      setExceededModal(true);
    } else {
      history.push('/createperson');
    }
  };

  const { data: employeeList = [], isLoading } = useQuery<any>(["employee_list"], {
    endpoint: 'employee_list',
    options: { method: "get" },
  }, { enabled: true });

  const initialState: GridInitialState = {
    filter: {
      filterModel: {
        items: [{ id: 1, columnField: 'status', operatorValue: 'isAnyOf', value: ['Active','Hiring'] }],
        linkOperator: GridLinkOperator.And,
        quickFilterLogicOperator: GridLinkOperator.And,
      },
    },
    columns: {
      columnVisibilityModel: {
        first_name: false,
        middle_name: false,
        last_name: false,
        preferred_name: false,
        has_photo: false,
        birth_date: false,
        birth_day: false,
        age: false,
        place_of_birth: false,
        gender_name: false,
        marital_status_name: false,
        nationality_name: false,
        citizenship_name: false,
        preferred_language_name: false,
        secondary_language_name: false,
        shirt_size_name: false,
        allergies: false,
        dietary_restrictions: false,
        home_address_country: false,
        home_address_name: false,
        home_address_details: false,
        home_city: false,
        home_state: false,
        home_postal_code: false,
        mailing_address_country: false,
        mailing_address_name: false,
        mailing_address_details: false,
        mailing_city: false,
        mailing_state: false,
        mailing_postal_code: false,
        work_phone: false,
        work_phone_ext: false,
        mobile_phone: false,
        home_phone: false,
        work_email: false,
        personal_email: false,
        linkedin: false,
        twitter: false,
        facebook: false,
        hire_date: false,
        probation_end_date: false,
        contract_end_date: false,
        job_effective_date: false,
        experience: false,
        manager: false,
        experience_years: false,
        direct_reports: false,
        job_change_reason: false,
        job_change_comment: false,
        termination_date: false,
        job_termination_type: false,
        job_termination_reason: false,
        rehire_eligibility: false,
        termination_comment: false,
        compensation_effective_date: false,
        pay_amount: false,
        currency: false,
        payment_period: false,
        payment_schedule: false,
        payment_type: false,
        overtime_status: false,
        compensation_change_reason: false,
        compensation_comment: false,
        em_contact_name: false,
        em_contact_relationship: false,
        em_contact_work_phone: false,
        em_contact_work_phone_ext: false,
        em_contact_mobile_phone: false,
        em_contact_home_phone: false,
        em_contact_email: false,
        em_contact_country: false,
        em_contact_address: false,
        em_contact_address_details: false,
        em_contact_city: false,
        em_contact_state: false,
        em_contact_postal_code: false,
        action_required: false,
        employee_user_status: false,
        role: false,
        last_login_date: false,
        join_date: false,
        created_by: false,
        created_at: false,
      }
    }
  };

  const onGridStateChange = ({ filter: { filteredRowsLookup }, rows: { idRowsLookup } }: GridState) => {
    if (!isEmpty(filteredRowsLookup)) {
      const filteredRowsIds = Object.keys(filteredRowsLookup).filter(key => filteredRowsLookup[key]);
      let rows = Object.keys(idRowsLookup).map((key) => filteredRowsIds.includes(key) && idRowsLookup[key]).filter((row => row));
      let numberOfEmployees = uniqBy(rows, 'employee_id').length;
      setNumberOfEmployees(numberOfEmployees)
    } else {
      let numberOfEmployees = uniqBy(employeeList, 'employee_id').length;
      setNumberOfEmployees(numberOfEmployees)
    }
  };

  let isActionRequired = employeeList.filter((e: any) => e.action_required === 'Yes').length;

  return (
    <div>
      <PageHeaderTitle
        title={<HeaderTitle>People <span>({numberOfEmployees} Records)</span></HeaderTitle>}
        rightSide={<LoadingButton
          type="button"
          size='large'
          variant='contained'
          onClick={onClickNewEmployeeCreate}
          loading={isFetching}
        >+ New Employee</LoadingButton>}
      />
      <DataGridContainer>
        <DataGrid
          name="people_list"
          saveGridState
          apiRef={apiRef}
          disableRowGrouping
          onStateChange={onGridStateChange}
          loading={isLoading}
          initialState={initialState}
          columns={columns}
          rows={employeeList}
          onPreferencePanelClose={() => setOpenedPanelValue('')}
          onPreferencePanelOpen={(e: GridPreferencePanelParams) => setOpenedPanelValue(e.openedPanelValue)}
          components={{
            Toolbar: CustomToolbar,
            QuickFilterClearIcon: CloseIcon,
            QuickFilterIcon: StyledMagnifierIcon
          }}
          componentsProps={{
            panel: {
              anchorEl: panelsButtonEl,
              placement: 'bottom'
            },
            toolbar: {
              setPanelsButtonEl,
              openedPanelValue,
              isActionRequired
            },
          }}
        />
      </DataGridContainer>
      <ExceededModal
        open={exceededModal}
        onClose={() => setExceededModal(false)}
        subscriptionData={data}
      />
    </div>
  );
};

export default PeopleList;

const HeaderTitle = styled.p`
  font-size: 18px;
  color: #00101A;
  & > span {
    color: #00101A;
    opacity: 0.5;
  }
`;

const DataGridContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '85%',
  margin: '14px 60px',
  [theme.breakpoints.down('md')]: {
    margin: "14px 20px",
  },
  [theme.breakpoints.down('sm')]: {
    margin: "14px 16px",
  },
}));

const ListDirectoryButtons = styled.div`
  display: flex;
`;

const StyledNavLink = styled(NavLink)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  width: 115,
  height: 40,
  border: "1px solid #DCEEE5",
  borderRadius: "4px 0px 0px 4px",
  justifyContent: "center",
  [theme.breakpoints.down('lg')]: {
    width: 40,
    '& > span': {
      display: 'none'
    },
  },
  '& > span': {
    color: '#364852',
    fontSize: 14,
    marginLeft: 5,
  },
  '&:hover': {
    backgroundColor: '#DCEEE5',
    color: '#339966',
    '& path': {
      fill: '#339966',
    }
  },
  '&.navlink_active': {
    '& > span': {
      color: '#339966',
    },
    backgroundColor: '#DCEEE5',
    '& path': {
      fill: '#339966',
    },
  }
}));

const StyledCloseIcon = styled(CloseIcon)`
    position: absolute;
    z-index: 2;
    right: 10px;
    top: 15px;
    width: 8px;
    height: 8px;
    cursor: pointer;
    & path {
        fill: #868686;
    }
`;

const FilterButtonContainer = styled.div<{ $isFilterSelect: boolean }>`
    position: relative;
    ${StyledCloseIcon}:hover + .MuiButtonBase-root{
        background-color: #DCEEE5;
    }
    & circle { 
        fill: ${({ $isFilterSelect }) => $isFilterSelect ? '#339966' : '#b5b5b5'}
    }
`;

const StyledGridToolbarQuickFilter = styled(GridToolbarQuickFilter)(({ theme }) => ({
  flexGrow: 1,
  padding: 0,
  maxWidth: 440,
  [theme.breakpoints.down('lg')]: {
    width: 170,
  },
  [theme.breakpoints.down('sm')]: {
    width: 40,
  },
  '& input': { padding: '10px 5px' },
  '.MuiButtonBase-root': {
    padding: '5px !important',
    '&:hover': {
      backgroundColor: 'transparent !important',
      'path': { fill: '#636363 !important' }
    },
  },
}));

const StyledActionRequiredButton = styled('button')<{ isActive: boolean }>(({ theme, isActive }) => ({
  position: "relative",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: 38,
  paddingInline: 15,
  border: isActive ? '1px solid #FFF0E2' : '1px solid #E5E8E9',
  borderRadius: 4,
  backgroundColor: isActive ? '#FFF7EF' : '#FFF',
  color: isActive ? '#FF9933' : '#364852',
  fontSize: 14,
  [theme.breakpoints.down('md')]: {
    fontSize: 0,
  },
  cursor: "pointer",
  transition: "background-color 250ms cubic-bezier(0.4,0,0.2,1) 0ms",
  "& > #required_icon": {
    marginRight: 5,
    "path:first-of-type": {
      fill: isActive ? '#FF9933' : '#b5b5b5',
    },
  },
  "& > #close_icon": {
    marginLeft: 9,
    width: 8,
    height: 8,
    "& path": {
      fill: '#868686'
    },
  },
  "& > span": {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 12,
    width: 12,
    fontSize: 8,
    borderRadius: 6,
    backgroundColor: "#FFF",
    color: isActive ? '#D0761D' : '#364852',
    top: 2,
    left: 29,
  },
  "&:hover": {
    borderColor: "#FFF0E2",
    backgroundColor: "#FFF7EF",
    color: "#FF9933",
    "& > span": { color: "#D0761D" },
    "& > #required_icon": { "path:first-of-type": { fill: "#FF9933" } }
  }
}));

const StyledMagnifierIcon = styled(MagnifierIcon)`
  min-width: 15px;
  min-height: 15px;
`;