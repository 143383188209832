import { useState } from 'react';
import styled from "styled-components";
import {
    GridColDef,
    GridExceljsProcessInput,
    GridInitialState,
    GridRenderCellParams,
    GridState
} from "@mui/x-data-grid-premium";
import DataGrid from "components/DataLists/DataGrid";
import { PageHeaderTitle } from "components/DesignUIComponents";
import EmployeeCard from "components/Employee/Card";
import useQuery from "hooks/useQueryCustom";
import { isEmpty, uniqBy } from "lodash";
import format from "date-fns/format";
import { formatInTimeZone } from 'date-fns-tz'
import { useSelector } from "react-redux";
import { currentUserSelector } from "redux/selectors";
import PermissionGate from 'permissions/PermissionGate';

const renderAvatarCell = (params: GridRenderCellParams, type: string) => {
    return params.row[type]?.first_name ?
        <PermissionGate on='employee' properties={{ disabled: true }}>
            <EmployeeCard fontSize={14} employee={params.row[type]} />
        </PermissionGate> : ""
};
const columns: GridColDef[] = [
    {
        field: 'employee', headerName: 'Employee',
        renderCell: (params) => renderAvatarCell(params, 'employee'),
        valueGetter: ({ value }) => `${value.first_name} ${value.last_name}`,
        minWidth: 150, flex: 1
    },
    { field: 'employee_id', headerName: 'Emp. #', minWidth: 100, flex: 0.6 },
    {
        field: 'termination_date',
        headerName: 'Termination Date',
        type: 'date',
        valueGetter: ({ value }) => value && new Date(value),
        valueFormatter: ({ value }) => value && formatInTimeZone(new Date(value), 'UTC', 'P'),
        minWidth: 100, flex: 0.6
    },
    { field: 'employment_status', headerName: 'Emp. Status', minWidth: 100, flex: 1 },
    { field: 'job_title', headerName: 'Job Title', minWidth: 100, flex: 1 },
    { field: 'department', headerName: 'Department', minWidth: 100, flex: 1 },
    { field: 'division', headerName: 'Division', minWidth: 100, flex: 1 },
    { field: 'location', headerName: 'Location', minWidth: 100, flex: 1 },
    {
        field: 'manager',
        headerName: 'Manager',
        renderCell: (params) => renderAvatarCell(params, 'manager'),
        valueGetter: ({ value }) => value.first_name ? `${value.first_name} ${value.last_name}` : '',
        minWidth: 150, flex: 1
    },
    {
        field: 'hire_date',
        headerName: 'Hire Date',
        type: 'date',
        valueGetter: ({ value }) => value && new Date(value),
        valueFormatter: ({ value }) => value && formatInTimeZone(new Date(value), 'UTC', 'P'),
        minWidth: 100, flex: 0.6
    },
    {
        field: 'length_of_service_period',
        headerName: 'Length of Service',
        minWidth: 100, flex: 1
    },
    {
        field: 'length_of_service',
        headerName: 'Length of Service (Years)',
        minWidth: 100, flex: 0.6
    },
    {
        field: 'termination_type',
        headerName: 'Termination Type',
        minWidth: 100, flex: 1
    },
    {
        field: 'termination_reason',
        headerName: 'Termination Reason',
        minWidth: 100, flex: 1
    },
    { field: 'age', headerName: 'Age', minWidth: 100, flex: 0.6 },
    { field: 'gender', headerName: 'Gender', minWidth: 100, flex: 1 },
];

export default function TerminationsReport() {
    const [numberOfEmployees, setNumberOfEmployees] = useState<number>(0);
    const { data: terminationsReportData = [], isLoading } = useQuery<any>(["terminations_report"], {
        endpoint: 'reports/termination',
        options: { method: "post" },
    }, { enabled: true });

    const currentUser = useSelector(currentUserSelector);

    const initialState: GridInitialState = {
        sorting: {
            sortModel: [
                { field: 'termination_date', sort: 'desc' },
                { field: 'employee', sort: 'asc' },
            ],
        },
        columns: {
            columnVisibilityModel: {}
        }
    };

    const exceljsPreProcess = ({ workbook, worksheet }: GridExceljsProcessInput): any => {
        workbook.creator = 'Profesto';
        workbook.created = new Date();
        worksheet.properties.defaultRowHeight = 30;

        worksheet.getCell('A1').value = 'Terminations';
        worksheet.getCell('A1').font = {
            name: 'Arial Black',
            bold: true,
            size: 12,
        };
        worksheet.getCell('A1').alignment = {
            vertical: 'top',
            horizontal: 'left',
            wrapText: true,
        };
        worksheet.getCell('A2').value = currentUser.company.name;
        worksheet.getCell('A2').font = {
            name: 'Arial',
            size: 10,
        };
        worksheet.getCell('A3').value = format(new Date(), "MM.dd.yyyy HH:mm:ss");
        worksheet.getCell('A3').font = {
            name: 'Arial',
            size: 10,
        };
        worksheet.addRow({});
    };

    const exceljsPostProcess = ({ worksheet }: GridExceljsProcessInput): any => {
        worksheet.getRow(5).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'cccccc' }
        };
        worksheet.getRow(5).font = {
            size: 12,
            bold: true
        };
        worksheet.getRow(5).alignment = {
            vertical: 'middle',
            horizontal: 'center',
        };
    };

    const onGridStateChange = ({ filter: { filteredRowsLookup }, rows: { idRowsLookup } }: GridState) => {
        if (!isEmpty(filteredRowsLookup)) {
            const filteredRowsIds = Object.keys(filteredRowsLookup).filter(key => filteredRowsLookup[key]);
            let rows = Object.keys(idRowsLookup).map((key) => filteredRowsIds.includes(key) && idRowsLookup[key]).filter((row => row));
            let numberOfEmployees = uniqBy(rows, 'employee_id').length;
            setNumberOfEmployees(numberOfEmployees)
        } else {
            let numberOfEmployees = uniqBy(terminationsReportData, 'employee_id').length;
            setNumberOfEmployees(numberOfEmployees)
        }
    };

    return (
        <>
            <PageHeaderTitle title={<HeaderTitle>Terminations <span>({numberOfEmployees} Employees)</span></HeaderTitle>} />
            <div style={{ display: 'flex', height: '85%', margin: '20px 50px', flexDirection: 'column' }}>
                <DataGrid
                    name="terminations_report"
                    saveGridState
                    disableRowGrouping
                    onStateChange={onGridStateChange}
                    loading={isLoading}
                    rows={terminationsReportData}
                    columns={columns}
                    initialState={initialState}
                    enableExports
                    excelOptions={{
                        exceljsPreProcess,
                        exceljsPostProcess,
                        fileName: `${format(new Date(), "yyyy-MM-dd'T'HH:mm")} - Profesto - Terminations`,
                    }}
                />
            </div>
        </>
    )
};

const HeaderTitle = styled.p`
  font-size: 18px;
  color: #00101A;
  & > span {
    color: #00101A;
    opacity: 0.5;
    font-size: 15px;
  }
`;
