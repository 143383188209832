import { useEffect, useState } from "react";
import styled from "styled-components";
import useQuery from "hooks/useQueryCustom";
import LinearProgress from "@mui/material/LinearProgress";
import DialogModal, { IDialogProps } from "components/Modal/Dialog";
import { usePermissionGate } from "permissions/usePermissionGate";

import { ReactComponent as EmployeesIcon } from 'assets/svg/persons_circles.svg';

interface IExceededModal extends IDialogProps {
    onClose?: () => void,
    subscriptionData: {
        count: number,
        employee_count: number,
        plan: string,
        trial: number
    },
    contactDetailsVisible?: boolean,
};

export default function ExceededModal({ onClose, subscriptionData, contactDetailsVisible, ...rest }: IExceededModal) {
    const [portalTargetLink, setPortalTargetLink] = useState<string>('')
    const { role } = usePermissionGate();

    const { refetch: getPortalLink, isFetching: isLinkFetching } = useQuery<any>(["portal_url"], {
        endpoint: `billing/portal_link?to=${portalTargetLink}`,
        options: { method: "get" },
    }, { enabled: false });

    const onClickChoosePlan = async () => {
        const { data } = await getPortalLink();
        window.open(data.url, '_blank');
        setPortalTargetLink('');
        onClose?.();
    };

    useEffect(() => {
        if (portalTargetLink) {
            onClickChoosePlan();
        }
    }, [portalTargetLink]);

    const renderUpgradeTextOffer = () => {
        if (role === 'owner' && subscriptionData?.plan?.toLowerCase() === 'basic') {
            return <>Please <span onClick={() => setPortalTargetLink('update_plan')}>Upgrade your Plan</span> to add more Employees.</>
        }
        if (role === 'owner' && subscriptionData?.plan?.toLowerCase() === 'core') {
            return <>Please <span onClick={() => setPortalTargetLink('update_quantity')}>Upgrade available seats number</span> to add more Employees.</>
        }
        return "Please contact your Account Owner to add more Employees.";
    };

    return (
        <DialogModal
            withButtons
            onClose={() => onClose?.()}
            actionButton={() => onClose?.()}
            actionButtonText="CLOSE"
            title="The employee number limit has been reached!"
            hideCancelButton
            fullWidth
            upperPosition
            {...rest}
        >
            <ExceededModalContentContainer>
                <EmployeesIcon />
                <ModalMainText>
                    The employee number limit has been reached! <br /> {subscriptionData?.employee_count} out of {subscriptionData?.count} Seats Used.
                </ModalMainText>
                <ModalSecondaryText>
                    {renderUpgradeTextOffer()}
                    {isLinkFetching ? <LinearProgress /> : <div style={{ height: 4 }} />}
                </ModalSecondaryText>
                {role === 'owner' && contactDetailsVisible ? <ModalSecondaryText style={{ marginTop: 2 }}>or</ModalSecondaryText> : null}
                {role === 'owner' && contactDetailsVisible ? <ModalSecondaryText style={{ marginTop: 2 }}>
                    Contact us at <a href="mailto:contact@hrsoft.com">contact@profesto.net</a> to delete unwanted Employees and free up the seats.
                </ModalSecondaryText> : null}
            </ExceededModalContentContainer>
        </DialogModal>
    )
};

const ExceededModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 50px;
`;

const ModalMainText = styled.p`
  margin-top: 25px;
  font-size: 15px;
  font-family: 'Aspira Demi';
  color: #676767;
  text-align: center;
`;

const ModalSecondaryText = styled.div`
  margin-top: 10px;
  font-size: 14px;
  color: #676767;
  text-align: center;
  & > span, a {
    color: #339966;
    text-decoration: underline;
    cursor: pointer;
  }
`;