import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { PageHeaderTitle } from 'components/DesignUIComponents';
import PermissionGate from 'permissions/PermissionGate';
import { usePermissionGate } from 'permissions/usePermissionGate';
import { ReactComponent as PersonIcon } from 'assets/svg/info_circle/person-circle.svg';
import { ReactComponent as HeadCountIcon } from 'assets/svg/info_circle/persons_headcount-circle.svg';

export default function ReportsList() {
    const { permissions, role } = usePermissionGate();

    const firstBlock: any = {
        job_history: permissions?.report?.job_history.view,
        compensation_history: permissions?.report?.compensation_history.view,
        work_anniversaries: permissions?.report?.work_anniversaries.view,
        birthdays: permissions?.report?.birthdays.view,
    };
    const firstBlockVisibility = Object.keys(firstBlock).some((e) => firstBlock[e]);

    const secondBlock: any = {
        additions: permissions?.report?.additions.view,
        terminations: permissions?.report?.terminations.view,
        pay_change: permissions?.report?.pay_change.view,
    };
    const secondBlockVisibility = Object.keys(secondBlock).some((e) => secondBlock[e]);

    return (
        <>
            <PageHeaderTitle title='Reports' />
            <ListsContainer>
                <ContentRow>
                    {firstBlockVisibility || role === 'owner' ? <ContentRowHeader>
                        <PersonIcon /><p>Employee Information</p>
                    </ContentRowHeader> : null}
                    <ContentRowList>
                        <PermissionGate on='job_history'>
                            <Link to='job_history'>Job History</Link>
                        </PermissionGate>
                        <PermissionGate on='compensation_history'>
                            <Link to='compensation_history'>Compensation History</Link>
                        </PermissionGate>
                        <PermissionGate on='work_anniversaries'>
                            <Link to='work_anniversaries'>Work Anniversaries</Link>
                        </PermissionGate>
                        <PermissionGate on='birthdays'>
                            <Link to='birthdays'>Birthdays</Link>
                        </PermissionGate>
                    </ContentRowList>
                </ContentRow>
                <ContentRow>
                    {secondBlockVisibility || role === 'owner' ? <ContentRowHeader>
                        <HeadCountIcon /><p>Headcount and Turnover</p>
                    </ContentRowHeader> : null}
                    <ContentRowList>
                        <PermissionGate on='additions'>
                            <Link to='additions'>Additions</Link>
                        </PermissionGate>
                        <PermissionGate on='terminations'>
                            <Link to='terminations'>Terminations</Link>
                        </PermissionGate>
                        <PermissionGate on='pay_change'>
                            <Link to='pay_change'>Pay Change</Link>
                        </PermissionGate>
                    </ContentRowList>
                </ContentRow>
                <ContentRow>
                </ContentRow>
            </ListsContainer>
        </>
    )
};

const ListsContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
    margin: 12px 60px;
`;

const ContentRow = styled.div`

    flex: 1 0 31%;
    margin: 5px;
`;

const ContentRowHeader = styled.div`
    display: flex;
    align-items: center;
    & > p {
        font-size: 18px;
        color: #339966;
        font-weight: bold;
        margin-left: 8px;
    }
`;

const ContentRowList = styled.div`
    display: flex;
    flex-direction: column;
    margin: 20px 0 20px 33px;
    font-size: 14px;
    color: #00101A;
    gap: 15px;
`;


