import styled from "styled-components";
import DialogModal from "components/Modal/Dialog";
import EmpEditHeader from "../editHeader";

import { ReactComponent as WarningMark } from 'assets/svg/warning-mark_circles.svg'

const Wrapper = styled.div`
    .mode-body{
        display: flex;
        flex-direction: column;
        align-items: center;

        p{
            color: #676767;
            margin: 25px 0;
        }
    }
`;

const DeleteEmployee = (props: any) => {
    return (
        <DialogModal
            open={props.isOpen}
            title={'Delete Employee'}
            onClose={() => props.onModalClose()}
            actionButton={() => props.onDelete()}
            withButtons
            cancelButtonText='NO. KEEP THIS EMPLOYEE'
            actionButtonText='YES, DELETE THIS EMPLOYEE'
            actionLoading={props.loadingRequest}
            nominalHeader={
                <EmpEditHeader
                    employeeName={`${props.person.first_name} ${props.person.last_name}`}
                    avatarUuid={props.person.uuid}
                    employeeId={props.person.id}
                    jobData={props.jobData}
                />
            }
            fullWidth
            upperPosition
        >
            <Wrapper>
                <div className='delete-mode'>
                    <div className='mode-body'>
                        <WarningMark />
                        <p>Are you sure you want to delete all information for this employee?</p>
                    </div>
                </div>

            </Wrapper>
        </DialogModal>
    );
};

export default DeleteEmployee;
