import { Route, Switch } from 'react-router';
import Dictionary from './Dictionary';
export const Dictionaries = () => {
    return (
        <Switch>
            <Route path="/settings/job_information/compensation_change" component={() => <Dictionary endpoint="/compensation_change" singularTitle="Compensation Change Reason" title="Compensation Change Reasons" />} />
            <Route path="/settings/job_information/job_change_reason" component={() => <Dictionary endpoint="/job_change_reason" singularTitle="Job Change Reason" title="Job Change Reasons" />} />
            <Route path="/settings/job_information/job_termination_reason" component={() => <Dictionary endpoint="/job_termination_reason" singularTitle="Termination Reason" title="Termination Reasons" />} />
            <Route path="/settings/job_information/job_termination_type" component={() => <Dictionary endpoint="/job_termination_type" singularTitle="Termination Type" title="Termination Types" />} />
            <Route path="/settings/job_information/department" component={() => <Dictionary withChecking endpoint="/department" singularTitle="Department" title="Departments" />} />
            <Route path="/settings/job_information/divisions" component={() => <Dictionary withChecking endpoint="/division" singularTitle="Division" title="Divisions" />} />
            <Route path="/settings/job_information/employment_status" component={() => <Dictionary withChecking endpoint="/employment_status" singularTitle="Employment Status" title="Employment Statuses" />} />
            <Route path="/settings/job_information/job_title" component={() => <Dictionary withChecking endpoint="/job_title" singularTitle="Job Title" title="Job Titles" />} />
            <Route path="/settings/job_information/location" component={() => <Dictionary withChecking endpoint="/location" singularTitle="Location" title="Locations" />} />
            <Route path="/settings/job_information/payment_period" component={() => <Dictionary withChecking endpoint="/payment_period" singularTitle="Pay Rate Period" title="Pay Rate Periods" />} />
            <Route path="/settings/job_information/payment_schedule" component={() => <Dictionary withChecking endpoint="/payment_schedule" singularTitle="Pay Schedule" title="Pay Schedules" />} />
            <Route path="/settings/job_information/payment_type" component={() => <Dictionary withChecking endpoint="/payment_type" singularTitle="Payment Type" title="Payment Types" />} />

            <Route path="/settings/personal_information/driver_classification" component={() => <Dictionary endpoint="/driver_classification" singularTitle="Driver License Classification" title="Driver License Classifications" />} />
            <Route path="/settings/personal_information/education_degree" component={() => <Dictionary endpoint="/education_degree" singularTitle="Education Degree" title="Education Degrees" />} />
            <Route path="/settings/personal_information/contact_relationship" component={() => <Dictionary endpoint="/contact_relationship" singularTitle="Emergency Contact Relationship" title="Emergency Contact Relationships" />} />
            <Route path="/settings/personal_information/language" component={() => <Dictionary endpoint="/language" singularTitle="Language" title="Languages" />} />
            <Route path="/settings/personal_information/nationality" component={() => <Dictionary endpoint="/nationality" singularTitle="Nationality" title="Nationalities" />} />
            <Route path="/settings/personal_information/shirt_size" component={() => <Dictionary endpoint="/shirt_size" singularTitle="Shirt Size" title="Shirt Sizes" />} />
            <Route path="/settings/personal_information/visa" component={() => <Dictionary endpoint="/visa" singularTitle="Visa" title="Visas" />} />
            <Route path="/settings/personal_information/currency" component={() => <Dictionary endpoint="/currency" singularTitle="Currency" title="Currencies" />} />

            {/*<Route path="/settings/timeoff_types" component={() => <Dictionary endpoint="/time_off_type" singularTitle="Time Off Type" title="Time Off Types" />} />*/}
        </Switch>
    )
};