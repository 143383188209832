import { PropsWithChildren, Fragment, CSSProperties } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Controller, useFormContext } from "react-hook-form";
import utcToZonedTime from "date-fns-tz/utcToZonedTime";
import useQueryCustom from "hooks/useQueryCustom";
import Divider from "@mui/material/Divider";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioButton from "components/RadioButton";
import TextArea from "components/TextArea";
import UniversalInput from "components/Input/UniversalInput";
import DatePicker from "components/DatePickers/DatePicker";
import Checkbox from "components/Checkbox";

import { StepTitle, StepDesc } from "../../StepsContent/Welcome";

interface IFieldItem {
    required?: boolean,
    label: string,
    style?: CSSProperties,
};

export const FieldItem = ({ children, required, label, style }: PropsWithChildren<IFieldItem>) => {
    return (
        <StyledFieldItem style={style}>
            {label && <label>{label}{required && <sup>*</sup>}</label>}
            {children}
        </StyledFieldItem>
    )
};

export default function DocumentInformation({ setRequiredFields }: { setRequiredFields: (e: any) => void }) {
    const { id } = useParams<{ id: string }>();
    const { control, register, watch, setValue, formState: { errors } } = useFormContext();
    const watchSEVP = watch('sevp');

    const { data } = useQueryCustom<string[], {}, {}>(["get_onboarding_fill_documents"], {
        endpoint: `onboarding/admin/fill_documents?employee_id=${id}`,
        options: { method: "get" },
        onSuccess: (data: any) => {
            //find required fields to show in tooltip
            if (data?.class_a) {
                if (data.class_a.id_name === 'us_passport') {
                    setRequiredFields(['doc_title', 'issuing_authority', 'doc_number']);
                };
                if (data.class_a.id_name === 'resident') {
                    setRequiredFields(['doc_title', 'issuing_authority', 'doc_number']);
                };
                if (data.class_a.id_name === 'foreign_passport') {
                    setValue('doc_title', 'foreign_passport');
                    setRequiredFields(['issuing_authority', 'doc_number']);
                };
                if (data.class_a.id_name === 'authorization') {
                    setValue('doc_title', 'employment_authorization');
                    setRequiredFields(['issuing_authority', 'doc_number']);
                };
                if (data.class_a.id_name === 'nonimmigrant') {
                    setValue('doc_title', 'foreign_passport_nonimmigrant');
                    setValue('secondary_doc_title', 'arrival/departure_record');
                    setRequiredFields(['issuing_authority', 'doc_number', 'sevp_doc_title', 'sevp_issuing_authority', 'sevp_doc_number', 'secondary_issuing_authority', 'secondary_doc_number']);
                };
                if (data.class_a.id_name === 'micronesia_or_marshall_islands') {
                    setValue('secondary_doc_title', 'arrival/departure_record');
                    setRequiredFields(['doc_title', 'issuing_authority', 'doc_number', 'sevp_doc_title', 'sevp_issuing_authority', 'sevp_doc_number', 'secondary_issuing_authority', 'secondary_doc_number']);
                };
            };
            if (data?.class_b) {
                if (data.class_b.id_name === 'driver_license') {
                    setRequiredFields(['doc_title', 'issuing_authority', 'doc_number']);
                };
                if (data.class_b.id_name === 'id_card') {
                    setValue('doc_title', 'photo_id_card');
                    setRequiredFields(['issuing_authority', 'doc_number']);
                };
                if (data.class_b.id_name === 'school_id') {
                    setValue('doc_title', 'school_id');
                    setRequiredFields(['issuing_authority', 'doc_number']);
                };
                if (data.class_b.id_name === 'voter_card') {
                    setValue('doc_title', 'voter_registration_card');
                    setRequiredFields(['issuing_authority', 'doc_number']);
                };
                if (data.class_b.id_name === 'military_card') {
                    setRequiredFields(['doc_title', 'issuing_authority', 'doc_number']);
                };
                if (data.class_b.id_name === 'military_department_card') {
                    setValue('doc_title', 'military_dependent_id');
                    setRequiredFields(['issuing_authority', 'doc_number']);
                };
                if (data.class_b.id_name === 'coast_guard_card') {
                    setValue('doc_title', 'us_coast_guard');
                    setRequiredFields(['issuing_authority', 'doc_number']);
                };
                if (data.class_b.id_name === 'native_american_document') {
                    setValue('doc_title', 'native_american_tribal');
                    setRequiredFields(['issuing_authority', 'doc_number']);
                };
                if (data.class_b.id_name === 'canada_driver_license') {
                    setValue('doc_title', 'driver_license_canada');
                    setRequiredFields(['issuing_authority', 'doc_number']);
                };
            };
            if (data?.underage) {
                if (data.underage.id_name === 'school_record') {
                    setValue('doc_title', 'school_record');
                    setRequiredFields(['issuing_authority', 'doc_number']);
                };
                if (data.underage.id_name === 'hospital_record') {
                    setValue('doc_title', 'hospital_record');
                    setRequiredFields(['issuing_authority', 'doc_number']);
                };
                if (data.underage.id_name === 'nursery_school_record') {
                    setRequiredFields(['doc_title', 'issuing_authority', 'doc_number']);
                };
            };
            if (data?.class_c) {
                if (data.class_c.id_name === 'us_passport') {
                    setValue('secondary_doc_title', 'social_security_card');
                    setRequiredFields((e: any) => [...e, 'secondary_issuing_authority', 'secondary_doc_number']);
                };
                if (data.class_c.id_name === 'birth_certificate') {
                    setRequiredFields((e: any) => [...e, 'secondary_doc_title', 'secondary_issuing_authority', 'secondary_doc_number']);
                };
                if (data.class_c.id_name === 'original_birth_certificate') {
                    setRequiredFields((e: any) => [...e, 'secondary_doc_title', 'secondary_issuing_authority', 'secondary_doc_number']);
                };
                if (data.class_c.id_name === 'native_american') {
                    setValue('secondary_doc_title', 'native_american_tribal');
                    setRequiredFields((e: any) => [...e, 'secondary_issuing_authority', 'secondary_doc_number']);
                };
                if (data.class_c.id_name === 'form_i-197') {
                    setValue('secondary_doc_title', 'us_citizen_id');
                    setRequiredFields((e: any) => [...e, 'secondary_issuing_authority', 'secondary_doc_number']);
                };
                if (data.class_c.id_name === 'form_i-179') {
                    setValue('secondary_doc_title', 'resident_citizen_id');
                    setRequiredFields((e: any) => [...e, 'secondary_issuing_authority', 'secondary_doc_number']);
                };
                if (data.class_c.id_name === 'employment_authorization_document') {
                    setValue('secondary_doc_title', 'employment_authorization');
                    setRequiredFields((e: any) => [...e, 'secondary_issuing_authority', 'secondary_doc_number']);
                };
            };
            if (data?.filled_documents) {
                setValue('doc_title', data.filled_documents.doc_title);
                setValue('issuing_authority', data.filled_documents.issuing_authority ?? '');
                setValue('doc_number', data.filled_documents.doc_number);
                setValue('expiration_date', data.filled_documents.expiration_date ? utcToZonedTime(new Date(data.filled_documents.expiration_date), 'UTC') : '');
                setValue('sevp', data.filled_documents.sevp);
                setValue('sevp_doc_title', data.filled_documents.sevp_doc_title);
                setValue('sevp_issuing_authority', data.filled_documents.sevp_issuing_authority ?? '');
                setValue('sevp_doc_number', data.filled_documents.sevp_doc_number);
                setValue('sevp_expiration_date', data.filled_documents.sevp_expiration_date ? utcToZonedTime(new Date(data.filled_documents.sevp_expiration_date), 'UTC') : '');
                setValue('secondary_doc_title', data.filled_documents.secondary_doc_title);
                setValue('secondary_issuing_authority', data.filled_documents.secondary_issuing_authority ?? '');
                setValue('secondary_doc_number', data.filled_documents.secondary_doc_number);
                setValue('secondary_expiration_date', data.filled_documents.secondary_expiration_date ? utcToZonedTime(new Date(data.filled_documents.secondary_expiration_date), 'UTC') : '');
            };
        },
    }, { refetchOnWindowFocus: false });

    const renderPlainDocumentFields = (type: string, radioButtons: { value: string, label: string }[] = []) => {
        return (
            <Fragment>
                {radioButtons.length ? <FieldItem label="Document Title" required>
                    <Controller
                        control={control}
                        rules={{ required: "Document Title is required" }}
                        name={`${type ? type + '_' : ''}doc_title`}
                        render={({ field }) => (
                            <Fragment>
                                <RadioGroup {...field}>
                                    {radioButtons.map((e, i) => (
                                        <FormControlLabel
                                            key={i}
                                            value={e.value}
                                            control={<RadioButton />}
                                            label={e.label}
                                        />
                                    ))}
                                </RadioGroup>
                                <RadioButtonsError>{errors[`${type ? type + '_' : ''}doc_title`]?.message}</RadioButtonsError>
                            </Fragment>
                        )}
                    />
                </FieldItem> : null}
                <FieldItem label="">
                    <Controller
                        name={`${type ? type + '_' : ''}issuing_authority`}
                        control={control}
                        rules={{ required: 'Issuing Authority is required' }}
                        render={({ field: { value, onChange } }) => (
                            <TextArea
                                value={value}
                                onChange={onChange}
                                label={'Issuing Authority'}
                                required
                                maxRows={1}
                                maxLength={550}
                                errorText={errors[`${type ? type + '_' : ''}issuing_authority`]?.message as string}
                            />
                        )}
                    />
                </FieldItem>
                <FieldItem label="Document Number" required>
                    <UniversalInput
                        {...register(`${type ? type + '_' : ''}doc_number`, { required: 'Document Number is required', maxLength: 50 })}
                        errorText={errors[`${type ? type + '_' : ''}doc_number`]?.message}
                        inputProps={{ maxLength: 50 }}
                    />
                </FieldItem>
                <FieldItem label="">
                    <Controller
                        name={`${type ? type + '_' : ''}expiration_date`}
                        control={control}
                        rules={{ validate: value => value !== null || 'Please enter a valid date' }}
                        render={({ field: { onChange, value } }) => (
                            <DatePicker
                                selected={value}
                                onChange={onChange}
                                label='Expiration Date (if any)'
                                errorText={errors[`${type ? type + '_' : ''}expiration_date`]?.message}
                            />
                        )}
                    />
                </FieldItem>
            </Fragment>
        )
    };

    const renderDocumentFields = (
        type: string,
        docTitle: string,
        radioButtons: { value: string, label: string }[] = [],
        divider: boolean = true,
        sevp?: { value: string, label: string }[]
    ) => {
        return (
            <Fragment>
                <SingleListContainer>
                    <ListTitleContainer>{docTitle}</ListTitleContainer>
                    {renderPlainDocumentFields(type, radioButtons)}
                    {sevp && <Fragment>
                        <FieldItem label="">
                            <Controller
                                name="sevp"
                                control={control}
                                render={({ field: { value, onChange } }) => (
                                    <Checkbox
                                        checked={value}
                                        onChange={onChange}
                                        label={"The employee is part of the Student and Exchange Visitor Program (SEVP)"}
                                    />
                                )}
                            />
                        </FieldItem>
                        {watchSEVP && renderPlainDocumentFields('sevp', sevp)}
                    </Fragment>}
                </SingleListContainer>
                {divider ? <Divider orientation="vertical" flexItem sx={{ marginInline: 3 }} /> : null}
            </Fragment>
        )
    };

    const fieldsClassAConstructor = (data: any) => {
        if (data?.class_a) {
            if (data.class_a.id_name === 'us_passport') {
                return renderDocumentFields(
                    '',
                    'US Passport or Passport Card',
                    [{ value: 'passport', label: 'US Passport' }, { value: 'passport_card', label: 'US Passport Card' }],
                    false
                );
            };
            if (data.class_a.id_name === 'resident') {
                return renderDocumentFields(
                    '',
                    'Permanent Resident Card or Alien Registration Receipt Card (Form I-551)',
                    [{ value: 'permanent', label: 'Permanent Resident Card' }, { value: 'alien', label: 'Alien Registration Receipt Card' }],
                    false
                );
            };
            if (data.class_a.id_name === 'foreign_passport') {
                return renderDocumentFields('', 'Foreign passport with temporary I-551 stamp or printed notation on a MRIV', [], false);
            };
            if (data.class_a.id_name === 'authorization') {
                return renderDocumentFields('', 'Employment Authorization Document that contains a photograph (Form I-766)', [], false);
            };
            if (data.class_a.id_name === 'nonimmigrant') {
                return (
                    <Fragment>
                        {renderDocumentFields(
                            '',
                            'Foreign passport',
                            [],
                            true,
                            [{ value: 'student', label: "Certificate of Eligibility for Nonimmigrant (F-1) Student Status (Form I-20) " },
                            { value: 'visitor', label: "Certificate of Eligibility for Exchange Visitor (J-1) Status (Form DS-2019)" }]
                        )}
                        {renderDocumentFields(
                            'secondary',
                            'Arrival/departure Record (Form I-94 or Form I-94A)',
                            [],
                            false,
                        )}
                    </Fragment>
                )
            };
            if (data.class_a.id_name === 'micronesia_or_marshall_islands') {
                return (
                    <Fragment>
                        {renderDocumentFields(
                            '',
                            'Passport from the Federal States of Micronesia or Republic of Marshall Islands',
                            [{ value: 'micronesia', label: "Federal States of Micronesia Passport" },
                            { value: 'marshal', label: "Republic of Marshall Islands Passport" }],
                            true,
                            [{ value: 'student', label: "Certificate of Eligibility for Nonimmigrant (F-1) Student Status (Form I-20) " },
                            { value: 'visitor', label: "Certificate of Eligibility for Exchange Visitor (J-1) Status (Form DS-2019)" }]
                        )}
                        {renderDocumentFields(
                            'secondary',
                            'Arrival/departure Record (Form I-94 or Form I-94A)',
                            [],
                            false,
                        )}
                    </Fragment>
                )
            };
        };
    };

    const fieldsClassBConstructor = (data: any) => {
        if (data?.class_b) {
            if (data.class_b.id_name === 'driver_license') {
                return renderDocumentFields(
                    '',
                    'Driver’s license or state-issued ID card',
                    [{ value: 'driver', label: 'Driver’s license' }, { value: 'id', label: 'State ID card' }],
                )
            };
            if (data.class_b.id_name === 'id_card') {
                return renderDocumentFields('', 'Photo ID card issued by federal, state, or local government agency');
            };
            if (data.class_b.id_name === 'school_id') {
                return renderDocumentFields('', "School ID");
            };
            if (data.class_b.id_name === 'voter_card') {
                return renderDocumentFields('', "Voter’s registration card")
            };
            if (data.class_b.id_name === 'military_card') {
                return renderDocumentFields(
                    '',
                    "US Military card or draft record",
                    [{ value: 'card', label: 'US Military card' }, { value: 'draft', label: 'US Military draft record' }]
                )
            };
            if (data.class_b.id_name === 'military_department_card') {
                return renderDocumentFields('', "Military dependent’s ID card");
            };
            if (data.class_b.id_name === 'coast_guard_card') {
                return renderDocumentFields('', "US Coast Guard Merchant Mariner Card")
            };
            if (data.class_b.id_name === 'native_american_document') {
                return renderDocumentFields('', 'Native American tribal document')
            };
            if (data.class_b.id_name === 'canada_driver_license') {
                return renderDocumentFields('', "Driver’s license issued by the Canadian government")
            };
        };
        if (data?.underage) {
            if (data.underage.id_name === 'school_record') {
                return renderDocumentFields('', "School record or report card")
            };
            if (data.underage.id_name === 'hospital_record') {
                return renderDocumentFields('', "Clinic, doctor or hospital record");
            };
            if (data.underage.id_name === 'nursery_school_record') {
                return renderDocumentFields(
                    '',
                    "Daycare or nursery school record",
                    [{ value: 'day_care', label: 'Day-care record' }, { value: 'nursery', label: 'Nursery school record' }]
                )
            };
        };
    };

    const fieldsClassCConstructor = (data: any) => {
        if (data?.class_c) {
            if (data.class_c.id_name === 'us_passport') {
                return renderDocumentFields('secondary', "Social Security Card", [], false);
            };
            if (data.class_c.id_name === 'birth_certificate') {
                return renderDocumentFields(
                    'secondary',
                    "Certification of report of birth issued by the Department of State (Forms DS-1350, FS-545, FS-240)",
                    [{ value: 'birth_cert', label: 'Birth Certificate (DS-1350)' },
                    { value: 'abroad_cert', label: 'Birth Abroad Certification (FS-545)' },
                    { value: 'consular_report', label: "Consular Report of Birth Abroad (FS-240)" }],
                    false
                );
            };
            if (data.class_c.id_name === 'original_birth_certificate') {
                return renderDocumentFields(
                    'secondary',
                    "Original or certified birth certificate issued by a US state, county, municipality, or territory",
                    [{ value: 'state_birth_cert', label: 'State Birth Certificate' },
                    { value: 'country_birth_cert', label: 'County Birth Certificate' },
                    { value: 'municipal_birth_cert', label: "Municipal Authority Birth Certificate)" },
                    { value: 'territory_birth_cert', label: "Territory of US Birth Certificate" }],
                    false
                );
            };
            if (data.class_c.id_name === 'native_american') {
                return renderDocumentFields('secondary', "Native American tribal document", [], false);
            };
            if (data.class_c.id_name === 'form_i-197') {
                return renderDocumentFields('secondary', "US Citizen ID Card (I-197)", [], false);
            };
            if (data.class_c.id_name === 'form_i-179') {
                return renderDocumentFields('secondary', "Resident Citizen ID Card (I-179)", [], false);
            };
            if (data.class_c.id_name === 'employment_authorization_document') {
                return renderDocumentFields('secondary', "Employment authorization issued by the Department of Homeland Security", [], false);
            };
        };
    };

    return (
        <ContentContainer>
            <StepTitle>Enter document Information</StepTitle>
            <StepDesc>
                Enter the information for the documents provided by the employee
            </StepDesc>
            <ListsContainer>
                {fieldsClassAConstructor(data)}
                {fieldsClassBConstructor(data)}
                {fieldsClassCConstructor(data)}
            </ListsContainer>
        </ContentContainer>
    )
};

const ContentContainer = styled.div`
    overflow-y: auto;
    flex: 1;
    padding-top: 60px;
`;

const ListsContainer = styled.div`
    display: flex;
    flex: 1;
    padding-block: 50px;
    margin-right: 60px;
    margin-bottom: 20px;
`;

const SingleListContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-inline: 5px;
    flex: 0 1 33.3%;
`;

const StyledFieldItem = styled.div`
    margin-right: 10px;
    margin-bottom: 20px;
    & sup {
        color: #C54343;
    }
    & > label {
        display: inline-block;
        margin-bottom: 6px;
    }
`;

const ListTitleContainer = styled.div`
    font-size: 16px;
    color: #339966;
    font-family: 'Aspira Wide Demi';
    text-align: left;
    margin-bottom: 25px;
`;

const RadioButtonsError = styled.span`
    color: var(--red);
    margin-top: 6px;
    font-size: 12px;
    display: inline-block;
`;