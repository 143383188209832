import styled from "styled-components";
import { useSelector } from "react-redux";
import { currentUserSelector } from "redux/selectors";
import format from "date-fns/format";
import isToday from "date-fns/isToday";

import { StepTitle, StepDesc } from "./Welcome";
import Documents from '../../Employee/documents';

interface IDocsUpload {
    updatedAt: Date
};

export default function Docs({ updatedAt }: IDocsUpload) {
    const { employee } = useSelector(currentUserSelector);

    return (
        <ContentContainer>
            <StepTitle>Upload Documents</StepTitle>
            <StepDesc>
                Upload your documents to share with us.
                <span>Last Saved {isToday(new Date(updatedAt)) ? 'Today' : format(new Date(updatedAt), 'PP')} at {format(new Date(updatedAt), 'p')}</span>
            </StepDesc>
            <DocsListContainer>
                <Documents person={{ id: employee.id }} />
            </DocsListContainer>
        </ContentContainer>
    )
};

const ContentContainer = styled.div`
    flex: 1;
    padding-top: 60px;
    overflow-y: auto;
`;

const DocsListContainer = styled.div`
    margin-top: 20px;
    margin-right: 60px;
`;