
import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import EducationInformationEdit from "./edit";
import EducationInformationDelete from "./delete";
import Section from "../section";
import {
    createEducation,
    getEmployeeEducation,
    deleteEmployeeEducation,
    updateEducation
} from 'services'
import { useToasts } from "react-toast-notifications";
import { isEmpty } from "lodash";

import { ReactComponent as EditIcon } from 'assets/svg/pen-circle.svg';
import { ReactComponent as TrashCanIcon } from 'assets/svg/trash-can-circle.svg';

const Wrapper = styled.div`
    .table{
        padding: 20px 0;
       
        .table-header{
            margin: 0px 10px;
            padding: 0 20px;
            font-weight: bold;
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
            color: #172B37;
            font-size: 14px;
            font-family: 'Aspira Demi';

            div {
                &:last-child {
                    width: 5%;
                }
                width: 21%;
            }
        }

        .table-row{
            margin: 0px 10px;
            padding: 13px 20px;
            font-size: 13px;
            border-top: 1px solid #F8F8F8;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #414141;
            :hover{
                background: #EEEEEE;
                .actions{
                    visibility: visible;
                }
            }

            div {
                width: 25%;
                &:last-child {
                    width: 5%;
                }
            }

            .actions{
                display: flex;
                visibility: hidden;
                align-items: center;
                justify-content: flex-end;
            }
        }
    }
`;

const NoData = styled.div`
    padding: 20px 25px;
    font-size: 13px;
    color: #80888D;
`;

const EducationInformation = ({ person, match, onSave, disabled }: any) => {
    const [loadingRequest, setLoadingRequest] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [educationList, setEducationList] = useState<any>([]);
    const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
    const [educationId, setEducationId] = useState<any>(null);
    const [chosenEducation, setChosenEducation] = useState<any>(null);
    const [editMode, setEditMode] = useState<boolean>(false);
    const { addToast } = useToasts();
    const [editErrors, setEditErrors] = useState<any>([]);

    useEffect(() => {
        if (match.params.id || person.id) {
            getEmployeeEducation(25, 1, match.params.id ?? person.id).then(res => setEducationList(res.data.list));
        }
    }, [match.params.id, person.id])

    const handleEditSubmit = (data: any) => {
        if (editMode) {
            setLoadingRequest(true);
            updateEducation(data, chosenEducation.id).then(res => {
                setLoadingRequest(false);
                setIsOpen(false);
                getEmployeeEducation(25, 1, match.params.id ?? person.id).then(res => setEducationList(res.data.list));
                addToast('Education successfully updated', {
                    appearance: 'success',
                    autoDismiss: true,
                })
                onSave && onSave();
            }).catch(err => {
                setLoadingRequest(false);
                addToast(<div><span style={{ fontWeight: 'bold' }}> There were some errors with your form.</span> <span style={{ marginTop: 6, display: 'inline-block' }}>Please Fix The Highlighted Fields Below.</span></div>, {
                    appearance: 'error',
                    autoDismiss: true,
                    placement: 'top-center'
                });
                setEditErrors(err.response.data.errors)
            })
        } else {
            setLoadingRequest(true);
            createEducation(data, person.id).then(res => {
                setLoadingRequest(false);
                setIsOpen(false);
                getEmployeeEducation(25, 1, match.params.id ?? person.id).then(res => setEducationList(res.data.list));
                addToast('Education successfully added', {
                    appearance: 'success',
                    autoDismiss: true,
                })
                onSave && onSave();
            }).catch(err => {
                setLoadingRequest(false);
                addToast(<div><span style={{ fontWeight: 'bold' }}> There were some errors with your form.</span> <span style={{ marginTop: 6, display: 'inline-block' }}>Please Fix The Highlighted Fields Below.</span></div>, {
                    appearance: 'error',
                    autoDismiss: true,
                    placement: 'top-center'
                });
                setEditErrors(err.response.data.errors)
            })
        }

    }

    const handleDeleteSubmit = (data: any) => {
        setLoadingRequest(true);
        deleteEmployeeEducation(educationId).then((res: any) => {
            setLoadingRequest(false);
            const filteredList = educationList.filter((item: any) => item.id !== res.data.id)
            setEducationList(filteredList);
            setIsDeleteOpen(false);
            addToast('Education successfully deleted', {
                appearance: 'success',
                autoDismiss: true,
            })
            onSave && onSave();
        });
    }

    return (
        <Wrapper>
            <Section
                title='Education'
                onEditClick={() => {
                    setEditMode(false)
                    setChosenEducation([])
                    setIsOpen(true)
                }}
                rightText='Add'
                disabled={disabled}
            >
                {!educationList.length ? <NoData>No Education Information entries have been added.</NoData> : <div className='table'>
                    <div className='table-header'>
                        <div>College / Institution </div>
                        <div>Degree</div>
                        <div>Major / Specialization</div>
                        <div>GPA</div>
                        <div>Start Date</div>
                        <div>End Date</div>
                        <div></div>
                    </div>
                    <div>
                        {educationList.map((item: any) => <div className='table-row' key={item.id}>
                            <div>{isEmpty(item.institution) ? '-' : item.institution}</div>
                            <div>{item.education_degree?.name ?? '-'}</div>
                            <div>{isEmpty(item.specialization) ? '-' : item.specialization}</div>
                            <div>{isEmpty(item.gpa) ? '-' : item.gpa}</div>
                            <div>{item.start_date ?? '-'}</div>
                            <div>{item.end_date ?? '-'}</div>
                            <div className='actions'>
                                {disabled ? null : <><StyledEditIcon onClick={() => {
                                    setChosenEducation(item);
                                    setIsOpen(true);
                                    setEditMode(true);
                                }} />
                                    <StyledTrashIcon onClick={() => {
                                        setIsDeleteOpen(true);
                                        setEducationId(item.id);
                                    }} /></>}
                            </div>
                        </div>)}
                    </div>
                </div>}

                <EducationInformationDelete
                    isOpen={isDeleteOpen}
                    onModalClose={() => setIsDeleteOpen(false)}
                    onDelete={handleDeleteSubmit}
                    loadingRequest={loadingRequest}
                />

                <EducationInformationEdit
                    isOpen={isOpen}
                    chosenEducation={chosenEducation}
                    user={person}
                    jobData={person.active_job_detail}
                    onModalClose={() => {
                        setEditMode(false)
                        setIsOpen(false)
                    }}
                    onSubmit={handleEditSubmit}
                    editMode={editMode}
                    editErrors={editErrors}
                    loadingRequest={loadingRequest}
                />
            </Section>
        </Wrapper>
    );
};

export default withRouter(EducationInformation);

const StyledEditIcon = styled(EditIcon)`
  cursor: pointer;
  margin-right: 5px;
`;

const StyledTrashIcon = styled(TrashCanIcon)`
  cursor: pointer;
  margin-right: 0;
`;
