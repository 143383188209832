import { Fragment, useEffect, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import styled from "styled-components";
import { peopleDirPathSelector, currentUserSelector, globalErrorSelector } from "redux/selectors";
import useQuery from "hooks/useQueryCustom";
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import DialogModal from 'components/Modal/Dialog';
import CompanyActions from "./CompanyActions";
import EmployeeSearchField from "./EmployeeSearch";
import AccountMenu from './AccountMenu';
import ExceededModal from 'containers/people/ExceededModal';
import PermissionGate from 'permissions/PermissionGate';
import FinishRegistrationModal from './FinishRegistration';
import { usePermissionGate } from 'permissions/usePermissionGate';

import { ReactComponent as SettingIcon } from 'assets/svg/gear_circle.svg';
import { ReactComponent as ProfestoLogo } from 'assets/svg/profesto_logo.svg';
import { ReactComponent as TimerIcon } from 'assets/svg/timer-with-x_circles.svg';
import { ReactComponent as HamburgerMenuIcon } from 'assets/svg/hamburger-lines.svg';

const AuthorizedHeader = () => {
  const [portalTargetLink, setPortalTargetLink] = useState<string>('')
  const { pathname } = useLocation();
  const { role, isRegFinished } = usePermissionGate();
  const peopleDir = useSelector(peopleDirPathSelector);
  const currentUser = useSelector(currentUserSelector);
  const globalError = useSelector(globalErrorSelector);

  const isPeoplePathMenu = pathname.includes('people') ||
    pathname.includes('createperson') ||
    pathname.includes('list') ||
    pathname.includes('employee') ||
    pathname === '/';

  const { refetch: refetchSubscriptionInfo, data, isLoading } = useQuery<any>(["subscription_info"], {
    endpoint: 'billing/subscription',
    options: { method: "get" },
  }, { refetchOnMount: false, refetchOnWindowFocus: false, refetchOnReconnect: false });

  useEffect(() => {
    if (globalError?.code === '2') {
      refetchSubscriptionInfo();
    }
  }, [globalError?.code, refetchSubscriptionInfo]);

  const { refetch: getPortalLink, isFetching } = useQuery<any>(["portal_url"], {
    endpoint: `billing/portal_link?to=${portalTargetLink}`,
    options: { method: "get" },
  }, { enabled: false });

  const renderRemainingDays = (day: number) => {
    if (day === 2) return 'Tomorrow';
    if (day === 1) return 'Today';
    return `In ${day} Days`;
  };

  const onClickChoosePlan = async () => {
    const { data } = await getPortalLink();
    window.open(data.url, '_blank');
    setPortalTargetLink('');
  };

  useEffect(() => {
    if (portalTargetLink) {
      onClickChoosePlan();
    }
  }, [portalTargetLink]);

  return (
    <Fragment>
      <Container>
        <NavLeftSideContainer>
          <Link to='/'>
            <ProfestoLogo />
          </Link>
          <NavContainer>
            <ul>
              <li><NavLink className={isPeoplePathMenu ? 'active' : ''} to={`/${peopleDir}`}>People</NavLink></li>
              <li><NavLink to="/myinfo">My Info</NavLink></li>
              <li><NavLink to='/reports/'>Reports</NavLink></li>
            </ul>
          </NavContainer>
        </NavLeftSideContainer>
        <NavRightSideContainer>
          {role === 'owner' && data && data.status === 'trialing' && <TrialInformerContainer>
            <p>Trial Ends {isLoading ?
              <CircularProgress size={15} sx={{ margin: "0 16px" }} /> :
              renderRemainingDays(data.trial)} <br /><span onClick={() => setPortalTargetLink('update_plan')}>CHOOSE A PLAN</span>
              {isFetching ?
                <CircularProgress sx={{ position: 'absolute', marginTop: 0.5, marginLeft: 0.3 }} size={10} /> : null}
            </p>
          </TrialInformerContainer>}
          <PermissionGate on='search_employee'>
            <EmployeeSearchField />
          </PermissionGate>
          <CompanyActionsContainer>
            <CompanyActions />
          </CompanyActionsContainer>
          <PermissionGate on='search_employee'>
            <NavLink
              className='settings'
              activeClassName='settings-active'
              to={role === 'owner' ? '/settings/company_info' : '/settings/employee_access'}
            >
              <SettingIcon />
            </NavLink>
          </PermissionGate>
          <AccountMenuContainer>
            <AccountMenu />
          </AccountMenuContainer>
          <HamburgerMenuContainer>
            <HamburgerMenuIcon />
          </HamburgerMenuContainer>
        </NavRightSideContainer>
      </Container>
      <DialogModal open={globalError?.code === '1'} withoutHeader upperPosition>
        <ModalContentContainer>
          <TimerIcon />
          {role === 'owner' ? <Fragment>
            <ModalMainText>
              Your Profesto trial for the Company "{currentUser.company?.name}" has expired.
            </ModalMainText>
            <ModalSecondaryText>
              Please <span onClick={() => setPortalTargetLink('update_quantity')}>
                Upgrade available seat number
              </span> or <span onClick={() => setPortalTargetLink('update_plan')}>
                Choose another plan
              </span> in order to continue working with Profesto
              {isFetching ? <LinearProgress /> : <div style={{ height: 4 }}></div>}
            </ModalSecondaryText>
          </Fragment> : <Fragment>
            <ModalMainText>
              Your Profesto trial for the Company "{currentUser.company?.name}" has expired.
            </ModalMainText>
            <ModalSecondaryText>
              Please contact your company Account Owner
            </ModalSecondaryText>
          </Fragment>}
        </ModalContentContainer>
      </DialogModal>
      <ExceededModal
        open={globalError?.code === '2'}
        subscriptionData={data}
        withButtons={false}
        withoutHeader
        contactDetailsVisible
      />
      <FinishRegistrationModal
        open={!isRegFinished}
      />
    </Fragment>
  );
};

export default AuthorizedHeader;

const Container = styled('div')(({ theme }) => ({
  padding: "10px 60px",
  height: 62,
  background: "var(--header-dark)",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  zIndex: 9,
  flexShrink: 0,
  [theme.breakpoints.down('md')]: {
    padding: "10px 20px",
  },
  [theme.breakpoints.down('sm')]: {
    padding: "10px 16px",
  },
}));

const NavLeftSideContainer = styled('div')`
  display: flex;
  align-items: center;
`;

const NavContainer = styled('div')(({ theme }) => ({
  marginLeft: 60,
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
  ul: {
    display: "flex",
    li: {
      "a.active": {
        color: "var(--green)",
        borderBottom: "3px solid var(--green)"
      },
      a: {
        color: "white",
        width: 130,
        fontSize: 16,
        fontFamily: "'Aspira Wide Demi'",
        padding: "20px 30px",
        borderBottom: "3px solid transparent",
        transition: "all 0.15s",
        ":hover": {
          color: "var(--green)",
          borderBottom: "3px solid var(--green)"
        }
      }
    }
  }
}));

const NavRightSideContainer = styled('div')(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  ".settings": {
    marginRight: 12,
    width: 40,
    height: 40,
    "&:hover": { "& path:first-child": { fill: "#215549" } },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  ".settings-active": { "& path:first-child": { fill: "#215549" } }
}));

const TrialInformerContainer = styled('div')(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  height: 40,
  marginRight: "16.6rem",
  backgroundColor: "#243844",
  borderRadius: 30,
  padding: "0 15px",
  textAlign: 'center',
  [theme.breakpoints.down('lg')]: {
    marginRight: 50,
  },
  "& > p": {
    color: "#FFFFFF",
    fontSize: 14,
    [theme.breakpoints.down('sm')]: {
      fontSize: 9,
    },
    "& > span": {
      fontSize: 14,
      fontFamily: "'Aspira Demi'",
      textDecoration: "underline",
      textTransform: "uppercase",
      color: "#FF9933",
      cursor: "pointer",
      [theme.breakpoints.down('sm')]: {
        fontSize: 11,
      },
    }
  }
}));

const CompanyActionsContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    display: 'none'
  },
}));

const AccountMenuContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const HamburgerMenuContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 40,
  height: 40,
  backgroundColor: '#243844',
  borderRadius: 20,
  marginLeft: 12,
  cursor: 'pointer',
  "&:hover": { backgroundColor: "#215549" },
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
}));

const ModalContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #FFF;
  padding: 40px 50px;
`;

const ModalMainText = styled.p`
  margin-top: 25px;
  font-size: 15px;
  font-family: 'Aspira Demi';
  color: #676767;
  text-align: center;
`;

const ModalSecondaryText = styled.div`
  margin-top: 10px;
  font-size: 14px;
  color: #676767;
  text-align: center;
  & > span {
    color: #339966;
    text-decoration: underline;
    cursor: pointer;
  }
`;