import styled from "styled-components";
import DialogModal from "components/Modal/Dialog";

import { ReactComponent as TrashCanCircles } from 'assets/svg/trash-can_circles.svg';

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    & > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 40px;
        & > p {
            color: #676767;
            font-size: 14px;
            margin: 25px 0;
        }
    }
`;

const EmploymentHistoryDelete = ({ onModalClose, isOpen, onDelete, loadingRequest }: any) => {
    return (
        <DialogModal
            withButtons
            open={isOpen}
            onClose={onModalClose}
            title="Delete Row"
            actionButtonText="DELETE"
            actionButton={onDelete}
            actionLoading={loadingRequest}
            fullWidth
            upperPosition
        >
            <Wrapper>
                <div>
                    <TrashCanCircles />
                    <p>Are you sure you want to delete this row?</p>
                </div>
            </Wrapper>
        </DialogModal >
    );
};

export default EmploymentHistoryDelete;
