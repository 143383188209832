import { useEffect, ChangeEvent, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import styled from "styled-components";
import { useQueryClient } from "react-query";
import useMutationCustom from "hooks/useMutationCustom";
import useQueryCustom from "hooks/useQueryCustom";
import isEqual from 'date-fns/isEqual';
import isWithinInterval from "date-fns/isWithinInterval";
import isAfter from "date-fns/isAfter";
import { useToasts } from "react-toast-notifications";
import { getTimeOffRequest } from "services";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { NumericFormat } from 'react-number-format';
import RadioButton from "components/RadioButton";
import DialogModal, { IDialogProps } from "components/Modal/Dialog";
import SelectDropdown from "components/Dropdowns/SelectDropdown";
import Checkbox from "components/Checkbox";
import DatePicker from "components/DatePickers/DatePicker";
import UniversalInput from "components/Input/UniversalInput";
import TextArea from "components/TextArea";
import { usePermissionGate } from "permissions/usePermissionGate";

import EmployeeInfoHeader from 'containers/Employee/editHeader';
import PartialDateChoose from "./PartialDateChoose";
import type { IEmployeeMainInfo } from './.';
import differenceInDays from "date-fns/differenceInDays";

interface IRequestTimeOffModal extends IDialogProps {
    employeeInfo: IEmployeeMainInfo,
};

type TFormValues = {
    automatic_approval: boolean,
    time_off_type: {
        id: number,
        name: string,
        deleted: boolean
    } | null,
    date_from: Date | string,
    date_to: Date | string,
    requested_hours: string,
    time_off_period: string,
    note: string
};

type TCalendarRange = {
    id?: number,
    time_off_day: Date,
    time_off_hour: string
};

type TCreateTimeOffArgs = {
    automatic_approval: boolean,
    employee_id: number,
    time_off_type_id: number | undefined,
    time_off_period: string,
    requested_hours: string | number | null,
    date_from: string | Date,
    date_to: string | Date,
    note: string,
    time_off_hours_attributes?: TCalendarRange[]
};

type TRequestError = {
    errors: [{ field: string, message: string, }]
};

type TTimeOffUsedData = {
    hours_requested: string,
    hours_used: string,
    hours_scheduled: string,
};

export default function RequestTimeOffModal({ employeeInfo, open, onClose, ...rest }: IRequestTimeOffModal) {
    const { addToast } = useToasts();
    const queryClient = useQueryClient();
    const { role } = usePermissionGate();
    const [intervalWithHourData, setIntervalWithHourData] = useState<TCalendarRange[]>([]);

    const { control, setValue, watch, reset, handleSubmit, formState: { errors } } = useForm<TFormValues>({
        defaultValues: {
            automatic_approval: false,
            time_off_type: null,
            date_from: '',
            date_to: '',
            requested_hours: '',
            time_off_period: 'full',
            note: '',
        }
    });
    const watchFromDate = watch('date_from');
    const watchToDate = watch('date_to', '');
    const watchTimeOffType = watch('time_off_type');
    const watchRequestedHours = watch('requested_hours');
    const watchTimeOffPeriod = watch('time_off_period');

    const { data: timeOffUsed } = useQueryCustom<TTimeOffUsedData, TRequestError>(["time_off_used", watchTimeOffType?.id], {
        endpoint: `time_off/used?employee_id=${employeeInfo.id}&time_off_type_id=${watchTimeOffType?.id}`,
        options: { method: "get" },
    }, { enabled: open });

    const { mutate: createTimeOff, isLoading: createTimeOffLoading } = useMutationCustom<string[], TRequestError, TCreateTimeOffArgs>(["post_time_off_request"], {
        endpoint: 'time_off/time_off_request', options: { method: "post" },
    }, {
        onSuccess: (_, variables) => {
            addToast(
                variables.automatic_approval ? "You have successfully added and approved Time Off"
                    : "You have successfully requested Time Off",
                { appearance: 'success', autoDismiss: true }
            );
            onClose?.({}, 'escapeKeyDown');
            reset();
            queryClient.invalidateQueries('time_off_requests_list');
            setIntervalWithHourData([]);
        },
        onError: (err) => {
            err.errors.forEach((item) => {
                addToast(item.message, { appearance: 'error', autoDismiss: true });
            });
        }
    });

    useEffect(() => {
        if (watchFromDate && watchToDate) {
            if (isEqual(watchFromDate as Date, watchToDate as Date)) setValue('requested_hours', '8');
            else setValue('requested_hours', '');
        };
    }, [setValue, watchFromDate, watchToDate]);

    const dateValidator = (value: Date | string, type: string) => {
        if (type === 'date_from') {
            if (value > watchToDate as unknown) return 'Date To must be greater than Date From';
        };
        if (type === 'date_to') {
            if (value < watchFromDate as unknown) return 'Date To must be greater than Date From';
            if (differenceInDays(value as Date, watchFromDate as Date) >= 365) return 'Time Off Period should not exceed one year';
        };
        return value === null ? 'Please enter a valid date' : value !== '' || 'Date is required'
    };

    const handleFormSubmit = (data: TFormValues) => {
        let formHoursAttributes = intervalWithHourData.filter(e => isWithinInterval(new Date(e.time_off_day), { start: watchFromDate as Date, end: watchToDate as Date }));

        let formData = {
            employee_id: employeeInfo.id,
            automatic_approval: data.automatic_approval,
            time_off_type_id: data.time_off_type?.id,
            date_from: data.date_from,
            date_to: data.date_to,
            requested_hours: data.requested_hours || null,
            time_off_period: data.time_off_period,
            note: data.note,
            time_off_hours_attributes: formHoursAttributes
        };
        createTimeOff(formData);
    };

    const businessDays = (start: Date, end: Date): number => {
        let count = 0;
        let current = new Date(start);

        while (current <= end) {
            let day = current.getDay();
            if (day !== 0 && day !== 6) {
                count++;
            };
            current.setDate(current.getDate() + 1);
        };

        return count;
    };

    const renderRequestedHours = (fromDate: Date, toDate: Date, intervalWithHourData: TCalendarRange[]) => {
        if (intervalWithHourData.length && fromDate && toDate && !isAfter(fromDate, toDate)) {
            let filterRange = intervalWithHourData.filter(e => isWithinInterval(new Date(e.time_off_day), { start: fromDate, end: toDate }));
            return `${filterRange.reduce((accumulator, currentValue) => accumulator + +currentValue.time_off_hour, 0)}  Hours Requested`;
        };
        if (watchRequestedHours) return `${watchRequestedHours} Hours Requested`;
        if (fromDate && toDate) {
            const businessDaysBetween = businessDays(fromDate as Date, toDate as Date);
            return `${businessDaysBetween * 8} Hours Requested`;
        };
        return '0 Hours Requested'
    };

    const handlePartialCalendarChangeRange = (range: TCalendarRange[]) => {
        setIntervalWithHourData(range);
    };

    const handlePartialCalendarChangeCell = (cell: TCalendarRange) => {
        let computedRange = intervalWithHourData.map(e => {
            if (isEqual(cell.time_off_day, e.time_off_day)) {
                return cell;
            } else return e;
        });
        setIntervalWithHourData(computedRange);
    };

    return (
        <DialogModal
            open={open}
            onClose={onClose}
            withButtons
            title="Request Time Off"
            actionButtonText="SEND REQUEST"
            actionButton={handleSubmit(handleFormSubmit)}
            actionLoading={createTimeOffLoading}
            nominalHeader={role !== 'employee' ?
                <EmployeeInfoHeader
                    employeeName={`${employeeInfo.first_name} ${employeeInfo.last_name}`}
                    avatarUuid={employeeInfo.uuid}
                    employeeId={employeeInfo.id}
                    jobData={employeeInfo.active_job_detail}
                /> : <></>}
            maxWidth={'sm'}
            fullWidth
            {...rest}
        >
            <FieldsContainer>
                {role !== 'employee' && <Controller
                    name="automatic_approval"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <Checkbox
                            checked={value}
                            onChange={onChange}
                            label={'Record Time Off  without managers approval'}
                        />
                    )}
                />}
                <div>
                    <Controller
                        name="time_off_type"
                        control={control}
                        rules={{ required: 'Time Off Type is required' }}
                        render={({ field: { onChange, value } }) => (
                            <SelectDropdown
                                inputPlaceholder='Select Time Off Type'
                                onChange={(_event: React.SyntheticEvent<Element, Event>, newValue: unknown) => {
                                    onChange(newValue)
                                }}
                                value={value}
                                loadRemoteData={() => getTimeOffRequest(100, 1)}
                                errorText={errors.time_off_type?.message}
                                label={'Time Off Type'}
                                required
                            />
                        )}
                    />
                </div>
                <DatePickersContainer>
                    <div>
                        <Controller
                            name="date_from"
                            control={control}
                            rules={{ validate: value => dateValidator(value, 'date_from') }}
                            render={({ field: { onChange, value, ref } }) => (
                                <DatePicker
                                    ref={ref}
                                    selected={value}
                                    onChange={(date, event) => {
                                        !watchToDate && setValue('date_to', date as Date);
                                        if (watchFromDate && watchToDate) {
                                            isEqual(watchFromDate as Date, watchToDate as Date) && setValue('date_to', date as Date);
                                        };
                                        onChange(date, event);
                                    }}
                                    errorText={errors.date_from?.message}
                                    label={'From'}
                                    required
                                />
                            )}
                        />
                    </div>
                    <div>
                        <Controller
                            name="date_to"
                            control={control}
                            rules={{ validate: value => dateValidator(value, 'date_to') }}
                            render={({ field: { onChange, value, ref } }) => (
                                <DatePicker
                                    ref={ref}
                                    selected={value}
                                    onChange={onChange}
                                    errorText={errors.date_to?.message}
                                    label={'To'}
                                    required
                                />
                            )}
                        />
                    </div>
                </DatePickersContainer>
                {watchFromDate && watchToDate && isEqual(watchFromDate as Date, watchToDate as Date) && <Controller
                    control={control}
                    name="requested_hours"
                    rules={{
                        required: 'Requested Hours is required',
                        validate: value => +value <= 24 || "A maximum of 24 hours is allowed"
                    }}
                    render={({ field: { onChange, value, ref } }) => (
                        <NumericFormat
                            onValueChange={(values) => onChange(values.value)}
                            value={value}
                            inputRef={ref}
                            customInput={UniversalInput}
                            decimalSeparator="."
                            decimalScale={2}
                            valueIsNumericString
                            required
                            label={'Requested Hours'}
                            errorText={errors.requested_hours?.message}
                        />
                    )}
                />}
                {watchFromDate && watchToDate && !isEqual(watchFromDate as Date, watchToDate as Date) && <Controller
                    control={control}
                    name="time_off_period"
                    render={({ field }) => (
                        <RadioGroup
                            row
                            {...field}
                            onChange={(_, value) => {
                                field.onChange(value);
                                if (value === 'full') setIntervalWithHourData([]);
                            }}>
                            <FormControlLabel
                                value={'full'}
                                control={<RadioButton />}
                                label={'Full Days'}
                            />
                            <FormControlLabel
                                value={'partial'}
                                control={<RadioButton />}
                                label={'Partial Days'}
                            />
                        </RadioGroup>
                    )}
                />}
                {watchTimeOffPeriod === 'partial' && watchFromDate && watchToDate && !isEqual(watchFromDate as Date, watchToDate as Date) && <PartialDateChoose
                    onChangeRange={handlePartialCalendarChangeRange}
                    onChangeCell={handlePartialCalendarChangeCell}
                    intervalWithHourData={intervalWithHourData}
                    startDate={watchFromDate as Date}
                    endDate={watchToDate as Date}
                />}
                <SummarizedInfoBox>
                    <p>{renderRequestedHours(watchFromDate as Date, watchToDate as Date, intervalWithHourData)}</p>
                    <p>{timeOffUsed && +timeOffUsed?.hours_used % 1 === 0 ? parseInt(timeOffUsed?.hours_used) : timeOffUsed?.hours_used} Hours  Used (YTD)</p>
                    <p>{timeOffUsed && +timeOffUsed?.hours_scheduled % 1 === 0 ? parseInt(timeOffUsed?.hours_scheduled) : timeOffUsed?.hours_scheduled} Hours  Scheduled</p>
                </SummarizedInfoBox>
                <div>
                    <Controller
                        name="note"
                        rules={{ maxLength: { value: 2500, message: 'Max character is 2500' } }}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <TextArea
                                onChange={(event: ChangeEvent<HTMLTextAreaElement>) => { onChange(event.target.value) }}
                                maxRows={5}
                                value={value}
                                label={'Note'}
                                maxLength={2500}
                            />
                        )}
                    />
                </div>
            </FieldsContainer>
        </DialogModal>
    )
};

const FieldsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const DatePickersContainer = styled.div`
    display: flex; 
    flex-direction: row; 
    gap: 20px;
    & > div {
        flex: 1;
    }
`;

const SummarizedInfoBox = styled.div`
    padding: 15px;
    border-radius: 4px;
    max-width: 50%;
    background-color: #F6F7F8;
`;