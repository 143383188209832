import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import Section, { ListItem } from "../section";
import ContactInformationEdit from './edit';
import { getContactInformation, updateContactInformation } from 'services'
import { useToasts } from "react-toast-notifications";
import { WarningDialog } from "components/DesignUIComponents";
import { useSelector } from "react-redux";
import { currentUserSelector } from "redux/selectors";

const Wrapper = styled.div`
    .section-body{
        padding: 20px;

        h4{
            color: #172B37;
            font-weight: bold;
            font-size: 14px;
        }
        
        .list-item .title{
            width: 170px;
            display: inline-block;
            text-align: right;
        }
    }
`;

const NoData = styled.div`
    padding-top: 13px;
    padding-left: 35px;
    font-size: 13px;
    color: #80888D;
    padding-bottom: 20px;
`;

const LinkWrapper = styled.span`
   &:hover {
        color: #FF9933;
   }
   cursor: pointer;
   text-decoration: underline; 
`;

const ContactInformation = ({ person, refreshEmployeeInfo, match, disabled }: any) => {
    const history = useHistory();
    const [loadingRequest, setLoadingRequest] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [contactInfo, setContactInfo] = useState<any>([]);
    const { addToast } = useToasts();
    const [editErrors, setEditErrors] = useState<any>([]);
    const [warningOpen, setWarningOpen] = useState<boolean>(false);
    const [changeEmails, setChangeEmails] = useState<any>([]);
    const currentUser = useSelector(currentUserSelector)

    useEffect(() => {
        if (match.params.id || person.id) {
            getContactInformation(match.params.id ?? person.id).then(res => setContactInfo(res.data))
        }
    }, [match.params.id, person.id])

    const warningTitle = () => {
        return changeEmails && changeEmails.length > 1 ? ' Personal and Work Email Change' : (changeEmails[0] === 'work_email' ? 'Work Email Change' : 'Personal Email Change')
    }

    const warningText = () => {
        return <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>{person.id === currentUser.employee.id ? 'Your' : 'Employee'}
                {changeEmails.length > 1 ? ' Personal and Work ' : (changeEmails[0] === 'work_email' ? ' Work ' : ' Personal ')}
                Email has successfully been updated.</div><br /><br />
            <div style={{ fontWeight: 'bold' }}>Please note that {person.id === currentUser.employee.id ? 'your' : 'employee'} Sign in Email will not automatically be updated.</div>
            {person.id === currentUser.employee.id && <div style={{ marginTop: 2 }}>To update sign in email go to&nbsp;
                <LinkWrapper onClick={() => {setWarningOpen(false); history.push('?action=change_email')}}>Sign in Email Change</LinkWrapper>
            </div>}
        </div>
    }

    const handleEditSubmit = (data: any) => {
        setLoadingRequest(true);
        updateContactInformation(person.id, data).then(res => {
            setLoadingRequest(false);
            setContactInfo(res.data)
            setIsOpen(false);
            refreshEmployeeInfo()
            addToast('Contact Information has been successfully updated', {
                appearance: 'success',
                autoDismiss: true,
            });

            if (res.data.updated_emails && res.data.updated_emails.length) {
                setChangeEmails(res.data.updated_emails)
                setWarningOpen(true)
            }

        }).catch(err => {
            setLoadingRequest(false);
            addToast(err.response.data.errors[0].message, {
                appearance: 'error',
                autoDismiss: true,
            });
            setEditErrors(err.response.data.errors)
        });
    }

    return (
        <Wrapper>
            <Section title='Contact Information' onEditClick={() => setIsOpen(true)} disabled={disabled}>
                <div className='section-body'>
                    {contactInfo ? <div className='row'>
                        <div className='col-md-6'>
                            <div>
                                <h4>Phone</h4>
                                {!contactInfo.work_phone && !contactInfo.mobile_phone && !contactInfo.home_phone ?
                                    <NoData>No Phone Information entries have been added.</NoData>
                                    : <div>
                                        <ListItem title='Work Phone' value={(contactInfo.work_phone ? contactInfo.work_phone : '') +
                                            (contactInfo.work_phone_ext ? ` Ext. ${contactInfo.work_phone_ext}` : '')} />
                                        <ListItem title='Mobile Phone' value={contactInfo.mobile_phone} />
                                        <ListItem title='Home Phone' value={contactInfo.home_phone} />
                                    </div>}

                            </div>

                            <div>
                                <h4>Email</h4>
                                {!contactInfo.personal_email && !contactInfo.work_email ?
                                    <NoData>No Email Information entries have been added.</NoData>
                                    :
                                    <div>
                                        <ListItem title='Work Email' value={contactInfo.work_email} />
                                        <ListItem title='Personal Email' value={contactInfo.personal_email} />
                                    </div>}
                            </div>
                        </div>

                        <div className='col-md-6'>
                            <div style={{ marginLeft: 7.5 }}>
                                <h4>Social Links</h4>
                                {!contactInfo.linkedin && !contactInfo.facebook && !contactInfo.twitter ?
                                    <NoData>No Social Links Information entries have been added.</NoData>
                                    :
                                    <div>
                                        <ListItem title='Linkedin' isLink={true} value={contactInfo.linkedin} />
                                        <ListItem title='Facebook' isLink={true} value={contactInfo.facebook} />
                                        <ListItem title='Twitter' isLink={true} value={contactInfo.twitter} />
                                    </div>}
                            </div>
                        </div>
                    </div> : <div>No contact Information entries have been added.</div>}
                </div>

            </Section>

            <ContactInformationEdit
                isOpen={isOpen}
                user={person}
                jobData={person.active_job_detail}
                contactInfoData={contactInfo}
                onModalClose={() => setIsOpen(false)}
                onSubmit={handleEditSubmit}
                editErrors={editErrors}
                loadingRequest={loadingRequest}
            />

            {warningOpen && <WarningDialog
                title={warningTitle()}
                isOpen={warningOpen}
                onClose={() => setWarningOpen(false)}
                warningText={warningText()}
                withButtons={false}
                actionLoading={loadingRequest}
            />}
        </Wrapper >
    );
};

export default withRouter(ContactInformation);
