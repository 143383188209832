import styled from "styled-components";
import useQuery from "hooks/useQueryCustom";
import { useSelector } from "react-redux";
import { currentUserSelector } from "redux/selectors";
import format from "date-fns/format";
import isToday from "date-fns/isToday";
import CircularProgress from "@mui/material/CircularProgress";

import { StepTitle, StepDesc } from "./Welcome";
import EmergencyBlock from '../../Employee/emergency';
import EducationBlock from '../../Employee/personal/education';
import VisaInfoBlock from '../../Employee/personal/visa';
import PassportBlock from '../../Employee/personal/passport';
import DriverLicenseBlock from '../../Employee/personal/driverslicense';
import AdditionalInfoBlock from '../../Employee/personal/additionalinfo';

interface IAdditionalInfo {
    updatedAt: Date,
    onBlockSave: (e: { continue: boolean }) => void
};

export default function AdditionalInfo({ updatedAt, onBlockSave }: IAdditionalInfo) {
    const { employee } = useSelector(currentUserSelector);

    const { data, isLoading } = useQuery<string[], {}, any>(["get_employee_info"], {
        endpoint: `employee_info/main?employee_id=${employee.id}`,
        options: { method: "get" },
    }, { refetchOnWindowFocus: false });

    if (isLoading) return <LoadingScreenContainer><CircularProgress thickness={4} /></LoadingScreenContainer>;

    return (
        <ContentContainer>
            <StepTitle>Additional Information</StepTitle>
            <StepDesc>
                To complete your profile, please fill in the detailed information below. <br />
                This will ensure we will have the most up-to-date information.
                <span>Last Saved {isToday(new Date(updatedAt)) ? 'Today' : format(new Date(updatedAt), 'PP')} at {format(new Date(updatedAt), 'p')}</span>
            </StepDesc>
            <AdditionalInfoContainer>
                <EmergencyBlock person={data} onSave={() => onBlockSave({ continue: false })} />
                <EducationBlock person={data} onSave={() => onBlockSave({ continue: false })} />
                <VisaInfoBlock person={data} onSave={() => onBlockSave({ continue: false })} />
                <PassportBlock person={data} onSave={() => onBlockSave({ continue: false })} />
                <DriverLicenseBlock person={data} onSave={() => onBlockSave({ continue: false })} />
                <AdditionalInfoBlock person={data} onSave={() => onBlockSave({ continue: false })} />
            </AdditionalInfoContainer>
        </ContentContainer>
    )
};

const ContentContainer = styled.div`
    flex: 1;
    padding-top: 60px;
    overflow-y: auto;
`;

const AdditionalInfoContainer = styled.div`
    margin-top: 20px;
    margin-right: 60px;
`;

const LoadingScreenContainer = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   flex: 1;
`;