import React, { useEffect, useState, Fragment, useMemo, memo } from 'react';
import styled from 'styled-components';
import Text from 'components/Text';
import { useToasts } from 'react-toast-notifications';
import useQueryCustom from 'hooks/useQueryCustom';
import useMutationCustom from 'hooks/useMutationCustom';
import { useQueryClient } from 'react-query';
import InputBase from "@mui/material/InputBase";
import { Controller, useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import Tooltip from "@mui/material/Tooltip";
import { GridInitialState } from "@mui/x-data-grid-pro";
import DataGrid from "components/DataLists/DataGrid";
import DialogModal from 'components/Modal/Dialog';
import {
    GridColumns,
    GridActionsCellItem,
    GridToolbarContainer,
    GridToolbarQuickFilter, GridRenderCellParams, MuiEvent
} from "@mui/x-data-grid-premium";
import { getCountryList, getStateList } from 'services';
import UniversalInput from 'components/Input/UniversalInput';
import Checkbox from 'components/Checkbox';
import SelectDropdown from 'components/Dropdowns/SelectDropdown';
import CircularProgress from '@mui/material/CircularProgress';

import { ReactComponent as EditIcon } from 'assets/svg/pen-circle.svg';
import { ReactComponent as TrashCanIcon } from 'assets/svg/trash-can-circle.svg';
import { ReactComponent as WarningMark } from 'assets/svg/warning-mark_circles.svg';

interface DictionaryProps {
    endpoint: string;
    title: string;
    singularTitle: string;
    withChecking?: boolean;
}

const Dictionary: React.FC<DictionaryProps> = (props) => {
    const queryClient = useQueryClient();
    const [newOptionName, setNewOptionName] = useState<string>('');
    const [isOpen, setModalOpen] = useState<boolean>(false);
    const [editModalOpen, setEditModalOpen] = useState<{ id: number, name: string } | null>(null);
    const [deleteModalOpen, setDeleteModalOpen] = useState<{ id: number, name: string } | null>(null);
    const { addToast } = useToasts();
    const { register, unregister, reset, handleSubmit, setError, setValue, control, watch, formState: { errors } } = useForm({
        defaultValues: {
            name: '',
            remote_location: false,
            country: null,
            address: '',
            address_details: '',
            city: null,
            region: '',
            state: null,
            postal_code: ''
        }
    });
    const watchCountry: any = watch('country');
    const watchRemoveLocation = watch('remote_location');

    const { data } = useQueryCustom<{ count: number, list: Array<{ id: number, name: string, deleted: boolean }> },
        { errors: [{ field: string, message: string, }] }>(
            ["get_dictionary"], {
            endpoint: props.endpoint,
            options: {
                method: 'get',
                body: {
                    order_direction: 'asc',
                    order: 'name',
                }
            },
        }, { cacheTime: 0, enabled: true });

    const { refetch: refetchDictionary, isFetching: directoryLoading } = useQueryCustom<any>(["get_single_dictionary"], {
        endpoint: props.endpoint + `/${editModalOpen?.id}`,
        options: { method: 'get' },
        onSuccess: (data) => {
            if (data) {
                setValue('name', data.name);
                setValue('remote_location', data.remote_location);
                setValue('country', data.country);
                setValue('address', data.address);
                setValue('address_details', data.address_details);
                setValue('city', data.city);
                setValue('region', data.region);
                setValue('state', data.state);
                setValue('postal_code', data.postal_code);
            }
        }
    }, { cacheTime: 0, enabled: false });

    const createDictionaryOption = useMutationCustom<{}, { errors: [{ field: string, message: string, }] }, { name: string }>(
        ["create_dictionary"], {
        endpoint: props.endpoint,
        options: { method: "post" },
    }, {
        onSuccess: () => {
            setModalOpen(false);
            setNewOptionName('');
            addToast(`${props.singularTitle} has been added successfully!`, { appearance: 'success', autoDismiss: true });
            queryClient.invalidateQueries('get_dictionary');
            reset();
        },
        onError: (err) => {
            if (err?.errors[0].field) {
                err.errors.forEach((item: any) => {
                    setError(item.field, { type: 'custom', message: item.message });
                });
            };
        }
    });

    const updateDictionaryOption = useMutationCustom<{}, { errors: [{ field: string, message: string, }] }, { name: string }>(
        ["update_dictionary"], {
        endpoint: props.endpoint + `/${editModalOpen?.id}`,
        options: { method: "put" },
    }, {
        onSuccess: () => {
            setEditModalOpen(null);
            setNewOptionName('');
            addToast(`${props.singularTitle} Updated Successfully!`, { appearance: 'success', autoDismiss: true });
            queryClient.invalidateQueries('get_dictionary')
        }
    });

    const deleteDictionaryOption = useMutationCustom<{}, { errors: string[] }>(
        ["delete_dictionary"], {
        endpoint: props.endpoint + `/${deleteModalOpen?.id}`,
        options: { method: "delete" },
    }, {
        onSuccess: () => {
            setDeleteModalOpen(null);
            addToast(`${props.singularTitle} Deleted Successfully!`, { appearance: 'success', autoDismiss: true });
            queryClient.invalidateQueries('get_dictionary')
        }
    });

    const { data: checkUsageData, refetch: refetchCheckUsage } = useQueryCustom<{ usage: number }, { errors: string[] }>(
        ["check_usage"], {
        endpoint: props.endpoint + `/check_usage/${deleteModalOpen?.id}`,
        options: { method: "get" },
    }, { cacheTime: 0, enabled: false });

    useEffect(() => {
        if (editModalOpen) {
            setNewOptionName(editModalOpen.name);
            props.endpoint === '/location' && refetchDictionary();
        };
    }, [editModalOpen, props.endpoint, refetchDictionary]);

    useEffect(() => {
        if (deleteModalOpen && props.withChecking) {
            refetchCheckUsage();
        } else {
            queryClient.invalidateQueries('check_usage')
        }
    }, [deleteModalOpen]);

    const initialState: GridInitialState = {
        sorting: {
            sortModel: [
                { field: 'name', sort: 'asc' }
            ],
        }
    };

    const onAddNewLocation = (data: any) => {
        let formData = {
            name: data.name,
            remote_location: data.remote_location,
            country_id: data.country?.id,
            address: data.address,
            address_details: data.address_details,
            city: data.city,
            region: data.region,
            state_id: data.state?.id,
            postal_code: data.postal_code
        };
        createDictionaryOption.mutate(formData);
    };

    const onUpdateLocation = (data: any) => {
        let formData = {
            name: data.name,
            remote_location: data.remote_location,
            country_id: data.country?.id,
            address: data.address,
            address_details: data.address_details,
            city: data.city,
            region: data.region,
            state_id: data.state?.id,
            postal_code: data.postal_code
        };
        updateDictionaryOption.mutate(formData);
    };

    const renderAvatarCell = (params: GridRenderCellParams, type: string) => {
        return <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
            <div>{params.row.name}</div>
            <div style={{ display: 'flex' }}>
                <GridActionsCellItem
                    className="actionButton"
                    icon={
                        <Tooltip title='Edit' disableInteractive arrow>
                            <StyledIcon as={EditIcon} />
                        </Tooltip>
                    }
                    label="Edit"
                    onClick={() => { setEditModalOpen({ id: params.row.id, name: params.row.name }) }}
                    sx={{ padding: '3.5px', '&:hover': { backgroundColor: 'transparent' } }}
                />
                <GridActionsCellItem
                    className="actionButton"
                    icon={
                        <Tooltip title='Delete' disableInteractive arrow>
                            <StyledIcon as={TrashCanIcon} />
                        </Tooltip>
                    }
                    label="Delete"
                    onClick={() => setDeleteModalOpen({ id: params.row.id, name: params.row.name })}
                    sx={{ padding: '3.5px', '&:hover': { backgroundColor: 'transparent' } }}
                />
            </div>
        </div>
    };

    const columns = useMemo<GridColumns<any>>(() => [
        {
            field: 'name', headerName: props.title,
            renderCell: (params) => renderAvatarCell(params, 'employee'),
            flex: 1
        }
    ], [setEditModalOpen, props.title]);

    const CustomToolbar = () => {
        return (
            <GridToolbarContainer>
                <GridToolbarQuickFilter
                    sx={quickFilterStyle}
                    variant="outlined"
                    size="small"
                    debounceMs={500}
                />
                <Button
                    onClick={(e) => { e.preventDefault(); reset(); setNewOptionName(''); setModalOpen(true); }}
                    type="button"
                    size='large'
                    variant='contained'
                    sx={{ width: 140, marginLeft: 'auto' }}
                >
                    + New Item
                </Button>
            </GridToolbarContainer>
        )
    };

    return (
        <Fragment>
            <div style={{ display: 'flex', alignContent: 'center' }}>
                <div style={{ width: 3, height: 25, backgroundColor: '#339966', float: 'left', marginRight: 15, }} />
                <Text type="title">{props.title} <span style={{ opacity: 0.6 }}>&nbsp;({data?.count})</span></Text>
            </div>
            <div style={{ overflow: 'auto', marginTop: 20, flex: 1, backgroundColor: '#fff', padding: '18px 15px', borderRadius: 6 }}>

                <div style={{ display: 'flex', height: '100%' }}>
                    {data &&
                        <DataGrid
                            name='dictionary'
                            saveGridState
                            onRowDoubleClick={(params: any, event: MuiEvent<React.MouseEvent>) => {
                                event.defaultMuiPrevented = true;
                                setEditModalOpen({ id: params.row.id, name: params.row.name })
                            }}
                            components={{ Toolbar: CustomToolbar }}
                            rows={data.list}
                            columns={columns}
                            initialState={initialState}
                        />}
                </div>
            </div>

            {(isOpen || !!editModalOpen) && props.endpoint !== '/location' && <DialogModal
                open={(isOpen || !!editModalOpen) && props.endpoint !== '/location'}
                title={(isOpen ? 'Add' : 'Edit') + ' ' + props.singularTitle}
                onClose={() => { updateDictionaryOption.reset(); createDictionaryOption.reset(); editModalOpen ? setEditModalOpen(null) : setModalOpen(false) }}
                actionButton={() => { editModalOpen ? updateDictionaryOption.mutate({ name: newOptionName }) : createDictionaryOption.mutate({ name: newOptionName }) }}
                withButtons
                cancelButtonText='CANCEL'
                actionButtonText='SAVE'
                actionLoading={updateDictionaryOption.isLoading || createDictionaryOption.isLoading}
                upperPosition
            >
                <div style={{ paddingBlock: 10 }}>
                    <InputTitle>{props.singularTitle} Name<sup>*</sup></InputTitle>
                    <StyledInput
                        autoFocus
                        value={newOptionName}
                        onChange={(e) => setNewOptionName(e.target.value)}
                        error={createDictionaryOption.isError}
                    />

                    {createDictionaryOption.isError && <ErrorText>{createDictionaryOption.error.errors.find((error) => error.field === 'name')?.message}</ErrorText>}
                    {updateDictionaryOption.isError && <ErrorText>{updateDictionaryOption.error.errors.find((error) => error.field === 'name')?.message}</ErrorText>}
                </div>
            </DialogModal>}

            <DialogModal
                open={(isOpen || !!editModalOpen) && props.endpoint === '/location'}
                title={(isOpen ? 'Add' : 'Edit') + ' ' + props.singularTitle}
                onClose={() => { updateDictionaryOption.reset(); createDictionaryOption.reset(); editModalOpen ? setEditModalOpen(null) : setModalOpen(false) }}
                actionButton={() => { editModalOpen ? handleSubmit(onUpdateLocation)() : handleSubmit(onAddNewLocation)(); }}
                withButtons
                cancelButtonText='CANCEL'
                actionButtonText='SAVE'
                actionLoading={updateDictionaryOption.isLoading || createDictionaryOption.isLoading}
                maxWidth={'md'}
                upperPosition
            >
                <div style={{ display: 'flex', flexDirection: 'column', paddingBlock: 10, width: 600 }}>
                    {directoryLoading ? <CircularProgress sx={{ alignSelf: 'center' }} /> : <Fragment>
                        <StyledFieldItem>
                            <label>Location Name<sup>*</sup></label>
                            <UniversalInput
                                inputProps={{ maxLength: 250 }}
                                errorText={errors.name ? errors.name.message : ''}
                                {...register('name', { required: 'Location Name is required', maxLength: 250 })}
                            />
                        </StyledFieldItem>
                        <StyledFieldItem>
                            <Controller
                                name="remote_location"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <Checkbox
                                        checked={value}
                                        onChange={(event) => {
                                            onChange(event.target.checked);
                                            if (event.target.checked) {
                                                unregister(['country', 'address', 'address_details', 'city', 'state', 'region', 'postal_code']);
                                            };
                                        }}
                                        label={'This location is for remote employees'}
                                    />
                                )}
                            />
                        </StyledFieldItem>
                        {!watchRemoveLocation && <Fragment>
                            <StyledFieldItem style={{ width: '65%' }}>
                                <label>Country<sup>*</sup></label>
                                <Controller
                                    name="country"
                                    control={control}
                                    rules={{ required: 'Country is required' }}
                                    render={({ field: { onChange, value } }) => (
                                        <SelectDropdown
                                            onChange={(_event: React.SyntheticEvent<Element, Event>, newValue: any) => {
                                                onChange(newValue)
                                            }}
                                            value={value}
                                            inputPlaceholder='Select Country'
                                            loadRemoteData={() => getCountryList(300, 1)}
                                            errorText={errors.country ? errors.country.message : ''}
                                        />
                                    )}
                                />
                            </StyledFieldItem>
                            <StyledFieldItem style={{ width: '65%' }}>
                                <label>Address Line 1<sup>*</sup></label>
                                <UniversalInput
                                    {...register('address', { required: 'Address line 1 is required' })}
                                    errorText={errors.address ? errors.address.message : ''}
                                    placeholder='Street Address, P.O. Box, Company Name, C/O'
                                />
                            </StyledFieldItem>
                            <StyledFieldItem style={{ width: '65%' }}>
                                <label>Address Line 2</label>
                                <UniversalInput
                                    {...register('address_details')}
                                    placeholder='Apartment, Suite, Unit, Building, Floor, Etc'
                                />
                            </StyledFieldItem>
                            <div style={{ display: 'flex' }}>
                                <StyledFieldItem style={{ width: '33%' }}>
                                    <label>City<sup>*</sup></label>
                                    <UniversalInput
                                        errorText={errors.city ? errors.city.message : ''}
                                        {...register('city', { required: 'City is required' })}
                                    />
                                </StyledFieldItem>
                                {watchCountry?.iso === 'US' ? <StyledFieldItem style={{ width: '33%' }}>
                                    <label>State / Province / Region<sup>*</sup></label>
                                    <Controller
                                        name="state"
                                        control={control}
                                        rules={{ required: "State / Province / Region is required" }}
                                        render={({ field: { onChange, value } }) => (
                                            <SelectDropdown
                                                onChange={(_event: React.SyntheticEvent<Element, Event>, newValue: any) => {
                                                    onChange(newValue)
                                                }}
                                                value={value}
                                                loadRemoteData={() => getStateList(200, 1)}
                                                inputPlaceholder={'Select State'}
                                                errorText={errors.state ? errors.state.message : ''}
                                            />
                                        )}
                                    />
                                </StyledFieldItem> : <StyledFieldItem style={{ width: '33%' }}>
                                    <label>State / Province / Region<sup>*</sup></label>
                                    <UniversalInput
                                        {...register('region', { required: 'State / Province / Region is required' })}
                                        errorText={errors.region ? errors.region.message : ''}
                                    />
                                </StyledFieldItem>}
                                <StyledFieldItem style={{ width: '33%' }}>
                                    <label>Zip / Postal Code<sup>*</sup></label>
                                    <UniversalInput
                                        {...register("postal_code", { required: "Zip / Postal Code is required" })}
                                        errorText={errors.postal_code ? errors.postal_code.message : ""}
                                    />
                                </StyledFieldItem>
                            </div>
                        </Fragment>}
                    </Fragment>}
                </div>
            </DialogModal>

            {!!deleteModalOpen && <DialogModal
                open={!!deleteModalOpen}
                title={`Delete ${props.title}`}
                onClose={() => setDeleteModalOpen(null)}
                actionButton={() => { checkUsageData && checkUsageData.usage > 0 ? setDeleteModalOpen(null) : deleteDictionaryOption.mutate() }}
                withButtons
                cancelButtonText='CANCEL'
                actionButtonText={checkUsageData && checkUsageData.usage > 0 ? "I'LL FIX IT" : 'YES, DELETE'}
                actionLoading={deleteDictionaryOption.isLoading}
                upperPosition
                fullWidth
            >
                <Fragment>
                    {((checkUsageData && checkUsageData.usage === 0) || !props.withChecking) && (
                        <div>
                            <div style={{ paddingTop: 20, paddingBottom: 26, display: 'flex', justifyContent: 'center' }}>
                                <WarningMark />
                            </div>
                            <div style={{ paddingBottom: 30, display: 'flex', justifyContent: 'center', paddingInline: '25%', textAlign: 'center' }}>
                                <Text type="medium">
                                    Are you sure you want to delete {props.singularTitle} "{deleteModalOpen?.name}"?
                                </Text>
                            </div>
                        </div>
                    )}

                    {checkUsageData && checkUsageData.usage > 0 && (
                        <div>
                            <div style={{ paddingTop: 40, paddingBottom: 26, display: 'flex', justifyContent: 'center' }}>
                                <WarningMark />
                            </div>
                            <div style={{ paddingBottom: 20, display: 'flex', justifyContent: 'center', paddingInline: '12%', textAlign: 'center' }}>
                                <Text type="medium">
                                    Can't Delete Yet
                                    <br />
                                    You've got {checkUsageData.usage} employees with this {props.singularTitle}!
                                </Text>
                            </div>
                            <div style={{ paddingBottom: 40, display: 'flex', justifyContent: 'center', paddingInline: '12%', textAlign: 'center' }}>
                                <Text type="medium">
                                    Move them to another {props.singularTitle} before deleting this
                                </Text>
                            </div>
                        </div>
                    )}
                </Fragment>
            </DialogModal>}
        </Fragment>
    );
};

export default memo(Dictionary, (prevProps, nextProps) => {
    return prevProps.title !== nextProps.title;
});

const ErrorText = styled.div`
    margin-top: 5px;
    color: #676767;
    font-size: 11px; 
    text-transform: capitalize;
    color: #C54343;
`;

const StyledIcon = styled.svg`
    width: 22px;
    height: 22px;
    &:hover {
        & * circle {
            fill: #396
        }
        path {
            fill: #FFF
        }
    }
`;

const quickFilterStyle = {
    padding: 0,
    width: 440,
    '& input': { padding: '10px 5px' },
    '.MuiButtonBase-root': {
        padding: '5px !important',
        '&:hover': {
            backgroundColor: 'transparent !important',
            'path': { fill: '#636363 !important' }
        },
    },
};

const StyledInput = styled(InputBase)<{ error: boolean }>(({ error }) => ({
    width: 500,
    height: 40,
    padding: '0 8px',
    borderRadius: 4,
    border: error ? '1px solid var(--red)' : '1px solid #D6D6D6',
    "&:hover": {
        borderColor: error ? 'var(--red)' : '#99CC33'
    },
    "&.Mui-focused": {
        borderColor: error ? 'var(--red)' : '#99CC33'
    }
}));

const InputTitle = styled('p')`
    margin-bottom: 8px;
    text-transform: capitalize;
    & > sup {
        color: #C54343;
    }
`;

const StyledFieldItem = styled.div`
    margin-bottom: 20px;
    margin-right: 10px;
    & sup {
        color: #C54343;
    }
    & > label {
        display: inline-block;
        margin-bottom: 6px;
    }
`;
