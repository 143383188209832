import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useForm, Controller } from 'react-hook-form';
import { useParams } from "react-router-dom";
import EmpEditHeader from "../../editHeader";
import DialogModal from "components/Modal/Dialog";
import {
  getEmploymentStatus,
  createEmploymentStatus,
  getJobTitles,
  createJobTitle,
  getJobTerminationReason,
  createTerminationReason,
  getJobTerminationType,
  createTerminationType,
  getEnum,
  getDepartments,
  createDepartment,
  getDivisions,
  createDivision,
  getLocations,
  createLocation,
  getManagerList,
  getJobChangeReason,
  createJobChangeReason
} from "services";
import SelectWithAdd from "components/Dropdowns/SelectWithAdd";
import SelectWithLocationAdd from "components/Dropdowns/SelectWithLocationAdd";
import EnumDropdown from "components/Dropdowns/EnumDropdown";
import SelectDropdown from "components/Dropdowns/SelectDropdown";
import TextArea from "components/TextArea";
import { useToasts } from "react-toast-notifications";
import DatePicker from "components/DatePickers/DatePicker";
import { utcToZonedTime } from 'date-fns-tz';

const JobInfoEdit = (props: any) => {
  const { handleSubmit, watch, setValue, control, setError, clearErrors, formState: { errors } } = useForm({
    shouldFocusError: true,
    defaultValues: {
      effective_date: new Date(),
      employment_status: null,
      job_termination_type: null,
      job_termination_reason: null,
      rehire_eligibility_id: '',
      job_title: null,
      department: null,
      division: null,
      location: null,
      job_change_reason: null,
      manager: null,
      comment: null
    } as any
  });
  const { person, jobData, editMode, updateMode, chosenItem, defaultStatus, exceptStatus, disabled = false } = props;
  const watchValues = watch();
  const [rehireData, setRehireData] = useState([]);
  const [termination, setTermination] = useState<boolean>(false);
  const { addToast } = useToasts();
  const { id: paramsId } = useParams<{ id: string }>();

  const onSubmit = (data: any) => {
    props.onSubmit(data);
  };

  useEffect(() => {
    getEnum(25, 1, 'Enum::RehireEligibility').then(res => setRehireData(res.data.list));
  }, [])

  useEffect(() => {
    clearErrors()
  }, [props.isOpen])

  useEffect(() => {
    if (props.formErrors) {
      props.formErrors.forEach((item: any) => {
        if (item.field && item.field !== 'base') {
          setError(item.field, { type: 'string', message: item.message })
        } else {
          addToast(item.message, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      })
    }
  }, [props.formErrors])

  useEffect(() => {
    setValue('effective_date', chosenItem && !updateMode ? utcToZonedTime(new Date(chosenItem.effective_date), 'UTC') : new Date());
    setValue('employment_status', defaultStatus ?? chosenItem?.employment_status)
    setValue('job_termination_type', chosenItem?.job_termination_type);
    setValue('job_termination_reason', chosenItem?.job_termination_reason);
    setValue('rehire_eligibility_id', chosenItem?.rehire_eligibility?.id ?? '');
    setValue('job_title', chosenItem?.job_title);
    setValue('department', chosenItem?.department);
    setValue('division', chosenItem?.division);
    setValue('location', chosenItem?.location);
    setValue('job_change_reason', updateMode ? null : chosenItem?.job_change_reason);
    setValue('manager', chosenItem?.manager);
    setValue('comment', updateMode ? '' : chosenItem?.comment);
  }, [person, editMode, chosenItem, updateMode, props.isOpen])

  useEffect(() => {
    setTermination(watchValues.employment_status?.id_name === 'terminated')
  }, [watchValues.employment_status]);

  const onError = (err: any) => {
    if (err) {
      addToast(<ToastContentContainer>
        <b>There were some errors with your form.</b> Please Fix The Highlighted Fields Below
      </ToastContentContainer>, {
        appearance: 'error',
        autoDismiss: true,
        placement: 'top-center'
      });
    }
  };

  return (
    <DialogModal
      open={props.isOpen}
      title={`${editMode ? 'Edit' : 'Update'} Job Information`}
      onClose={() => props.onModalClose()}
      actionButton={handleSubmit(onSubmit, onError)}
      hideActionButton={disabled ? true : false}
      withButtons
      cancelButtonText='CANCEL'
      actionButtonText='SAVE'
      actionLoading={props.loadingRequest}
      fullWidth
      nominalHeader={
        <EmpEditHeader
          employeeName={`${person.first_name} ${person.last_name}`}
          avatarUuid={person.uuid}
          employeeId={person.id}
          jobData={jobData}
        />}
    >
      <Wrapper>
        <form>
          <div className='body'>
            <div className='contact-section'>
              <div className='input-item' style={{ width: 200 }}>
                <Controller
                  name="effective_date"
                  control={control}
                  rules={{ validate: value => value === null ? 'Please enter a valid date' : value !== '' || 'Effective Date is required' }}
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      required
                      selected={value}
                      onChange={onChange}
                      label='Effective Date'
                      errorText={errors.effective_date ? errors.effective_date.message : ''}
                      disabled={disabled}
                    />
                  )}
                />
              </div>

              <div className='input-item'>
                <label>Employment Status<sup>*</sup></label>
                <Controller
                  name="employment_status"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, onChange, ref } }) => (
                    <SelectWithAdd
                      name={'employment_status'}
                      inputPlaceholder='Select Employment Status'
                      inputValue={value}
                      disabled={disabled}
                      loadRemoteData={() => getEmploymentStatus(100, 1, false, exceptStatus)}
                      createRequest={createEmploymentStatus}
                      errorText={errors.employment_status ? 'Employment Status is required' : ''}
                      onChange={onChange}
                      ref={ref}
                    />
                  )}
                />
              </div>

              {termination && <div>
                <div className='input-item'>
                  <label>Termination Type</label>
                  <Controller
                    name="job_termination_type"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <SelectWithAdd
                        name='job_termination_type'
                        inputPlaceholder='Select Termination Type'
                        inputValue={value}
                        loadRemoteData={() => getJobTerminationType(100, 1)}
                        createRequest={createTerminationType}
                        setValue={setValue}
                        onChange={onChange}
                      />
                    )}
                  />
                </div>

                <div className='input-item'>
                  <label>Termination Reason</label>
                  <Controller
                    name="job_termination_reason"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <SelectWithAdd
                        name='job_termination_reason'
                        inputPlaceholder='Select Termination Reason'
                        inputValue={value}
                        loadRemoteData={() => getJobTerminationReason(100, 1)}
                        createRequest={createTerminationReason}
                        onChange={onChange}
                        disabled={disabled}
                      />
                    )}
                  />
                </div>

                <div className='input-item'>
                  <label>Eligible for Rehire</label>
                  <Controller
                    name="rehire_eligibility_id"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <EnumDropdown
                        placeholder='Select Eligible for Rehire'
                        onChange={onChange}
                        value={value}
                        options={rehireData}
                        disabled={disabled}
                      />
                    )}
                  />
                </div>
              </div>
              }

              {!termination ? <div>
                <div className='input-item'>
                  <label>Job Title<sup>*</sup></label>
                  <Controller
                    name="job_title"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                      <SelectWithAdd
                        name={'job_title'}
                        inputPlaceholder='Select Job Title'
                        inputValue={value}
                        loadRemoteData={() => getJobTitles(100, 1)}
                        createRequest={createJobTitle}
                        errorText={errors.job_title ? 'Job Title is required' : ''}
                        onChange={onChange}
                        disabled={disabled}
                      />
                    )}
                  />
                </div>

                <div className='input-item'>
                  <label>Department</label>
                  <Controller
                    name="department"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <SelectWithAdd
                        name='department'
                        inputPlaceholder='Select Department'
                        inputValue={value}
                        loadRemoteData={() => getDepartments(100, 1)}
                        createRequest={createDepartment}
                        onChange={onChange}
                        disabled={disabled}
                      />
                    )}
                  />
                </div>

                <div className='input-item'>
                  <label>Division</label>
                  <Controller
                    name="division"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <SelectWithAdd
                        name='division'
                        inputPlaceholder='Select Division'
                        inputValue={value}
                        loadRemoteData={() => getDivisions(100, 1)}
                        createRequest={createDivision}
                        onChange={onChange}
                        disabled={disabled}
                      />
                    )}
                  />
                </div>

                <div className='input-item'>
                  <label>Location</label>
                  <Controller
                    name="location"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <SelectWithLocationAdd
                        name='location'
                        inputPlaceholder='Select Location'
                        inputValue={value}
                        loadRemoteData={() => getLocations(100, 1)}
                        createRequest={createLocation}
                        onChange={onChange}
                        disabled={disabled}
                      />
                    )}
                  />
                </div>

                <div className='input-item'>
                  <label>Manager</label>
                  <Controller
                    name="manager"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <SelectDropdown
                        inputPlaceholder='Select Manager'
                        onChange={(_event: React.SyntheticEvent<Element, Event>, newValue: any) => {
                          onChange(newValue)
                        }}
                        value={value}
                        loadRemoteData={() => getManagerList(100, 1, paramsId ? paramsId : person.id)}
                        withPic
                        disabled={disabled}
                      />
                    )}
                  />
                </div>

                <div className='input-item'>
                  <label>Change Reason</label>
                  <Controller
                    name="job_change_reason"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <SelectWithAdd
                        name='job_change_reason'
                        inputPlaceholder='Select Change Reason'
                        inputValue={value}
                        loadRemoteData={() => getJobChangeReason(100, 1)}
                        createRequest={createJobChangeReason}
                        onChange={onChange}
                        disabled={disabled}
                      />
                    )}
                  />
                </div>
              </div>
                : null}

              <div className='input-item' style={{ marginBottom: 0 }}>
                <label>Comment</label>
                <Controller
                  name="comment"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextArea
                      onChange={(event: any) => { onChange(event.target.value) }}
                      maxRows={5}
                      defaultValue={value}
                      disabled={disabled}
                    />
                  )}
                />
              </div>

            </div>
          </div>

        </form>
      </Wrapper>
    </DialogModal >
  );
};

export default JobInfoEdit;

const ToastContentContainer = styled.div`
    & > b {
        font-family: 'Aspira Demi';
    }
`;

const Wrapper = styled.div`
  .body{
    .input-item{
        max-width: 416px;
        margin-bottom: 15px;

        & > label{
          margin-bottom: 6px;
          margin-top: 6px;
          display: inline-block;
          & > sup {
          color: #C54343;
          }
        }
    }
  }
`;
