import { useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { Link, useHistory, useLocation } from 'react-router-dom';
import UniversalInput from "components/Input/UniversalInput";
import LoadingButton from "@mui/lab/LoadingButton";
import FormContainerTemplate from "../FormContainer";
import { RecoverPasswordService } from 'services'
import { FORM_PATTERNS } from "../../../constants";
import { useToasts } from "react-toast-notifications";

import { ReactComponent as ProfestoLogo } from 'assets/svg/profesto_logo.svg';
import { ReactComponent as ArrowIcon } from 'assets/svg/arrow.svg';

const Recover = () => {
  const { addToast } = useToasts();
  const { state: { email } = {} } = useLocation<any>();
  const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm<{ email: string }>({
    defaultValues: {
      email: email || ''
    }
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const history = useHistory();

  const watchEmail = watch('email');

  const onSubmit = (data: { email: string }) => {
    setIsLoading(true);
    RecoverPasswordService(data.email).then(res => {
      addToast("Now, check your email for instructions on resetting your password", {
        appearance: 'success',
        autoDismiss: true
      });
      setIsLoading(false);
      setValue('email', '')
      history.push('/login')
    }).catch((err) => {
      addToast(err.response.data.errors[0].message, {
        appearance: 'error',
        autoDismiss: true
      });
      setIsLoading(false);
    })
  };

  return (
    <FormContainerTemplate>
      <FormTopContainer>
        <ProfestoLogo
          width={274}
          height={56}
          id='profesto_logo'
        />
        <h3>Enter your email address and we'll send you a link to reset your password.</h3>
      </FormTopContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        <UniversalInput
          size='medium'
          placeholder='Email'
          visiblePlaceholder={watchEmail ? true : false}
          errorText={errors.email ? errors.email.message || FORM_PATTERNS.email.message : ''}
          {...register("email", { required: 'Please Enter Your Email', pattern: FORM_PATTERNS.email })}
        />
        <LoadingButton
          loading={isLoading}
          sx={{ marginTop: 3, fontSize: 14, padding: 2 }}
          fullWidth
          type="submit"
          variant="contained"
        >
          Send Email
        </LoadingButton>
      </form>
      <BackButton to='login' className='backlink'><StyledArrowIcon /> Back to sign in</BackButton>
    </FormContainerTemplate>
  );
};

export default Recover;

const StyledArrowIcon = styled(ArrowIcon) <{ direction?: string, fill?: string }>`
    margin-right: 5px;
    & path {
        fill: #396;
    }
    transform: rotate(90deg);
`;

const FormTopContainer = styled.div`
  margin-bottom: 44px;
  text-align: center;
  & > h3 {
    color: var(--dark-gray);
    margin-top: 20px;
    font-family: 'Aspira Demi';
    font-size: 1.4rem;
    line-height: 2.2rem;
  }
`;

const BackButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--green);
  margin-top: 20px;
  text-transform: uppercase;
`;

