import { useState, useEffect } from "react";
import { useHistory, useLocation } from 'react-router';
import styled from "styled-components"
import Text from 'components/Text';
import PermissionGate from "permissions/PermissionGate";
import { ReactComponent as ArrowDownIcon } from 'assets/svg/arrow.svg';
import useQuery from "../../hooks/useQueryCustom";

const Menu = () => {
    const history = useHistory();
    const [jobInformationVisible, setJobInformationVisible] = useState<boolean>(false);
    const [personalInfoVisible, setPersonalInfoVisible] = useState<boolean>(false);
    let location = useLocation();

    useEffect(() => {
        if (location.pathname.indexOf('/settings/job_information') !== -1) {
            setJobInformationVisible(true);
        }
        if (location.pathname.indexOf('/settings/personal_information') !== -1) {
            setPersonalInfoVisible(true);
        }
    }, [location]);

    const { refetch: getPortalLink } = useQuery<any>(["portal_url"], {
        endpoint: 'billing/portal_link',
        options: { method: "get" },
    }, { enabled: false });

    const onClickChoosePlan = async () => {
        const { data } = await getPortalLink();
        window.open(data.url, '_blank');
    };

    return (
        <SettingMenu>
            <TextContainer>
                <Text type="title">Settings</Text>
            </TextContainer>
            <Divider />

            <PermissionGate on="billing">
                <div style={{ marginBottom: 20 }}>
                    <TextContainer>
                        <Text type="subtitle">APPLICATION SETTINGS</Text>
                    </TextContainer>
                    <ClickableTextContainerMain active={location.pathname.indexOf('/settings/company_info') !== -1}
                        onClick={() => history.push('/settings/company_info')}>
                        <Text type="medium">Company Info</Text>
                    </ClickableTextContainerMain>
                    <ClickableTextContainerMain active={location.pathname.indexOf('/settings/billing') !== -1}
                        onClick={() => onClickChoosePlan()}>
                        <Text type="medium">Subscriptions {'&'} Billing</Text>
                    </ClickableTextContainerMain>
                </div>
            </PermissionGate>

            <TextContainer>
                <Text type="subtitle">SYSTEM ACCESS CONTROL</Text>
            </TextContainer>

            <ClickableTextContainerMain active={location.pathname.indexOf('/settings/employee_access') !== -1}
                onClick={() => history.push('/settings/employee_access')}>
                <Text type="medium">Employee Access</Text>
            </ClickableTextContainerMain>

            <TextContainer largeSpacing>
                <Text type="subtitle">DICTIONARIES</Text>
            </TextContainer>
            <RootClickableTextContainer active={jobInformationVisible} onClick={() => setJobInformationVisible(!jobInformationVisible)}>
                <Text type="medium">Job Information</Text>
                <ArrowDownIcon />
            </RootClickableTextContainer>

            <Submenu open={jobInformationVisible}>
                <ClickableTextContainer
                    active={location.pathname.indexOf('/settings/job_information/department') !== -1}
                    onClick={() => history.push('/settings/job_information/department')}
                >
                    <Text type="medium">Departments</Text>
                </ClickableTextContainer>

                <ClickableTextContainer
                    active={location.pathname.indexOf('/settings/job_information/divisions') !== -1}
                    onClick={() => history.push('/settings/job_information/divisions')}
                >
                    <Text type="medium">Divisions</Text>
                </ClickableTextContainer>

                <ClickableTextContainer
                    active={location.pathname.indexOf('/settings/job_information/location') !== -1}
                    onClick={() => history.push('/settings/job_information/location')}
                >
                    <Text type="medium">Locations</Text>
                </ClickableTextContainer>

                <ClickableTextContainer
                    active={location.pathname.indexOf('/settings/job_information/job_title') !== -1}
                    onClick={() => history.push('/settings/job_information/job_title')}
                >
                    <Text type="medium">Job Titles</Text>
                </ClickableTextContainer>

                <ClickableTextContainer
                    active={location.pathname.indexOf('/settings/job_information/employment_status') !== -1}
                    onClick={() => history.push('/settings/job_information/employment_status')}
                >
                    <Text type="medium">Employment Statuses</Text>
                </ClickableTextContainer>

                <ClickableTextContainer
                    active={location.pathname.indexOf('/settings/job_information/job_change_reason') !== -1}
                    onClick={() => history.push('/settings/job_information/job_change_reason')}
                >
                    <Text type="medium">Job Change Reasons</Text>
                </ClickableTextContainer>

                <ClickableTextContainer
                    active={location.pathname.indexOf('/settings/job_information/compensation_change') !== -1}
                    onClick={() => history.push('/settings/job_information/compensation_change')}
                >
                    <Text type="medium">Compensation Change Reasons</Text>
                </ClickableTextContainer>

                <ClickableTextContainer
                    active={location.pathname.indexOf('/settings/job_information/job_termination_reason') !== -1}
                    onClick={() => history.push('/settings/job_information/job_termination_reason')}
                >
                    <Text type="medium">Termination Reasons</Text>
                </ClickableTextContainer>

                <ClickableTextContainer
                    active={location.pathname.indexOf('/settings/job_information/job_termination_type') !== -1}
                    onClick={() => history.push('/settings/job_information/job_termination_type')}
                >
                    <Text type="medium">Termination Types</Text>
                </ClickableTextContainer>

                <ClickableTextContainer
                    active={location.pathname.indexOf('/settings/job_information/payment_type') !== -1}
                    onClick={() => history.push('/settings/job_information/payment_type')}
                >
                    <Text type="medium">Pay Types</Text>
                </ClickableTextContainer>

                <ClickableTextContainer
                    active={location.pathname.indexOf('/settings/job_information/payment_period') !== -1}
                    onClick={() => history.push('/settings/job_information/payment_period')}
                >
                    <Text type="medium">Pay Rate Periods</Text>
                </ClickableTextContainer>

                <ClickableTextContainer
                    active={location.pathname.indexOf('/settings/job_information/payment_schedule') !== -1}
                    onClick={() => history.push('/settings/job_information/payment_schedule')}
                >
                    <Text type="medium">Pay Schedules</Text>
                </ClickableTextContainer>

            </Submenu>

            <RootClickableTextContainer active={personalInfoVisible} onClick={() => setPersonalInfoVisible(!personalInfoVisible)}>
                <Text type="medium">Personal Information</Text>
                <ArrowDownIcon />
            </RootClickableTextContainer>
            <Submenu open={personalInfoVisible}>

                <ClickableTextContainer
                    active={location.pathname.indexOf('/settings/personal_information/contact_relationship') !== -1}
                    onClick={() => history.push('/settings/personal_information/contact_relationship')}
                >
                    <Text type="medium">Emergency Contact Relationships</Text>
                </ClickableTextContainer>

                <ClickableTextContainer
                    active={location.pathname.indexOf('/settings/personal_information/education_degree') !== -1}
                    onClick={() => history.push('/settings/personal_information/education_degree')}
                >
                    <Text type="medium">Education Degrees</Text>
                </ClickableTextContainer>

                <ClickableTextContainer
                    active={location.pathname.indexOf('/settings/personal_information/driver_classification') !== -1}
                    onClick={() => history.push('/settings/personal_information/driver_classification')}
                >
                    <Text type="medium">Driver License Classifications</Text>
                </ClickableTextContainer>

                <ClickableTextContainer
                    active={location.pathname.indexOf('/settings/personal_information/visa') !== -1}
                    onClick={() => history.push('/settings/personal_information/visa')}
                >
                    <Text type="medium">Visas</Text>
                </ClickableTextContainer>

                <ClickableTextContainer
                    active={location.pathname.indexOf('/settings/personal_information/nationality') !== -1}
                    onClick={() => history.push('/settings/personal_information/nationality')}
                >
                    <Text type="medium">Nationalities</Text>
                </ClickableTextContainer>

                <ClickableTextContainer
                    active={location.pathname.indexOf('/settings/personal_information/language') !== -1}
                    onClick={() => history.push('/settings/personal_information/language')}
                >
                    <Text type="medium">Languages</Text>
                </ClickableTextContainer>

                <ClickableTextContainer
                    active={location.pathname.indexOf('/settings/personal_information/shirt_size') !== -1}
                    onClick={() => history.push('/settings/personal_information/shirt_size')}
                >
                    <Text type="medium">Shirt Sizes</Text>
                </ClickableTextContainer>

                <ClickableTextContainer
                    active={location.pathname.indexOf('/settings/personal_information/currency') !== -1}
                    onClick={() => history.push('/settings/personal_information/currency')}
                >
                    <Text type="medium">Currencies</Text>
                </ClickableTextContainer>
            </Submenu>
            {/*<RootClickableTextContainer*/}
            {/*    active={location.pathname.indexOf('/settings/timeoff_types') !== -1}*/}
            {/*    onClick={() => history.push('/settings/timeoff_types')}*/}
            {/*>*/}
            {/*    <Text type="medium">Time Off Types</Text>*/}
            {/*</RootClickableTextContainer>*/}
        </SettingMenu >
    )
}

export default Menu;


const SettingMenu = styled.div`
    width: 19%;
    float: left;
    padding: 23px 30px 0px 30px;
    background-color: #fff;
    height: 100%;
    overflow-y: auto;
    border-radius: 6px;
`

const TextContainer = styled.div<{ largeSpacing?: boolean }>`
    padding-top: ${(props) => props.largeSpacing ? '30px' : '10px'};
    height: auto;
`

const ClickableTextContainer = styled(TextContainer) <{ active?: boolean }>`
    cursor: pointer;
    padding-top: 10px;

    &:hover {
        background-color: #DCEEE5;
    }

    &:hover span {
        color: #339966;
    }

    & span {
        padding-block: 7px;
        padding-top: 0px;
        color: ${(props) => { return props.active ? "#339966" : '#424E55' }};;
    }
`

const ClickableTextContainerMain = styled(ClickableTextContainer) <{ active?: boolean }>`
    &:hover {
        background-color: transparent;
    }
`

const RootClickableTextContainer = styled(TextContainer) <{ active?: boolean }>`
    cursor: pointer;
    padding-top: 10px;

    &:hover span {
        color: #339966;
    }
    
    &:hover path {
        fill: #339966;
    }

    svg{
        margin-left: 5px;
        width: 10px;
        height: 10px;
        transform: ${(props) => { return props.active ? 'rotate(180deg)' : 'rotate(0deg)' }};
        
        path {
            fill: ${(props) => { return props.active ? "#339966" : '#424E55' }};
        }
    }

    & span {
        padding-block: 7px;
        padding-top: 0px;
        color: ${(props) => { return props.active ? "#339966" : '#424E55' }};
    }
`

const Divider = styled.div`
    border-top: 1px solid #F1F1F1;
    margin-top: 25px;
    margin-bottom: 14px;
`

const Submenu = styled.div<{ open: boolean }>`
    max-height: ${props => props.open ? '100%' : "0"};
    overflow: hidden;
    transition-property: all;
    transition-duration: 1s;
    transition-timing-function: ease-out;
    
    backface-visibility: hidden;

    & div span {
        padding-left: 12px;
    }
`;