import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  :root {
    --white: #fff;
    --black: #000;
    --meddium-green: #C3E1D2;
    --light-green: #EAF5EB;
    --success-green: #77B448;
    --green: #339966;
    --red: #C54343;
    --dark-green: #1F6B45;
    --dark-gray: #676767;
    --gray: #F8F8F8;
    --footer-dark: #00101A;
    --orange: #FF9933;
    --pink: #FDEDEE;
    --light-red: #F46A6A;
    --light-orange: #FCF2E4;
    --header-dark:  #172B37;
    --facebook: #2867b2;
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    vertical-align: baseline;
    font: inherit;
  }
  
  .grecaptcha-badge { visibility: hidden; }

  /*** main styles **/
  html,
  body {
    height: 100%;
    font-size: 14px; 
    font-family: 'Aspira Regular', Helvetica, sans-serif;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
  img {
    max-width: 100%;
    display: inline-block;
  }
  ul {
    list-style: none;
  }
  input,
  button{
    outline: none;
  }
  h4 {
    font-family: 'Aspira Regular', Helvetica, sans-serif;
  }

  .w-100{
    width: 100%;
  }

  .h-100{
    height: 100%;
  }

  .rotated{
    transform: rotate(180deg);
  }

  .text-center{
    text-align: center
  }

  .btn{
    display: block;
    border-radius: 4px;
    padding: 10px;
    border: none;
    outline: none !important;
    transition: background 0.25s;
    cursor: pointer;
  }

  .btn-green{
    background: var(--green);
    color: #fff;

    &:hover{
      background: var(--dark-green);
    }
  }

  textarea {
    font-family: 'Aspira Regular', Helvetica, Helvetica, sans-serif;
  }

  /** Flex Classes **/
  .d-flex{
    display: flex !important;
  }

  .justify-content-center{
    justify-content: center !important;
  }

  .align-items-center{
    align-items: center !important;
  }

  .error-overlay-list{
    list-style-type: circle;
    padding-left: 20px;
  }

  .loading-indicator{
    min-height: 30px;
    margin-top: -3px;
    circle{
      stroke: var(--green)
    }
  }
`;

export default GlobalStyles;