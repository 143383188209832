import styled from "styled-components";
import { Controller, useFormContext } from "react-hook-form";
import { StepTitle, StepDesc } from "../../StepsContent/Welcome";
import Divider from '@mui/material/Divider';
import useQueryCustom from "hooks/useQueryCustom";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioButton from "components/RadioButton";

type TDocLists = {
    class_a: IClass[],
    class_b: IClass[],
    class_c: IClass[],
    underage: IClass[],
};

interface IClass {
    id: number,
    id_name: string,
    name: string,
    description: string,
    i9_class: string,
    selected: boolean
};

export default function EligibilityDocuments({ employeeData }: any) {
    const { control, setValue } = useFormContext();

    const { data: docLists } = useQueryCustom<string[], {}, TDocLists>(["get_document_lists"], {
        endpoint: `onboarding/admin/documents?employee_id=${employeeData.id}`,
        options: { method: "get" },
        onSuccess: (data) => {
            data.class_a.forEach(e => {
                if (e.selected) {
                    setValue('class_a', e.id);
                }
            });
            data.class_b.forEach(e => {
                if (e.selected) {
                    setValue('class_b', e.id);
                }
            });
            data.class_c.forEach(e => {
                if (e.selected) {
                    setValue('class_c', e.id);
                }
            });
            data.underage.forEach(e => {
                if (e.selected) {
                    setValue('class_b', e.id);
                }
            });
        }
    }, { refetchOnWindowFocus: false });

    return (
        <ContentContainer>
            <StepTitle>Examine Documents</StepTitle>
            <StepDesc>
                To compete {employeeData?.first_name}'s Form I-9, please physically examine the employee's identification and work authorization documents.
                <br /> To be federally compliant, this should happen within the employee's first 3 days at work.
            </StepDesc>
            <StepSubTitleContainer>
                <p>What documents did the employee bring?</p>
                <p>You have to select one selection from List A or a combination of one selection from List B and one selection from List C</p>
            </StepSubTitleContainer>
            <ListsContainer>
                <SingleListContainer>
                    <ListTitleContainer>
                        <p>List A</p>
                        <p>Documents that Establish Both Identity <br /> and Employment Authorization</p>
                    </ListTitleContainer>
                    <Controller
                        control={control}
                        name="class_a"
                        render={({ field }) => (
                            <RadioGroup {...field} onChange={(_, value) => {
                                field.onChange(value);
                                setValue('class_b', '');
                                setValue('class_c', '');
                            }}>
                                {docLists?.class_a.map(item => (
                                    <ListItemContainer key={item.id}>
                                        <StyledFormControlLabel
                                            value={item.id}
                                            control={<RadioButton />}
                                            label={item.description}
                                        />
                                    </ListItemContainer>
                                ))}
                            </RadioGroup>
                        )}
                    />
                </SingleListContainer>
                <Divider orientation="vertical" flexItem sx={{ marginInline: 3 }} />
                <SingleListContainer>
                    <ListTitleContainer>
                        <p>List B</p>
                        <p>Documents that Establish <br /> Identity</p>
                    </ListTitleContainer>
                    <Controller
                        control={control}
                        name="class_b"
                        render={({ field }) => (
                            <RadioGroup {...field} onChange={(_, value) => {
                                field.onChange(value);
                                setValue('class_a', '');
                            }}>
                                {docLists?.class_b.map(item => (
                                    <ListItemContainer key={item.id}>
                                        <StyledFormControlLabel
                                            value={item.id}
                                            control={<RadioButton />}
                                            label={item.description}
                                        />
                                    </ListItemContainer>
                                ))}
                                <ListTitleContainer style={{ width: '100%', marginTop: 30 }}>
                                    <p>For persons under age 18 who are <br />unable to present a document listed above:</p>
                                </ListTitleContainer>
                                {docLists?.underage.map(item => (
                                    <ListItemContainer key={item.id}>
                                        <StyledFormControlLabel
                                            value={item.id}
                                            control={<RadioButton />}
                                            label={item.description}
                                        />
                                    </ListItemContainer>
                                ))}
                            </RadioGroup>
                        )}
                    />
                </SingleListContainer>
                <SingleListContainer>
                    <ListTitleContainer>
                        <p>List C</p>
                        <p>Documents that Establish <br /> Employment Authorization</p>
                    </ListTitleContainer>
                    <Controller
                        control={control}
                        name="class_c"
                        render={({ field }) => (
                            <RadioGroup {...field} onChange={(_, value) => {
                                field.onChange(value);
                                setValue('class_a', '');
                            }}>
                                {docLists?.class_c.map(item => (
                                    <ListItemContainer key={item.id}>
                                        <StyledFormControlLabel
                                            value={item.id}
                                            control={<RadioButton />}
                                            label={item.description}
                                        />
                                    </ListItemContainer>
                                ))}
                            </RadioGroup>
                        )}
                    />
                </SingleListContainer>
            </ListsContainer>
        </ContentContainer>
    )
};

const ContentContainer = styled.div`
    overflow-y: auto;
    flex: 1;
    padding-top: 60px;
`;

const StepSubTitleContainer = styled.div`
    padding-block: 50px;
    & > p:first-of-type {
        font-size: 16px;
        color: #000;
        font-family: 'Aspira Wide Demi';
        text-align: center;
    };
    & > p:last-of-type {
        font-size: 14px;
        color: #676767;
        text-align: center;
        margin-top: 10px;
    };
`;

const ListsContainer = styled.div`
    display: flex;
    flex: 1;
    margin-right: 60px;
    margin-bottom: 20px;
`;

const SingleListContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-inline: 5px;
    flex: 0 1 33.3%;
`;

const ListTitleContainer = styled.div`
    & > p:first-of-type {
        font-size: 16px;
        color: #339966;
        font-family: 'Aspira Wide Demi';
        text-align: center;
    };
    & > p:last-of-type {
        font-size: 14px;
        font-family: 'Aspira Demi';
        color: #000;
        text-align: center;
        margin-top: 10px;
    };
`;

const ListItemContainer = styled.div`
    padding-block: 5px;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
    align-items: flex-start;
    white-space: pre-wrap;
    .MuiFormControlLabel-label { 
        margin-top: 10px 
    }
`;