import styled from "styled-components";
import { useForm } from 'react-hook-form';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button'
import DialogModal from "components/Modal/Dialog";
import EmpEditHeader from "../../editHeader";
import PermissionGate from "permissions/PermissionGate";

import { ReactComponent as EditIcon } from 'assets/svg/pen-circle.svg';
import { ReactComponent as TrashCanIcon } from 'assets/svg/trash-can-circle.svg';
import { ReactComponent as MessageIcon } from 'assets/svg/message.svg';
import { ReactComponent as MagnifierIcon } from 'assets/svg/magnifier_circle.svg';
import { ReactComponent as PlusIcon } from 'assets/svg/plus.svg';

const Wrapper = styled.div`    
    .section-body{
      margin-bottom: 20px;
      min-width: 1000px;
      border-bottom: 1px solid #F8F8F8;
      .table-header {
        padding: 0 20px;
        margin-left: 17px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        font-family: 'Aspira Demi';
        div{
          width:  20%;
          font-size: 12px;
        }
      }
    }
`;

const HistoryItemContainer = styled.div<{ isPastStatus: boolean }>`
  margin: 0;
  padding: 0px 20px;
  border-top: 1px solid #F8F8F8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  &:hover{
      background: #EEEEEE;
      .actions{
        visibility: visible;
      }
  }

  div {
      width: 100%;
      font-size: 11px;
      padding-top: 10px;
      padding-bottom: 10px;
      color: ${({ isPastStatus }) => isPastStatus ? '#8E8E8E' : '#414141'};
  }

  .actions {
    display: flex;
    visibility: hidden;
    align-items: center;
    justify-content: flex-end;
  }
`;

const HistoryItem = ({ data, onDeleteClick, onEditClick, disabled }: any) => {
  return (
    <HistoryItemContainer isPastStatus={data.relevance === 'past'}>
      <div style={{ width: 130 }}>
        <StyledDot status={data.relevance} />
      </div>
      <div>{data.effective_date ?? '-'}</div>
      <div>{data.currency && data.currency.symbol} {(+data.pay_amount).toFixed(2)} / {data.payment_period.name}</div>
      <div>{data.payment_type?.name ?? '-'}</div>
      <div>{data.payment_schedule?.name ?? '-'}</div>
      <div>{data.overtime_status?.name ?? '-'}</div>
      <div>{data.compensation_change_reason?.name ?? '-'}</div>
      <div>
        {data.comment ?
          <span>
            <Tooltip title={data.comment} enterDelay={200}>
              <StyledMessageIcon />
            </Tooltip>
          </span> : '-'}
      </div>
      <div className='d-flex align-items-center'>
        <div className='actions'>
          <PermissionGate action="edit" on="compensation" fallback={<StyledMagnifierIcon onClick={() => onEditClick(data)} />}>
            {disabled ? <StyledMagnifierIcon onClick={() => onEditClick(data)} /> : <><StyledEditIcon onClick={() => onEditClick(data)} />
              <StyledTrashIcon onClick={() => onDeleteClick(data.id)} /></>}
          </PermissionGate>
        </div>
      </div>
    </HistoryItemContainer>
  )
}

const CompensationHistory = (props: any) => {
  const { handleSubmit } = useForm();
  const { user, jobData, compensationList, disabled } = props;

  const onSubmit = (data: any) => {
    props.onSubmit(data);
  };

  return (
    <DialogModal
      open={props.isOpen}
      onClose={() => props.onModalClose()}
      title={"Compensation History"}
      nominalHeader={
        <EmpEditHeader
          employeeName={`${user.first_name} ${user.last_name}`}
          avatarUuid={user.uuid}
          employeeId={user.id}
          jobData={jobData}
          rightSide={
            <PermissionGate on='compensation' action="edit">
              {disabled ? null : <Button
                type="button"
                size='large'
                variant='contained'
                onClick={props.onUpdateClick}
                startIcon={<StyledPlusIcon />}
              >
                Update Compensation
              </Button>}
            </PermissionGate>
          } />}
      maxWidth='xl'
    >
      <Wrapper>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='section-body'>
            {compensationList.length ? <div><div className='table-header'>
              <div>Effective Date</div>
              <div>Pay Rate</div>
              <div>Pay Type</div>
              <div>Pay Schedule</div>
              <div>Overtime Status</div>
              <div>Change Reason</div>
              <div>Comment</div>
              <div></div>
            </div>
              <div style={{ height: 420 }}>
                {compensationList?.map((item: any) => (
                  <HistoryItem
                    key={item.id}
                    data={item}
                    onDeleteClick={(id: any) => props.onDeleteClick(id)}
                    onEditClick={(item: any) => props.onEditClick(item)}
                    disabled={disabled}
                  />
                ))}
              </div>
            </div> : <NoJobEntries>No Job Information entries have been added.</NoJobEntries>}
          </div>
        </form>
      </Wrapper>
    </DialogModal>
  );
};

export default CompensationHistory;

const StyledMessageIcon = styled(MessageIcon)`
  &:hover {
    & > path {
      fill: #396;
    }
  }
`;

const StyledEditIcon = styled(EditIcon)`
  cursor: pointer;
  margin-right: 5px;
`;

const StyledTrashIcon = styled(TrashCanIcon)`
  cursor: pointer;
  margin-right: 0;
`;

const StyledMagnifierIcon = styled(MagnifierIcon)`
  cursor: pointer;
`;

const StyledDot = styled.span<{ status: string }>`
  display: flex;
  align-self: center;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: ${({ status }) => {
    const color = status === 'past' ? 'var(--dark-gray)' : status === 'future' ? 'var(--orange)' : 'var(--green)';
    return color
  }};
`;

const NoJobEntries = styled.p`
    min-height: 180px;
    color: #8E8E8E;
`;

const StyledPlusIcon = styled(PlusIcon)`
    margin-right: 5px;
    path {
        fill: #FFF;
    }
`;
