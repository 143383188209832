import styled from "styled-components"
import { useSelector } from "react-redux";
import { currentUserSelector } from "redux/selectors";
import { formatInTimeZone } from 'date-fns-tz'

import { ReactComponent as PersonCircle } from 'assets/svg/info_circle/person-circle.svg';
import { ReactComponent as SignatureCircle } from 'assets/svg/info_circle/pencil-circle.svg';
import { ReactComponent as DocCircle } from 'assets/svg/info_circle/doc_upload-circle.svg';
import { ReactComponent as CalendarCircle } from 'assets/svg/info_circle/calendar-circle.svg';
import { ReactComponent as LocationCircle } from 'assets/svg/info_circle/location-circle.svg';

interface IWelcomeStep {
    fillI9: boolean,
    firstDay: {
        date?: string,
        location?: string
    } | null
};

export default function WelcomeStep({ fillI9, firstDay }: IWelcomeStep) {
    const { company, employee } = useSelector(currentUserSelector);

    return (
        <ContentContainer>
            <StepTitle>WELCOME TO {company?.name}!</StepTitle>
            <StepDesc>Hi, {employee?.first_name}! Let’s set up your account. This process usually takes about 10 minutes.</StepDesc>
            <OnboardingProcessesContainer>
                <h1>During your self-onboarding process we’ll help you:</h1>
                <div><PersonCircle />Fill your Personal Details</div>
                <div><DocCircle /> Upload your own Documents</div>
                {fillI9 && <div><SignatureCircle />Setup your Employment Eligibility and  Sign Form I-9</div>}
                {firstDay ? <><h1 style={{ marginTop: 30 }}>Your First Day:</h1>
                    {firstDay.date && <div><CalendarCircle />{formatInTimeZone(new Date(firstDay.date), 'UTC', 'PPPP')}</div>}
                    {firstDay.location && <div><LocationCircle /> {firstDay.location}</div>}</> : null}
            </OnboardingProcessesContainer>
        </ContentContainer>
    )
};

const ContentContainer = styled.div`
    flex: 1;
    padding-top: 60px;
`;

const OnboardingProcessesContainer = styled.div`
    margin-top: 55px;
    display: flex;
    flex-direction: column;
    & > h1 {
        font-family: 'Aspira Wide Demi';
        font-size: 16px;
        color: #676767;
        margin-bottom: 15px;
    }
    & > div {
        display: flex;
        align-items: center;
        margin-bottom: 9px;
        font-size: 14px;
        color: #676767;
        & > svg {
            margin-right: 10px;
        }
    }
`;

export const StepTitle = styled.div`
    font-family: 'Aspira Demi';
    text-transform: uppercase;
    color: #414141;
    font-size: 16px;
    margin-bottom: 10px;
`;

export const StepDesc = styled.div`
    display: flex;
    justify-content: space-between;
    color: #424E55;
    font-size: 14px;
    margin-right: 60px;
    & > span {
        font-size: 14px;
    }
`;