import { useEffect, useState } from "react";
import styled from "styled-components";

import LeftCard from './LeftCard';
import RightCard from "./RightCard";

import { getEmployee, getCurrentUser } from "services";
import { useDispatch, useSelector } from "react-redux";
import { currentUserSelector } from "redux/selectors";
import CircularProgress from '@mui/material/CircularProgress';
import { setCurrentUser } from "redux/authSlice";


const Wrapper = styled.div`
  padding: 2rem 2rem 2rem;
  display: flex;
  flex-wrap: wrap;
  background-color: #F2F2F4;
`;

const Employee = (props: any) => {
  const [person, setPerson] = useState<any>(null);
  const dispatch = useDispatch();
  const currentUser = useSelector(currentUserSelector)

  useEffect(() => {
    getEmployee(props.match.params.id ?? currentUser?.employee.id).then(res => setPerson(res.data)).catch((err) => console.log(err.response))
  }, [props.match.params.id, currentUser?.employee.id])

  const getEmployeeInfo = () => {
    getEmployee(props.match.params.id ?? currentUser?.employee.id).then(res => setPerson(res.data)).catch((err) => console.log(err.response))
    if (currentUser?.employee.id && !props.match.params.id && localStorage.getItem('token')) {
      getCurrentUser().then(res => {
        dispatch(setCurrentUser(res.data));
      })
    }
  };

  let isDisabled = person?.onboarding?.onboarding_status?.id_name === 'in_progress';

  if (!person) {
    return (
      <Wrapper style={{ justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress />
      </Wrapper>
    );
  };

  return (
    <Wrapper>
      <LeftCard person={person} refreshEmployeeInfo={getEmployeeInfo} disabled={isDisabled} />
      <RightCard person={person} refreshEmployeeInfo={getEmployeeInfo} disabled={isDisabled} />
    </Wrapper>
  );
};

export default Employee;
