import { useEffect, useState } from "react";
import styled from "styled-components";
import { useForm } from 'react-hook-form';
import Tooltip from '@mui/material/Tooltip';
import Button from "@mui/material/Button";
import EmployeeCard from "components/Employee/Card";
import EmpEditHeader from "../../editHeader";
import DialogModal from "components/Modal/Dialog";
import { getJobEmployeeDetailList } from "services";
import { isEmpty } from "lodash";
import JobInfoHistoryDelete from "./delete";
import PermissionGate from "permissions/PermissionGate";

import { ReactComponent as EditIcon } from 'assets/svg/pen-circle.svg';
import { ReactComponent as TrashCanIcon } from 'assets/svg/trash-can-circle.svg';
import { ReactComponent as MessageIcon } from 'assets/svg/message.svg';
import { ReactComponent as MagnifierIcon } from 'assets/svg/magnifier_circle.svg';
import { ReactComponent as PlusIcon } from 'assets/svg/plus.svg';

const Wrapper = styled.div`
  .section-body{
    margin-bottom: 20px;
    min-width: 1100px;
    border-bottom: 1px solid #F8F8F8;
    
    .table-header{
      margin: 0px 20px;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      color: #172B37;
      font-size: 12px;
      font-family: 'Aspira Demi';
      
      div{
        width: 20%;
      }
    }
  }
`;

const HistoryWrapper = styled.div`
  margin: 0;
  padding: 0px 20px;
  border-top: 1px solid #F8F8F8;
  display: flex;
  align-items: center;
  font-size: 11px;

  .dot{
    width: 8px;
    height: 8px;
    margin-right: 5px;
    border-radius: 50%;
    display: inline-block;
  }

  &.past{
    opacity: 0.7;
    
    .dot{
      background: var(--dark-gray);
    }
  }

  &.future{

    .dot{
      background: var(--orange);
    }
  }

  &.current{

    .dot{
      background: var(--green);
    }
  }
  
  :hover{
      background: #EEEEEE;

      .actions{
    
        .inner{
          visibility: visible;
        }
      }
  }

  .user-info{
    justify-content: flex-start;
    border-bottom: 0 !important;
    font-size: 13px;
    width: 150px;
    margin: 10px 0;

    .name{
      font-size: 11px;
    }

    .noPhoto{
      line-height: 29px;
      font-size: 10px !important;
      margin-right: -8px;
    }
    
    div{
      width: auto;
    }
  }

  .actions{
    display: flex;
  
    align-items: center;
    justify-content: center;
    margin-left: 40px;

    .inner{
      visibility: hidden;
      display: flex;
      margin-left: 65px;
  
      align-items: center;
      justify-content: center;
    }


    .circle{
      cursor: pointer;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      margin-left: 5px;
      flex-shrink: 0;
      background: var(--gray);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

const NoJobEntries = styled.p`
    padding: 0 20px;
    min-height: 180px;
    color: #8E8E8E;
    font-size: 13px;
`;

const HistoryItem = ({ data, onDeleteClick, onEditClick, disabled }: any) => {
  return <HistoryWrapper style={{ padding: !data.manager ? '10px 20px' : '0 20px' }} className={data.relevance}>
    <div style={{ width: 130 }}>
      {<span className='dot'></span>} {data.effective_date}
    </div>
    <div style={{ color: data.employment_status && data.employment_status.name === 'Terminated' ? 'var(--red)' : '', width: 105 }}>
      {data.employment_status ? data.employment_status.name : '-'}
    </div>
    <div style={{ width: 130 }} >
      {data.job_title ? data.job_title.name : '-'}
    </div>
    <div style={{ width: 115, paddingRight: 20 }}>
      {data.department ? data.department.name : '-'}
    </div>
    <div style={{ width: 90, paddingRight: 15 }}>
      {data.division ? data.division.name : '-'}
    </div>
    <div style={{ width: 100, paddingRight: 15 }}>
      {data.location ? data.location.name : '-'}
    </div>

    <div style={{ width: 145 }}>
      {data.manager ? <EmployeeCard employee={data.manager} /> : '-'}
    </div>
    <div className='d-flex align-items-center' >
      <div style={{ width: 85 }}>
        {data.job_change_reason ? data.job_change_reason.name : '-'}
      </div>
      <div className='actions'>

        <div style={{ width: 13.47 }}>
          {!isEmpty(data.comment) ?
            <Tooltip title={data.comment} enterDelay={200}>
              <div>
                <StyledMessageIcon />
              </div>
            </Tooltip>
            : '-'}
        </div>
        <div className='inner'>
          <PermissionGate action="edit" on="compensation" fallback={<StyledMagnifierIcon onClick={() => onEditClick(data)} />}>
            {disabled ? <StyledMagnifierIcon onClick={() => onEditClick(data)} /> : <><StyledEditIcon onClick={() => onEditClick(data)} />
              <StyledTrashIcon onClick={() => onDeleteClick(data.id)} /></>}
          </PermissionGate>
        </div>
      </div>
    </div>
  </HistoryWrapper>
};

const JobHistory = (props: any) => {
  const { handleSubmit } = useForm();
  const { person, jobData, disabled } = props;
  const [jobList, setJobList] = useState([]);
  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
  const [chosenItemId, setChosenItemId] = useState<any>(null);

  const onSubmit = (data: any) => {
    props.onSubmit(data);
  };

  useEffect(() => {
    getJobEmployeeDetailList(25, 1, person.id).then(res => setJobList(res.data.list))
  }, [person]);

  const DeleteSubmit = () => {
    props.handleDeleteSubmit(chosenItemId)
    setIsDeleteOpen(false)
  };

  const DeleteClick = (id: number) => {
    setChosenItemId(id)
    setIsDeleteOpen(true);
  };

  return (
    <DialogModal
      open={props.isOpen}
      title={'Job Information History'}
      onClose={() => props.onModalClose()}
      nominalHeader={
        <EmpEditHeader
          employeeName={`${person.first_name} ${person.last_name}`}
          avatarUuid={person.uuid}
          employeeId={person.id}
          jobData={jobData}
          rightSide={
            <PermissionGate on='job' action="edit">
              {disabled ? null : <Button
                type="button"
                size='large'
                variant='contained'
                onClick={props.onUpdateClick}
                startIcon={<StyledPlusIcon />}
              >
                Update Job Information
              </Button>}
            </PermissionGate>
          } />}
      maxWidth='xl'
    >
      <Wrapper>
        <JobInfoHistoryDelete
          isOpen={isDeleteOpen}
          onModalClose={() => setIsDeleteOpen(false)}
          onDelete={DeleteSubmit}
          loadingRequest={props.loadingRequest}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='section-body'>
            {jobList.length ? <div><div className='table-header'>
              <div style={{ marginLeft: 15, width: 115 }}>
                Effective Date
              </div>
              <div style={{ width: 105 }}>
                Emp. Status
              </div>
              <div style={{ width: 130 }}>
                Job Title
              </div>
              <div style={{ width: 115 }}>
                Department
              </div>
              <div style={{ width: 90 }}>
                Division
              </div>
              <div style={{ width: 105 }}>
                Location
              </div>
              <div style={{ width: 145 }}>
                Manager
              </div>
              <div style={{ width: 125 }}>
                Reason
              </div>
              <div style={{ width: 130 }}>
                Comment
              </div>
            </div>
              <div style={{ height: 280 }}>
                {jobList && jobList.map((item: any, index: number) =>
                  <HistoryItem key={item.id} data={item}
                    onDeleteClick={(id: any) => DeleteClick(id)}
                    onEditClick={(item: any) => props.onEditClick(item)}
                    disabled={disabled}
                  />)}
              </div>
            </div> : <NoJobEntries>No Job Information entries have been added.</NoJobEntries>}
          </div>
        </form>
      </Wrapper>
    </DialogModal>
  );
};

export default JobHistory;

const StyledEditIcon = styled(EditIcon)`
  cursor: pointer;
  margin-right: 5px;
`;

const StyledTrashIcon = styled(TrashCanIcon)`
  cursor: pointer;
  margin-right: 0;
`;

const StyledMagnifierIcon = styled(MagnifierIcon)`
  cursor: pointer;
`;


const StyledMessageIcon = styled(MessageIcon)`
  &:hover {
    & > path {
      fill: #396;
    }
  }
`;

const StyledPlusIcon = styled(PlusIcon)`
    margin-right: 5px;
    path {
        fill: #FFF;
    }
`;
