import styled from "styled-components";
import { ListItem } from "../personal/section";
import { ReactComponent as EditIcon } from 'assets/svg/pen-circle.svg';
import { ReactComponent as TrashCanIcon } from 'assets/svg/trash-can-circle.svg';

const Wrapper = styled.div`
  :hover{
    .top{
      .actions{
        visibility: visible;
      }
    }
  }

 .top{
   display: flex;
    justify-content: space-between;
    align-items: center;

    .actions{
      display: flex;
      align-items: center;
      visibility: hidden;
      gap: 20px;
      & > div {
        display: flex;
        align-items: center;
        & > span {
          cursor: pointer;
        }
      }
    }
  }

 .body{
   padding: 0px 0px 40px !important;
    
   .list-item{
     font-size: 13px;
     
     .title{
       display: inline-block;
       width: 170px;
       text-align: right;
     }
   }

   h5{
     margin-top: 10px;
     margin-bottom: 10px;
     color: #172B37;
     font-weight: bold;
     font-size: 14px;
     font-family: 'Aspira Demi';
   }
 }
`;

const Relation = styled.div`
    font-size: 15px;
    font-weight: bold;
    color: #172B37;
    font-family: 'Aspira Demi';
    display: flex;
    align-items: center;
    .primary {
        font-weight: 100;
        color: #5DAE85;
        padding: 5px 10px 3px;
        border-radius: 4px;
        margin-left: 15px;
        letter-spacing: 0.7px;
        font-size: 13px;
        
        span {
            width: 9px;
            height: 9px;
            background: #7FBF9E;
            border-radius: 50%;
            display: inline-block;
            margin-right: 5px;
            font-family: 'Aspira Regular';
        }
    }
`

const EmergencyItem = (props: any) => {
  const { item, disabled } = props;

  return (
    <Wrapper>
      <div className='top'>
        <Relation>
          <h4>{item.name} - {item.contact_relationship && item.contact_relationship.name}</h4>
          {item.primary && <span className='primary'><span></span>Primary Contact</span>}
        </Relation>
        {disabled ? null : <div className='actions'>
          <div onClick={() => props.onEditClick(item)}>
            <StyledEditIcon width={23} height={23} /><span>Edit</span>
          </div>
          <div onClick={() => props.onDeleteClick(item)}>
            <StyledTrashIcon width={23} height={23} /><span>Delete</span>
          </div>
        </div>}
      </div>

      <div className='body'>
        <h5>Contact Information</h5>
        <div style={{ marginBottom: 30 }}>
          <ListItem title='Work Phone' value={item.work_phone +
            (item.work_phone_ext ? ` Ext. ${item.work_phone_ext}` : '')} />
          <ListItem title='Mobile Phone' value={item.mobile_phone} />
          <ListItem title='Home Phone' value={item.home_phone} />
          <ListItem title='Email' value={item.email} />
        </div>

        <h5>Address</h5>
        <div>
          <ListItem title='Country' value={item.country && item.country.name} />
          <ListItem title='Address Line 1' value={item.address} />
          <ListItem title='Address Line 2' value={item.address_details} />
          <ListItem title='City' value={item.city} />
          {item.country && item.country.iso === 'US' ?
            <ListItem title='State / Province / Region'
              value={item.state && item.state.name} /> :
            <ListItem title='State / Province / Region' value={item.region} />}
          <ListItem title='Zip / Postal Code' value={item.postal_code} />
        </div>
      </div>

    </Wrapper>
  );
};

export default EmergencyItem;

const StyledEditIcon = styled(EditIcon)`
  cursor: pointer;
  margin-right: 5px;
  & circle {
    fill: #B5B5B5;
  }
  & path {
    fill: #FFF;
  }
`;

const StyledTrashIcon = styled(TrashCanIcon)`
  cursor: pointer;
  margin-right: 5px;
  & circle {
    fill: #B5B5B5;
  }
  & path {
    fill: #FFF;
  }
`;

