import { useState } from 'react';
import styled from "styled-components";
import {
    GridColDef,
    GridExceljsProcessInput,
    GridInitialState,
    GridLinkOperator,
    GridRenderCellParams,
    GridState
} from "@mui/x-data-grid-premium";
import DataGrid from "components/DataLists/DataGrid";
import { PageHeaderTitle } from "components/DesignUIComponents";
import EmployeeCard from "components/Employee/Card";
import useQuery from "hooks/useQueryCustom";
import { isEmpty, uniqBy } from "lodash";
import format from "date-fns/format";
import { formatInTimeZone } from 'date-fns-tz'
import { useSelector } from "react-redux";
import { currentUserSelector } from "redux/selectors";
import PermissionGate from 'permissions/PermissionGate';

const renderAvatarCell = (params: GridRenderCellParams, type: string) => {
    return params.row[type]?.first_name ?
        <PermissionGate on='employee' properties={{ disabled: true }}>
            <EmployeeCard fontSize={14} employee={params.row[type]} />
        </PermissionGate> : ""
};

const columns: GridColDef[] = [
    {
        field: 'employee', headerName: 'Employee',
        renderCell: (params) => renderAvatarCell(params, 'employee'),
        valueGetter: ({ value }) => `${value.first_name} ${value.last_name}`,
        minWidth: 150,
        flex: 1
    },
    { field: 'employee_id', headerName: 'Emp. #', minWidth: 100, flex: 0.6 },
    {
        field: 'status', headerName: 'Status',
        type: 'singleSelect',
        valueOptions: ['Hiring', 'Active', 'Terminated'],
        minWidth: 100, flex: 0.6
    },
    {
        field: 'effective_date',
        headerName: 'Date',
        type: 'date',
        valueGetter: ({ value }) => value && new Date(value),
        valueFormatter: ({ value }) => value && formatInTimeZone(new Date(value), 'UTC', 'P'),
        minWidth: 100,
        flex: 0.6
    },
    { field: 'pay_rate', headerName: 'Pay Rate', minWidth: 100, flex: 1 },
    { field: 'currency', headerName: 'Pay Rate (Currency)', minWidth: 100, flex: 1 },
    {
        field: 'pay_amount',
        headerName: 'Pay Rate (Amount)',
        valueGetter: ({ value }) => Number(value).toFixed(2),
        type: 'number',
        minWidth: 100, flex: 1
    },
    { field: 'payment_period', headerName: 'Pay Rate (Period)', minWidth: 100, flex: 1 },
    { field: 'payment_type', headerName: 'Pay Type', minWidth: 100, flex: 1 },
    { field: 'payment_schedule', headerName: 'Pay Schedule', minWidth: 100, flex: 1 },
    { field: 'overtime', headerName: 'Overtime', minWidth: 100, flex: 1 },
    { field: 'reason', headerName: 'Reason', minWidth: 100, flex: 1 },
];

export default function CompensationReport() {
    const [numberOfEmployees, setNumberOfEmployees] = useState<number>(0);
    const { data: compensationHistoryData = [], isLoading } = useQuery<any>(["compensation_history_report"], {
        endpoint: 'reports/compensation_history',
        options: { method: "post" },
    }, { enabled: true });

    const currentUser = useSelector(currentUserSelector);

    const initialState: GridInitialState = {
        filter: {
            filterModel: {
                items: [{ id: 1, columnField: 'status', operatorValue: 'isAnyOf', value: ['Hiring', 'Active'] }],
                linkOperator: GridLinkOperator.And,
                quickFilterLogicOperator: GridLinkOperator.And,
            },
        },
        sorting: {
            sortModel: [
                { field: 'employee', sort: 'asc' },
                { field: 'effective_date', sort: 'desc' },
            ],
        },
        columns: {
            columnVisibilityModel: {
                currency: false,
                pay_amount: false,
                payment_period: false,
            }
        }
    };

    const exceljsPreProcess = ({ workbook, worksheet }: GridExceljsProcessInput): any => {
        workbook.creator = 'Profesto';
        workbook.created = new Date();
        worksheet.properties.defaultRowHeight = 30;

        worksheet.getCell('A1').value = 'Compensation History';
        worksheet.getCell('A1').font = {
            name: 'Arial Black',
            bold: true,
            size: 12,
        };
        worksheet.getCell('A1').alignment = {
            vertical: 'top',
            horizontal: 'left',
            wrapText: true,
        };
        worksheet.getCell('A2').value = currentUser.company.name;
        worksheet.getCell('A2').font = {
            name: 'Arial',
            size: 10,
        };
        worksheet.getCell('A3').value = format(new Date(), "MM.dd.yyyy HH:mm:ss");
        worksheet.getCell('A3').font = {
            name: 'Arial',
            size: 10,
        };
        worksheet.addRow({});
    };

    const exceljsPostProcess = ({ worksheet }: GridExceljsProcessInput): any => {
        worksheet.getRow(5).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'cccccc' }
        };
        worksheet.getRow(5).font = {
            size: 12,
            bold: true
        };
        worksheet.getRow(5).alignment = {
            vertical: 'middle',
            horizontal: 'center',
        };
        try {
            worksheet.getColumn('pay_amount').alignment = {
                horizontal: 'right',
            };
        } catch {
            return;
        }
    };

    const onGridStateChange = ({ filter: { filteredRowsLookup }, rows: { idRowsLookup } }: GridState) => {
        if (!isEmpty(filteredRowsLookup)) {
            const filteredRowsIds = Object.keys(filteredRowsLookup).filter(key => filteredRowsLookup[key]);
            let rows = Object.keys(idRowsLookup).map((key) => filteredRowsIds.includes(key) && idRowsLookup[key]).filter((row => row));
            let numberOfEmployees = uniqBy(rows, 'employee_id').length;
            setNumberOfEmployees(numberOfEmployees)
        } else {
            let numberOfEmployees = uniqBy(compensationHistoryData, 'employee_id').length;
            setNumberOfEmployees(numberOfEmployees)
        }
    };

    return (
        <>
            <PageHeaderTitle title={<HeaderTitle>Compensation History <span>({numberOfEmployees} Employees)</span></HeaderTitle>} />
            <div style={{ display: 'flex', height: '85%', margin: '20px 50px', flexDirection: 'column' }}>
                <DataGrid
                    name="compensation_report"
                    saveGridState
                    disableRowGrouping
                    onStateChange={onGridStateChange}
                    loading={isLoading}
                    rows={compensationHistoryData}
                    columns={columns}
                    initialState={initialState}
                    enableExports
                    excelOptions={{
                        exceljsPreProcess,
                        exceljsPostProcess,
                        fileName: `${format(new Date(), "yyyy-MM-dd'T'HH:mm")} - Profesto - Compensation-History`,
                    }}
                />
            </div>
        </>
    )
};

const HeaderTitle = styled.p`
  font-size: 18px;
  color: #00101A;
  & > span {
    color: #00101A;
    opacity: 0.5;
    font-size: 15px;
  }
`;
