import styled from "styled-components";
import DialogModal from "components/Modal/Dialog";

import { ReactComponent as TrashCanCircles } from 'assets/svg/trash-can_circles.svg';

const Wrapper = styled.div`
    .mode-body{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 40px;

        p{
            color: #676767;
            margin: 20px 0;
        } 
    }
`;

const EmergencyContactDelete = (props: any) => {
    return (
        <DialogModal
            open={props.isOpen}
            onClose={() => props.onModalClose()}
            title={'Delete Emergency Contact'}
            actionButton={() => props.onDelete()}
            withButtons
            cancelButtonText='CANCEL'
            actionButtonText='DELETE CONTACT'
            disableAutoFocus
            actionLoading={props.loadingRequest}
            fullWidth
            upperPosition
        >
            <Wrapper>
                <div className='mode-body'>
                    <TrashCanCircles />
                    <p>Are you sure you want to delete this Emergency Contact?</p>
                </div>
            </Wrapper>
        </DialogModal>
    );
};

export default EmergencyContactDelete;
