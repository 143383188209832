import { useEffect, useState } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import PassportInformationEdit from "./edit";
import PassportInformationDelete from './delete';
import Section, { StatusItem } from "../section";
import { getEmployeePassportList, createPassport, deleteEmployeePassport, updatePassport } from 'services';
import { checkDate } from "utils/common";
import { useToasts } from "react-toast-notifications";
import { isEmpty } from "lodash";

import { ReactComponent as EditIcon } from 'assets/svg/pen-circle.svg';
import { ReactComponent as TrashCanIcon } from 'assets/svg/trash-can-circle.svg';

const Wrapper = styled.div`
    .table{
        padding: 20px 0;
       
        .table-header{
            margin: 0px 10px;
            padding: 0 20px;
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
            color: #172B37;
            font-size: 14px;
            font-family: 'Aspira Demi';

            div {
                &:last-child {
                    width: 5%;
                }
                width: 28%;
            }
        }

        .table-row{
            margin: 0px 10px;
            padding: 13px 20px;
            display: flex;
            justify-content: space-between;
            color: #414141;
            border-top: 1px solid #F8F8F8;
            align-items: center;
            font-size: 13px;
            :hover{
                background: #EEEEEE;
                .actions{
                    visibility: visible;
                }
            }
            div {
                width: 25%;
                &:last-child {
                    width: 5%;
                }
            }

            .actions{
                display: flex;
                visibility: hidden;
                align-items: center;
                justify-content: flex-end;
            }
        }
    }
`;

const NoData = styled.div`
    padding: 20px 25px;
    font-size: 13px;
    color: #80888D;
`;

const PassportInformation = ({ person, match, onSave, disabled }: any) => {
    const [loadingRequest, setLoadingRequest] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
    const [passportList, setPassportList] = useState<any>([]);
    const [passportId, setPassportId] = useState(null);
    const [chosenPassport, setChosenPassport] = useState<any>(null);
    const [editMode, setEditMode] = useState<boolean>(false);
    const { addToast } = useToasts();
    const [editErrors, setEditErrors] = useState<any>([]);

    useEffect(() => {
        if (match.params.id || person.id) {
            getEmployeePassportList(25, 1, match.params.id ?? person.id).then(res => setPassportList(res.data))
        }

    }, [match.params.id, person.id])

    const handleEditSubmit = (data: any) => {
        if (!editMode) {
            setLoadingRequest(true);
            createPassport(data, person.id).then(res => {
                setLoadingRequest(false);
                getEmployeePassportList(50, 1, person.id).then(res => setPassportList(res.data))
                setIsOpen(false)
                addToast('Passport successfully added', {
                    appearance: 'success',
                    autoDismiss: true,
                })
                onSave && onSave();
            }).catch(err => {
                setLoadingRequest(false);
                addToast(<div><span style={{ fontWeight: 'bold' }}> There were some errors with your form.</span> <span style={{ marginTop: 6, display: 'inline-block' }}>Please Fix The Highlighted Fields Below.</span></div>, {
                    appearance: 'error',
                    autoDismiss: true,
                    placement: 'top-center'
                });
                setEditErrors(err.response.data.errors)
            })
        } else {
            setLoadingRequest(true);
            updatePassport(data, chosenPassport.id).then(res => {
                setLoadingRequest(false);
                getEmployeePassportList(50, 1, person.id).then(res => setPassportList(res.data))
                setChosenPassport(null);
                setIsOpen(false);
                addToast('Passport information successfully updated', {
                    appearance: 'success',
                    autoDismiss: true,
                })
                onSave && onSave();
            }).catch(err => {
                setLoadingRequest(false);
                addToast(<div><span style={{ fontWeight: 'bold' }}> There were some errors with your form.</span> <span style={{ marginTop: 6, display: 'inline-block' }}>Please Fix The Highlighted Fields Below.</span></div>, {
                    appearance: 'error',
                    autoDismiss: true,
                    placement: 'top-center'
                });
                setEditErrors(err.response.data.errors)
            })
        }
    }

    const handleDeleteSubmit = () => {
        setLoadingRequest(true);
        deleteEmployeePassport(passportId).then((res: any) => {
            setLoadingRequest(false);
            const filteredList = passportList.filter((item: any) => item.id !== res.data.id)
            setPassportList(filteredList);
            setIsDeleteOpen(false);
            addToast('Passport information Deleted', {
                appearance: 'success',
                autoDismiss: true,
            })
            onSave && onSave();
        });
    }

    const renderStatus = (date: string) => {
        return <StatusItem status={checkDate(date)} />;
    }

    return (
        <Wrapper>
            <Section
                title='Passport'
                onEditClick={() => {
                    setChosenPassport(null);
                    setIsOpen(true);
                }}
                rightText='Add'
                disabled={disabled}
            >
                {!passportList.length ?
                    <NoData>No Passport Information entries have been added.</NoData> :
                    <div className='table'>
                        <div className='table-header'>
                            <div>Passport Number</div>
                            <div>Issuing Country</div>
                            <div>Issued</div>
                            <div>Expiration</div>
                            <div>Status</div>
                            <div></div>
                        </div>
                        <div>
                            {passportList.map((item: any) => <div className='table-row' key={item.id}>
                                <div>{isEmpty(item.number) ? '-' : item.number}</div>
                                <div>{isEmpty(item.country?.name) ? '-' : item.country.name}</div>
                                <div>{item.issue_date ?? '-'}</div>
                                <div>{item.expiration_date}</div>
                                <div>{renderStatus(item.expiration_date)}</div>
                                <div className='actions'>
                                    {disabled ? null : <><StyledEditIcon onClick={() => {
                                        setEditMode(true);
                                        setChosenPassport(item);
                                        setIsOpen(true);
                                    }} />
                                        <StyledTrashIcon onClick={() => {
                                            setIsDeleteOpen(true);
                                            setPassportId(item.id);
                                        }} /></>}
                                </div>
                            </div>)}
                        </div>
                    </div>}

                <PassportInformationDelete
                    isOpen={isDeleteOpen}
                    onModalClose={() => setIsDeleteOpen(false)}
                    onDelete={handleDeleteSubmit}
                    loadingRequest={loadingRequest}
                />

                <PassportInformationEdit
                    isOpen={isOpen}
                    user={person}
                    editMode={editMode}
                    chosenPassport={chosenPassport}
                    jobData={person.active_job_detail}
                    onModalClose={() => {
                        setEditMode(false);
                        setIsOpen(false);
                        setChosenPassport(null);
                    }}
                    onSubmit={handleEditSubmit}
                    editErrors={editErrors}
                    loadingRequest={loadingRequest}
                />
            </Section>
        </Wrapper>
    );
};

export default withRouter(PassportInformation);

const StyledEditIcon = styled(EditIcon)`
  cursor: pointer;
  margin-right: 5px;
`;

const StyledTrashIcon = styled(TrashCanIcon)`
  cursor: pointer;
  margin-right: 0;
`;
