import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Controller, useForm } from 'react-hook-form';
import DialogModal from "components/Modal/Dialog";
import UniversalInput from "components/Input/UniversalInput";
import { getUserRoleInfo, getUserRoleList } from "services";
import { FORM_PATTERNS } from "../../../constants";
import SelectDropdown from "components/Dropdowns/SelectDropdown";
import EmpEditHeader from "../../Employee/editHeader";
import { useToasts } from "react-toast-notifications";
import Box from "@mui/material/Box";

import { ReactComponent as FillPersonalIcon } from "../../../assets/svg/fill-from-persona.svg";
import { ReactComponent as FillWorkIcon } from 'assets/svg/fill-from-work.svg';

const InviteModal = (props: any) => {
    const { isOpen, onModalClose, employeeId, reInvite = false, roleChange = false, chosenRoleId = null, title = null } = props;
    const { register, handleSubmit, control, setError, setValue, formState: { errors } } = useForm({
        shouldFocusError: true,
        defaultValues: {
            role: chosenRoleId,
            user_email: null
        }
    });
    const [employeeUser, setEmployeeUser] = useState<any>();
    const { addToast } = useToasts();

    useEffect(() => {
        getUserRoleInfo(employeeId).then(res => setEmployeeUser(res.data))
    }, [employeeId])

    useEffect(() => {
        setValue('role', chosenRoleId ?? employeeUser?.role)
        setValue('user_email', employeeUser?.user_email)
    }, [employeeUser])

    useEffect(() => {
        if (props.formErrors) {
            props.formErrors.forEach((item: any) => {
                if (item.field !== 'base') {
                    setError(item.field, { type: 'string', message: item.message })
                } else {
                    addToast(item.message, {
                        appearance: 'error',
                        autoDismiss: true,
                    });
                }
            })
        }
    }, [props.formErrors])

    const onSubmit = (data: any) => {
        props.onSubmit(data);
    };

    const fillWithWorkEmail = () => {
        setValue('user_email', employeeUser?.work_email)
    };

    const fillWithPersonalEmail = () => {
        setValue('user_email', employeeUser?.personal_email)
    };

    const onError = (err: any) => {
        if (err) {
            addToast(<ToastContentContainer>
                <b>There were some errors with your form.</b> Please Fix The Highlighted Fields Below
            </ToastContentContainer>, {
                appearance: 'error',
                autoDismiss: true,
                placement: 'top-center'
            });
        }
    };

    return (
        <DialogModal
            open={isOpen}
            title={title ?? (reInvite ? 'Resend Invitation' : (employeeUser?.role ? 'Change Role' : 'Invite User'))}
            onClose={() => onModalClose()}
            actionButton={handleSubmit(onSubmit, onError)}
            withButtons
            cancelButtonText='CANCEL'
            actionButtonText='SAVE'
            actionLoading={props.loadingRequest}
            nominalHeader={
                employeeUser && <EmpEditHeader
                    employeeName={`${employeeUser.first_name} ${employeeUser.last_name}`}
                    avatarUuid={employeeUser.uuid}
                    employeeId={employeeUser.id}
                    jobData={employeeUser?.active_job_detail}
                />
            }
            fullWidth
            upperPosition
        >
            <form>
                <InfoWrapper>
                    {/*Change Role*/}
                    {employeeUser && roleChange &&
                        <div>
                            <p style={{ marginBottom: 10 }}>Select user's new role and click change role.</p>
                        </div>
                    }

                    {/*Invite*/}
                    {employeeUser && !chosenRoleId && !reInvite && !roleChange &&
                        <div>
                            <p style={{ marginBottom: 10 }}>Fill in the User Role and Sign in Email and click invite. User gets email invitation.</p>
                            <p>Once the invitation is accepted, the user becomes part of the organization.</p>
                        </div>
                    }

                    {/*Re Invite*/}
                    {employeeUser && reInvite &&
                        <div>
                            <p style={{ marginBottom: 10 }}> Check or fill in the User Role, the employee Sign in Email and click Resend Invitation. User gets email invitation.</p>
                            <p>Once the invitation is accepted, the user becomes part of the organization.</p>
                        </div>
                    }

                    {/*Chosen Role*/}
                    {employeeUser && chosenRoleId &&
                        <div>
                            <p style={{ marginBottom: 10 }}>To enable access, this employee needs to have a valid Sign in Email.</p>
                            <p>Please, fill in the employee's email and click save</p>
                        </div>
                    }
                </InfoWrapper>

                {!chosenRoleId && <InputWrapper>
                    <label>User Role<sup>*</sup></label>
                    <Controller
                        name="role"
                        control={control}
                        rules={{ required: 'User Role is required' }}
                        render={({ field: { onChange, value } }) => (
                            <SelectDropdown
                                inputPlaceholder='Select User Role'
                                renderOption={(props: any, option: any) => (
                                    <Box component="li" {...props}
                                        style={{
                                            paddingLeft: 13,
                                            borderTop: option.addLine ? '1px solid #D6D6D6' : 'none'
                                        }}
                                        key={option.id ?? props.key}
                                    >
                                        {`${option.name} (${option.user_count})`}
                                    </Box>
                                )}
                                onChange={(_event: React.SyntheticEvent<Element, Event>, newValue: any) => {
                                    onChange(newValue)
                                }}
                                value={value}
                                loadRemoteData={() => getUserRoleList(50, 1, 0, true)}
                                errorText={errors.role ? errors.role.message : ''}
                            />
                        )}
                    />
                </InputWrapper>}

                {!roleChange && <InputWrapper>
                    <SignInEmailFillWrapper>
                        <label>Sign in Email<sup>*</sup></label>
                        <div style={{ display: 'flex', alignItems: 'center', gap: 15 }}>
                            <span style={{ color: '#9C9C9C' }}>Copy From: </span>
                            <FillEmailWrapper onClick={() => fillWithWorkEmail()}>
                                <FillEmailCircleWrapper><FillWorkIcon /></FillEmailCircleWrapper>
                                <span>Work Email</span>
                            </FillEmailWrapper>

                            <FillEmailWrapper onClick={() => fillWithPersonalEmail()}>
                                <FillEmailCircleWrapper><FillPersonalIcon /></FillEmailCircleWrapper>
                                <span>Personal Email</span>
                            </FillEmailWrapper>
                        </div>
                    </SignInEmailFillWrapper>
                    <UniversalInput
                        errorText={errors.user_email ? errors.user_email?.message : ''}
                        {...register("user_email", {
                            required: 'Sign in Email is required',
                            pattern: FORM_PATTERNS.email
                        })}
                    />
                </InputWrapper>}
            </form>
        </DialogModal>
    );
};

export default InviteModal;

const ToastContentContainer = styled.div`
    & > b {
        font-family: 'Aspira Demi';
    }
`;

const InfoWrapper = styled.div`
    margin-bottom: 20px;
`;

const InputWrapper = styled.div`
    max-width: 589px;
    margin-bottom: 15px;
    
    label{
        display: inline-block;
        margin-bottom: 8px;
        margin-top: 6px;
    }
    & sup {
        color: #C54343;
    }
`;

const FillEmailWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: var(--dark-gray);

  :hover {
    div{
      background-color: #339966;
    }
    span {
      color: var(--green);
    }
  }
`;

const SignInEmailFillWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FillEmailCircleWrapper = styled.div`
  width: 23px;
  height: 23px;
  background: #b5b5b5 0% 0% no-repeat padding-box;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 2px;
  margin-right: 5px;
`;
