
import { useEffect, useState } from "react";
import styled from "styled-components";
import { withRouter } from 'react-router-dom';
import DriversInformationEdit from "./edit";
import LicenseInformationDelete from "./delete";
import Section, { StatusItem } from "../section";
import {
    createDriversLicense,
    getDriverLicence,
    deleteEmployeeLicense,
    updateDriversLicense
} from 'services'
import { checkDate } from "utils/common";
import { useToasts } from "react-toast-notifications";
import { isEmpty } from "lodash";

import { ReactComponent as EditIcon } from 'assets/svg/pen-circle.svg';
import { ReactComponent as TrashCanIcon } from 'assets/svg/trash-can-circle.svg';

const Wrapper = styled.div`
    .table{
        padding: 20px 0;
   
        .table-header{
            margin: 0px 10px;
            padding: 0 20px;
            font-weight: bold;
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
            color: #172B37;
            font-size: 14px;
            font-family: 'Aspira Demi';
            div {
                &:last-child {
                    width: 5%;
                }
                width: 27%;
            }
        }

        .table-row{
            font-size: 13px;
            margin: 0px 10px;
            padding: 13px 20px;
            border-top: 1px solid #F8F8F8;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #414141;
            :hover{
                background: #EEEEEE;
                .actions{
                    visibility: visible;
                }
            }

            div {
                width: 25%;
                &:last-child {
                    width: 5%;
                }
            }

            .actions{
                display: flex;
                visibility: hidden;
                align-items: center;
                justify-content: flex-end;
            }
        }
    }
`;

const NoData = styled.div`
    padding: 20px 25px;
    font-size: 13px;
    color: #80888D;
`;

const DriversInformation = ({ person, match, onSave, disabled }: any) => {
    const [loadingRequest, setLoadingRequest] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
    const [driversLicense, setDriversLicense] = useState<any>([]);
    const [licenseId, setLicenseId] = useState(null);
    const [chosenLicense, setChosenLicense] = useState<any>(null);
    const [editMode, setEditMode] = useState<boolean>(false);
    const { addToast } = useToasts();
    const [editErrors, setEditErrors] = useState<any>([]);

    useEffect(() => {
        if (match.params.id || person.id) {
            getDriverLicence(25, 1, match.params.id ?? person.id).then(res => setDriversLicense(res.data.list))
        }
    }, [match.params.id, person.id])

    const handleEditSubmit = (data: any) => {
        if (!editMode) {
            setLoadingRequest(true);
            createDriversLicense(data, person.id).then(res => {
                setLoadingRequest(false);
                getDriverLicence(25, 1, match.params.id ?? person.id).then(res => setDriversLicense(res.data.list))
                setIsOpen(false)
                addToast('Drivers license successfully added', {
                    appearance: 'success',
                    autoDismiss: true,
                })
                onSave && onSave();
            }).catch(err => {
                setLoadingRequest(false);
                addToast(<div><span style={{ fontWeight: 'bold' }}> There were some errors with your form.</span> <span style={{ marginTop: 6, display: 'inline-block' }}>Please Fix The Highlighted Fields Below.</span></div>, {
                    appearance: 'error',
                    autoDismiss: true,
                    placement: 'top-center'
                });
                setEditErrors(err.response.data.errors)
            });
        } else {
            setLoadingRequest(true);
            updateDriversLicense(data, chosenLicense.id).then(res => {
                setLoadingRequest(false);
                getDriverLicence(25, 1, match.params.id ?? person.id).then(res => setDriversLicense(res.data.list))
                setIsOpen(false);
                addToast('Drivers license successfully updated', {
                    appearance: 'success',
                    autoDismiss: true,
                })
                onSave && onSave();
            }).catch(err => {
                setLoadingRequest(false);
                addToast(<div><span style={{ fontWeight: 'bold' }}> There were some errors with your form.</span> <span style={{ marginTop: 6, display: 'inline-block' }}>Please Fix The Highlighted Fields Below.</span></div>, {
                    appearance: 'error',
                    autoDismiss: true,
                    placement: 'top-center'
                });
                setEditErrors(err.response.data.errors)
            })
        }

    }

    const renderStatus = (date: string) => {
        return <StatusItem status={checkDate(date)} />;
    }

    const handleDeleteSubmit = () => {
        setLoadingRequest(true);
        deleteEmployeeLicense(licenseId).then((res: any) => {
            setLoadingRequest(false);
            const filteredList = driversLicense.filter((item: any) => item.id !== res.data.id)
            setDriversLicense(filteredList);
            setIsDeleteOpen(false);
            addToast('Drivers license information Deleted', {
                appearance: 'success',
                autoDismiss: true,
            })
            onSave && onSave();
        });
    }

    return (
        <Wrapper>
            <Section
                title='Driver License'
                onEditClick={() => {
                    setEditMode(false)
                    setChosenLicense([])
                    setIsOpen(true)
                }}
                rightText='Add'
                disabled={disabled}
            >
                {!driversLicense.length ?
                    <NoData>No Drivers License Information entries have been added.</NoData> :
                    <div className='table'>
                        <div className='table-header'>
                            <div>License #</div>
                            <div>Classification</div>
                            <div>State / Province / Region</div>
                            <div>Expiration</div>
                            <div>Status</div>
                            <div></div>
                        </div>
                        <div>
                            {driversLicense.map((item: any) => <div className='table-row' key={item.id}>
                                <div>{item.number}</div>
                                <div>
                                    {item.employee_driver_classifications.map((class_item: any, index: number) =>
                                        <span key={class_item.id}>{index !== 0 && ', '}{class_item.classification.name}</span>
                                    )}
                                </div>
                                <div>{isEmpty(item.region) ? '-' : item.region}</div>
                                <div>{item.expiration_date ?? '-'}</div>
                                <div>{(item.expiration_date && renderStatus(item.expiration_date)) ?? '-'}</div>
                                <div className='actions'>
                                    {disabled ? null : <><StyledEditIcon onClick={() => {
                                        setEditMode(true);
                                        setChosenLicense(item);
                                        setIsOpen(true);
                                    }} />
                                        <StyledTrashIcon onClick={() => {
                                            setIsDeleteOpen(true);
                                            setLicenseId(item.id);
                                        }} /></>}
                                </div>
                            </div>)}
                        </div>
                    </div>}

                <LicenseInformationDelete
                    isOpen={isDeleteOpen}
                    onModalClose={() => setIsDeleteOpen(false)}
                    onDelete={handleDeleteSubmit}
                    loadingRequest={loadingRequest}
                />

                <DriversInformationEdit
                    isOpen={isOpen}
                    user={person}
                    chosenLicense={chosenLicense}
                    jobData={person.active_job_detail}
                    onModalClose={() => {
                        setEditMode(false);
                        setIsOpen(false)
                    }}
                    onSubmit={handleEditSubmit}
                    editMode={editMode}
                    editErrors={editErrors}
                    loadingRequest={loadingRequest}
                />
            </Section>
        </Wrapper>
    );
};

export default withRouter(DriversInformation);

const StyledEditIcon = styled(EditIcon)`
  cursor: pointer;
  margin-right: 5px;
`;

const StyledTrashIcon = styled(TrashCanIcon)`
  cursor: pointer;
  margin-right: 0;
`;
