import styled from "@mui/system/styled";
import { Link } from "react-router-dom";

import { ReactComponent as ProfestoLogo } from 'assets/svg/profesto_logo.svg';

const UnauthorizedHeader = () => {
  return (
    <HeaderContainer>
      <Link to='/'><ProfestoLogo /> </Link>

      <HeaderRightSide>
        <p>Have a Profesto Account? <Link to='/login'>Sign In</Link></p>
      </HeaderRightSide>
    </HeaderContainer>
  );
};

export default UnauthorizedHeader;

const HeaderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  backgroundColor: '#FFF',
  padding: '20px 60px',
  alignItems: 'center',
  justifyContent: 'space-between',
  [theme.breakpoints.down("md")]: {
    padding: 20,
  },
  [theme.breakpoints.down("sm")]: {
    padding: '20px 16px',
  },
}));

const HeaderRightSide = styled('div')(({ theme }) => ({
  fontSize: 14,
  '& > p': {
    color: '#676767',
    '& > a': {
      fontFamily: 'Aspira Demi',
      color: '#339966',
    }
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: 12,
  },
}));