import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { formatInTimeZone } from 'date-fns-tz'
import Section, { ListItem } from "../section";
import PersonalInformationEdit from './edit';
import { getEmployeeWithId, updateEmployee } from 'services';
import { useToasts } from "react-toast-notifications";
import { calculateAge } from "utils/common";

const Wrapper = styled.div`
    .section-body{
        padding: 15px;
        
        .list-item .title{
            width: 170px;
            display: inline-block;
            text-align: right;
        }
    }
    
    .viewSsn {
        cursor: pointer;
        text-decoration: underline;
        
        :hover {
            color: var(--orange);
        }
    }
`;

const PersonalInformation = ({ person, refreshEmployeeInfo, match, disabled }: any) => {
    const [loadingRequest, setLoadingRequest] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [showSsn, setShowSsn] = useState<boolean>(false);
    const [personWithId, setPersonWithId] = useState<any>();
    const { addToast } = useToasts();
    const [editErrors, setEditErrors] = useState<any>([]);

    useEffect(() => {
        if (match.params.id || person.id) {
            getEmployeeWithId(match.params.id ?? person.id).then(res => setPersonWithId(res.data))
        }
        return setShowSsn(false)
    }, [match.params.id, person.id])

    const handleEditSubmit = (data: any) => {
        setLoadingRequest(true);
        updateEmployee(person.id, data).then(res => {
            setLoadingRequest(false);
            setPersonWithId(res.data)
            refreshEmployeeInfo()
            setIsOpen(false)
            addToast(`Your changes have been saved`, { appearance: 'success', autoDismiss: true })
        }).catch(err => {
            setLoadingRequest(false);
            setEditErrors(err.response.data.errors)
        });
    };

    return (
        <Wrapper style={{ height: 'calc(100% - 15px)' }}>
            <Section style={{ height: '100%' }} title='Personal Information' onEditClick={() => setIsOpen(true)} disabled={disabled}>
                <div className='section-body'>
                    <ListItem title='First Name' value={personWithId?.first_name ?? ''} />
                    <ListItem title='Middle Name' value={personWithId?.middle_name ?? ''} />
                    <ListItem title='Last Name' value={personWithId?.last_name ?? ''} />
                    <ListItem title='Preferred Name' value={personWithId?.preferred_name ?? ''} />
                    <ListItem title='Birth Date' value={personWithId?.birth_date ?
                        formatInTimeZone(new Date(personWithId.birth_date), 'UTC', 'MM/dd/yyyy') + ` (Age: ${calculateAge(new Date(personWithId.birth_date))})` : ''} />
                    <ListItem title='Place of Birth' value={personWithId?.place_of_birth ?? ''} />
                    <ListItem title='Gender' value={personWithId?.gender?.name ?? ''} />
                    <ListItem title='Marital Status' value={personWithId?.marital_status?.name ?? ''} />
                    <ListItem title='SSN' value={personWithId?.ssn && personWithId?.ssn !== '' ?
                        <span>
                            <span style={{ width: 20 }}>{showSsn ? personWithId.ssn : 'XXX-XX-XXXX'}</span>&nbsp;
                            <span className='viewSsn' onClick={() => setShowSsn(!showSsn)}>(Click to {showSsn ? 'Hide' : 'View'})</span>
                        </span> : ''} />
                    <ListItem title='Nationality' value={personWithId?.nationality?.name ?? ''} />
                    <ListItem title='Citizenship' value={personWithId?.citizenship?.name ?? ''} />
                    <ListItem title='Preferred Language' value={personWithId?.preferred_language?.name ?? ''} />
                </div>
            </Section>

            <PersonalInformationEdit
                isOpen={isOpen}
                user={personWithId}
                jobData={person.active_job_detail}
                onModalClose={() => setIsOpen(false)}
                onSubmit={handleEditSubmit}
                editErrors={editErrors}
                loadingRequest={loadingRequest}
            />
        </Wrapper>
    );
};

export default withRouter(PersonalInformation);
