import React, { useEffect } from "react";
import styled from "styled-components";
import { useToasts } from "react-toast-notifications";
import { Controller, useForm } from 'react-hook-form';
import DialogModal from "components/Modal/Dialog";
import UniversalInput from "components/Input/UniversalInput";
import EmpEditHeader from "../../editHeader";
import SelectDropdown from "components/Dropdowns/SelectDropdown";
import { getCountryList } from "services";
import DatePicker from "components/DatePickers/DatePicker";
import { utcToZonedTime } from 'date-fns-tz';

const PassportInformationEdit = (props: any) => {
  const { addToast } = useToasts();

  const { register, handleSubmit, watch, setValue, setError, reset, control, formState: { errors } } = useForm({
    shouldFocusError: false,
    defaultValues: {
      number: null,
      issuing_country: null,
      issue_date: '',
      expiration_date: ''
    }
  });
  const { user, jobData, chosenPassport, editMode } = props;
  const watchValues = watch();

  const onSubmit = (data: any) => {
    props.onSubmit(data);
  };

  useEffect(() => {
    if (chosenPassport && editMode) {
      setValue('number', chosenPassport.number ?? null)
      setValue('issuing_country', chosenPassport.country)
      setValue('issue_date', chosenPassport.issue_date && utcToZonedTime(new Date(chosenPassport.issue_date), 'UTC'))
      setValue('expiration_date', chosenPassport.expiration_date && utcToZonedTime(new Date(chosenPassport.expiration_date), 'UTC'))
    } else reset()
  }, [chosenPassport, editMode, props.isOpen])

  useEffect(() => {
    if (props.editErrors) {
      props.editErrors.map((item: any) => setError(item.field, { type: 'string', message: item.message }))
    }
  }, [props.editErrors]);

  const onError = (err: any) => {
    if (err) {
      addToast(<ToastContentContainer>
        <b>There were some errors with your form.</b> Please Fix The Highlighted Fields Below
      </ToastContentContainer>, {
        appearance: 'error',
        autoDismiss: true,
        placement: 'top-center'
      });
    }
  };

  return (
    <DialogModal
      open={props.isOpen}
      title={`${editMode ? 'Edit' : 'Add'} Passport`}
      onClose={() => { props.onModalClose() }}
      actionButton={handleSubmit(onSubmit, onError)}
      withButtons
      cancelButtonText='CANCEL'
      actionButtonText='SAVE'
      actionLoading={props.loadingRequest}
      nominalHeader={
        <EmpEditHeader
          employeeName={`${user.first_name} ${user.last_name}`}
          avatarUuid={user.uuid}
          employeeId={user.id}
          jobData={jobData}
        />
      }
      fullWidth
    >
      <Wrapper>
        <form>
          <div className='body'>
            <div className='contact-section'>
              <div className='input-item'>
                <label>Passport Number<sup>*</sup></label>
                <UniversalInput
                  errorText={errors.number ? 'Passport Number is required' : ""}
                  {...register('number', { required: true })}
                />
              </div>

              <div className='input-item'>
                <label>Issuing Country<sup>*</sup></label>
                <Controller
                  name="issuing_country"
                  rules={{ required: true }}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <SelectDropdown
                      onChange={(_event: React.SyntheticEvent<Element, Event>, newValue: any) => onChange(newValue)}
                      value={value}
                      inputPlaceholder='Select Issuing Country'
                      loadRemoteData={() => getCountryList(300, 1)}
                      errorText={errors.issuing_country ? 'Issuing Country is required' : ''}
                    />
                  )}
                />
              </div>

              <div className='input-item'>
                <div style={{ width: 200 }}>
                  <Controller
                    name="issue_date"
                    control={control}
                    rules={{ validate: (value: any) => value === null ? 'Please enter a valid date' : value !== '' || 'Issue Date is required' }}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        required
                        selected={value}
                        onChange={onChange}
                        label='Issue'
                        errorText={errors.issue_date ? errors.issue_date.message : ''}
                      />
                    )}
                  />
                </div>
                <br />
                <div style={{ width: 200 }}>
                  <Controller
                    name="expiration_date"
                    control={control}
                    rules={{
                      required: 'Expiration Date is required',
                      validate: (value: any) => value === null ? 'Please enter a valid date' : value === '' ?
                        'Expiration Date is required' : (!watchValues.issue_date || !value) || watchValues.issue_date as any < value
                        || 'Expiration must be greater than Issued'
                    }}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        required
                        selected={value}
                        onChange={onChange}
                        label='Expiration'
                        errorText={errors.expiration_date ? errors.expiration_date.message : ''}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </Wrapper>
    </DialogModal >
  );
};

export default PassportInformationEdit;

const ToastContentContainer = styled.div`
    & > b {
        font-family: 'Aspira Demi';
    }
`;

const Wrapper = styled.div`
  .body{
     .input-item{
         max-width: 416px;
         margin-bottom: 21px;
         
         & > label{
           display: inline-block;
           margin-bottom: 6px;
           & > sup {
            color: #C54343;
          }
         }
     }
}
`;
