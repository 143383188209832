import { useEffect } from "react";
import styled from "styled-components";
import { Controller, useForm } from 'react-hook-form';
import { useToasts } from "react-toast-notifications";
import DialogModal from "components/Modal/Dialog";
import UniversalInput from "components/Input/UniversalInput";
import EmpEditHeader from "../../editHeader";
import DatePicker from "components/DatePickers/DatePicker";
import { utcToZonedTime } from 'date-fns-tz';

const EmploymentDetailsEdit = (props: any) => {
  const { addToast } = useToasts();

  const { handleSubmit, watch, setValue, control, formState: { errors } } = useForm({
    shouldFocusError: false,
    defaultValues: {
      hire_date: '',
      probation_end_date: '',
      contract_end_date: ''
    } as any
  });
  const { user, jobData } = props;
  const watchValues = watch();

  const onSubmit = (data: any) => {
    props.onSubmit(data);
  };

  useEffect(() => {
    for (const prop of Object.getOwnPropertyNames(errors)) {
      delete errors[prop];
    }
  }, [props.isOpen])

  useEffect(() => {
    if (user) {
      setValue('hire_date', user.employee_employment_detail?.hire_date ?
        utcToZonedTime(new Date(user.employee_employment_detail.hire_date), 'UTC') : '')
      setValue('probation_end_date', user.employee_employment_detail?.probation_end_date ?
        utcToZonedTime(new Date(user.employee_employment_detail.probation_end_date), 'UTC') : '')
      setValue('contract_end_date', user.employee_employment_detail?.contract_end_date ?
        utcToZonedTime(new Date(user.employee_employment_detail.contract_end_date), 'UTC') : '')
    }
  }, [user, props.isOpen])

  const contractErrors = () => {
    if (watchValues.hire_date && watchValues.contract_end_date && watchValues.hire_date >= watchValues.contract_end_date) {
      return 'Contract End Date must be greater than Hire Date'
    } else if (watchValues.probation_end_date && watchValues.contract_end_date && watchValues.probation_end_date >= watchValues.contract_end_date) {
      return 'Contract End Date must be greater than Probation End Date'
    } else {
      return ''
    }
  };

  const probationErrors = () => {
    if (watchValues.hire_date && watchValues.probation_end_date && watchValues.hire_date >= watchValues.probation_end_date) {
      return 'Probation End Date must be greater than Hire Date'
    } else {
      return ''
    }
  };

  const onError = (err: any) => {
    if (err) {
      addToast(<ToastContentContainer>
        <b>There were some errors with your form.</b> Please Fix The Highlighted Fields Below
      </ToastContentContainer>, {
        appearance: 'error',
        autoDismiss: true,
        placement: 'top-center'
      });
    }
  };

  return (
    <DialogModal
      open={props.isOpen}
      title={'Edit Employment Details'}
      onClose={() => props.onModalClose()}
      actionButton={handleSubmit(onSubmit, onError)}
      withButtons
      cancelButtonText='CANCEL'
      actionButtonText='SAVE'
      actionLoading={props.loadingRequest}
      fullWidth
      nominalHeader={
        <EmpEditHeader
          employeeName={`${user.first_name} ${user.last_name}`}
          avatarUuid={user.uuid}
          employeeId={user.id}
          jobData={jobData}
        />
      }
    >
      <Wrapper>
        <form >
          <div className='body'>
            <div className='contact-section'>
              <div className='input-item'>
                <label>Employee #</label>
                <UniversalInput
                  value={user.id}
                  disabled
                />
              </div>

              <div className='input-item' style={{ width: 200 }}>
                <Controller
                  name="hire_date"
                  control={control}
                  rules={{ validate: value => value !== null || 'Please enter a valid date' }}
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      selected={value}
                      onChange={onChange}
                      label='Hire Date'
                      errorText={errors.hire_date ? errors.hire_date.message : ''}
                    />
                  )}
                />
              </div>

              <div className='input-item' style={{ width: 200 }}>
                <Controller
                  name="probation_end_date"
                  control={control}
                  rules={{
                    validate: (value: any) => value === null ? 'Please enter a valid date' : (!watchValues.hire_date || !value) || watchValues.hire_date as any < value
                      || probationErrors(),
                  }}
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      selected={value}
                      onChange={onChange}
                      label='Probation End Date'
                      errorText={errors.probation_end_date ? errors.probation_end_date.message : ''}
                    />
                  )}
                />
              </div>

              <div className='input-item' style={{ width: 200 }}>
                <Controller
                  name="contract_end_date"
                  control={control}
                  rules={{
                    validate: (value: any) => value === null ? 'Please enter a valid date' :
                      ((!watchValues.hire_date || !value) || watchValues.hire_date as any < value) &&
                      ((!watchValues.probation_end_date || !value) || watchValues.probation_end_date as any < value)
                      || contractErrors()
                  }}
                  render={({ field: { onChange, value } }) => (

                    <DatePicker
                      selected={value}
                      onChange={onChange}
                      label='Contract End Date'
                      errorText={errors.contract_end_date ? errors.contract_end_date.message : ''}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </form>
      </Wrapper>
    </DialogModal >
  );
};

export default EmploymentDetailsEdit;

const ToastContentContainer = styled.div`
    & > b {
        font-family: 'Aspira Demi';
    }
`;

const Wrapper = styled.div`
  .body{
    .input-item{
      max-width: 200px;
      margin-bottom: 15px;
      
      & > label{
        margin-bottom: 6px;
        margin-top: 6px;
        display: inline-block;
      }
    }
  }
`;