import React, { useEffect, useState } from 'react';
import { changeUserRole, deactivateUser, getUserList } from "services";
import { GridInitialState } from "@mui/x-data-grid-pro";
import {
    GridActionsCellItem,
    GridColumns,
    GridRenderCellParams, GridState
} from "@mui/x-data-grid-premium";
import format from "date-fns/format";
import { useToasts } from "react-toast-notifications";
import Tooltip from '@mui/material/Tooltip';
import { isEmpty } from "lodash";
import Text from 'components/Text';
import DataGrid from "components/DataLists/DataGrid";
import EmployeeCard from "components/Employee/Card";
import { WarningDialog } from "components/DesignUIComponents";
import { currentUserSelector } from "redux/selectors";
import InviteModal from "./InviteModal";
import styled from "styled-components";

import { ReactComponent as InviteIcon } from 'assets/svg/invite.svg';
import { ReactComponent as ResendIcon } from 'assets/svg/resend.svg';
import { ReactComponent as RoleChangeIcon } from 'assets/svg/role_change.svg';
import { ReactComponent as DisableIcon } from 'assets/svg/disable.svg';
import { useSelector } from "react-redux";


const StyledIcon = styled.svg`
    width: 22px;
    height: 22px;
    &:hover {
        & * circle {
            fill: #396
        }
        #Invite_head {
            fill: #FFF;
        }
        path {
            fill: #FFF;
        }
    }
`;

const DisabledStyledIcon = styled(StyledIcon)`
    &:hover {
        path {
            fill: #FFF;
            stroke: #FFF;
        }
    }
`;

export const UsersList = () => {
    const [loadingRequest, setLoadingRequest] = useState<boolean>(false);
    const [userList, setUserList] = useState<any>();
    const [employeeId, setEmployeeId] = useState<number>(0);
    const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
    const { addToast } = useToasts();
    const [disableAccessOpen, setDisableAccessOpen] = useState<boolean>(false);
    const [formErrors, setFormErrors] = useState<any>([]);
    const [numberOfRecords, setNumberOfRecords] = useState<number>(0);
    const [reInvite, setReInvite] = useState<boolean>(false);
    const [roleChange, setRoleChange] = useState<boolean>(false);
    const currentUser = useSelector(currentUserSelector)

    useEffect(() => {
        refreshData()
    }, [])

    useEffect(() => {
        setFormErrors([])
    }, [editModalOpen])

    const refreshData = () => {
        getUserList().then(res => setUserList(res.data))
    }

    const initialState: GridInitialState = {
        sorting: {
            sortModel: [
                { field: 'employee', sort: 'asc' }
            ],
        },
        columns: {
            columnVisibilityModel: {}
        }
    };

    const clickNoAccess = (employee_id: number) => {
        setEmployeeId(employee_id)
        setDisableAccessOpen(true)
    }

    const clickRoleChange = (employee_id: number) => {
        setEmployeeId(employee_id)
        setRoleChange(true)
        setReInvite(false)
        setEditModalOpen(true)
    }

    const clickInvite = (employee_id: number) => {
        setEmployeeId(employee_id)
        setReInvite(false)
        setRoleChange(false)
        setEditModalOpen(true)
    }

    const clickReInvite = (employee_id: number) => {
        setEmployeeId(employee_id)
        setReInvite(true)
        setRoleChange(false)
        setEditModalOpen(true)
    }

    const handleNoAccess = () => {
        setLoadingRequest(true);
        deactivateUser(employeeId).then(res => {
            setLoadingRequest(false);
            if (res.status === 200) {
                addToast(<div>System Access has been updated successfully!</div>, {
                    appearance: 'success',
                    autoDismiss: true
                });
                refreshData()
                setDisableAccessOpen(false)
            }
        }).catch(err => {
            setLoadingRequest(false);
            addToast(<div>{err.response.data.errors[0].message}</div>, {
                appearance: 'error',
                autoDismiss: true
            });
        })
    }

    const handleInvite = (data: any) => {
        setLoadingRequest(true);
        changeUserRole(data, employeeId, roleChange).then(res => {
            setLoadingRequest(false);
            if (res.status === 200) {
                addToast(<div>Invitation sent successfully!</div>, {
                    appearance: 'success',
                    autoDismiss: true
                });
                refreshData()
                setEditModalOpen(false)
            }
        }).catch(err => { setLoadingRequest(false); setFormErrors(err.response.data.errors) });
    }

    const onGridStateChange = ({ filter: { filteredRowsLookup }, rows: { idRowsLookup } }: GridState) => {
        if (!isEmpty(filteredRowsLookup)) {
            const filteredRowsIds = Object.keys(filteredRowsLookup).filter(key => filteredRowsLookup[key]);
            let rows = Object.keys(idRowsLookup).map((key) => filteredRowsIds.includes(key) && idRowsLookup[key]).filter((row => row));
            setNumberOfRecords(rows.length)
        } else {
            setNumberOfRecords(userList.length)
        }
    };

    const renderAvatarCell = (params: GridRenderCellParams, type: string) => {
        return <>
            <EmployeeCard fontSize={14} employee={params.row[type]} />
            {params.row.role_id_name !== 'owner' && params.row.id !== currentUser.employee.id && <div style={{ display: 'flex', marginLeft: 10 }}>
                {(!params.row.status_id || params.row.status_id === 'inactive') &&
                    <GridActionsCellItem
                        className="actionButton"
                        icon={
                            <Tooltip title='Invite User' disableInteractive arrow>
                                <StyledIcon as={InviteIcon} />
                            </Tooltip>
                        }
                        label="Invite"
                        onClick={() => { clickInvite(params.row.id) }}
                        sx={{ padding: '3.5px', '&:hover': { backgroundColor: 'transparent' } }}
                    />
                }
                {params.row.status_id === 'invited' &&
                    <GridActionsCellItem
                        className="actionButton"
                        icon={
                            <Tooltip title='Resend Invitation' disableInteractive arrow>
                                <StyledIcon as={ResendIcon} />
                            </Tooltip>
                        }
                        label="Resend"
                        onClick={() => clickReInvite(params.row.id)}
                        sx={{ padding: '3.5px', '&:hover': { backgroundColor: 'transparent' } }}
                    />
                }
                {(params.row.status_id === 'invited' || params.row.status_id === 'active') &&
                    <GridActionsCellItem
                        className="actionButton"
                        icon={
                            <Tooltip title='Change Role' disableInteractive arrow>
                                <StyledIcon as={RoleChangeIcon} />
                            </Tooltip>
                        }
                        label="Role Change"
                        onClick={() => clickRoleChange(params.row.id)}
                        sx={{ padding: '3.5px', '&:hover': { backgroundColor: 'transparent' } }}
                    />
                }
                {(params.row.status_id === 'invited' || params.row.status_id === 'active') &&
                    <GridActionsCellItem
                        className="actionButton"
                        icon={
                            <Tooltip title='Disable Access' disableInteractive arrow>
                                <DisabledStyledIcon as={DisableIcon} />
                            </Tooltip>
                        }
                        label="Disable"
                        onClick={() => clickNoAccess(params.row.id)}
                        sx={{ padding: '3.5px', '&:hover': { backgroundColor: 'transparent' } }}
                    />
                }
            </div>}
        </>
    };

    const columns = React.useMemo<GridColumns<any>>(() => [
        {
            field: 'employee', headerName: 'Employee',
            renderCell: (params) => renderAvatarCell(params, 'employee'),
            valueGetter: ({ value }) => `${value.first_name} ${value.last_name}`,
            flex: 1.5
        },
        { field: 'status', headerName: 'User Status', flex: 1 },
        { field: 'role_name', headerName: 'User Role', flex: 1 },
        {
            field: 'last_login_date',
            headerName: 'Last Login',
            type: 'date',
            valueGetter: ({ value }) => value && new Date(value),
            valueFormatter: ({ value }) => value && format(new Date(value), 'P'),
            flex: 1
        },
        {
            field: 'join_date',
            headerName: 'Date of Joining',
            type: 'date',
            valueGetter: ({ value }) => value && new Date(value),
            valueFormatter: ({ value }) => value && format(new Date(value), 'P'),
            flex: 1
        }
    ], []);

    return (
        <>
            <div style={{ display: 'flex', alignContent: 'center' }}>
                <div style={{ width: 3, height: 25, backgroundColor: '#339966', float: 'left', marginRight: 15, }} />
                <Text type="title">Employees <span style={{ opacity: 0.6 }}>&nbsp;({numberOfRecords})</span></Text>
            </div>

            <div style={{ marginTop: 20, flex: 1, backgroundColor: '#fff', padding: '18px 15px', borderRadius: 6 }}>
                <div style={{ display: 'flex', height: '100%' }}>
                    {userList &&
                        <DataGrid
                            name='user_list'
                            saveGridState
                            onStateChange={onGridStateChange}
                            rows={userList}
                            columns={columns}
                            initialState={initialState}
                        />}
                </div>
            </div>

            {disableAccessOpen && <WarningDialog
                title='Disable Access'
                isOpen={disableAccessOpen}
                onClose={() => setDisableAccessOpen(false)}
                onAction={handleNoAccess}
                cancelButtonText='NO, KEEP CURRENT ACCESS'
                actionButtonText='YES, DISABLE ACCESS'
                warningText='Are you sure you want to disable access for this employee?'
                actionLoading={loadingRequest}
            />}

            {editModalOpen && <InviteModal
                isOpen={editModalOpen}
                onModalClose={() => setEditModalOpen(false)}
                employeeId={employeeId}
                formErrors={formErrors}
                onSubmit={handleInvite}
                reInvite={reInvite}
                roleChange={roleChange}
                loadingRequest={loadingRequest}
            />}
        </>
    )
}
