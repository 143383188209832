import { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import LoadingButton from "@mui/lab/LoadingButton";
import MainErrorBox from 'components/error/mainError';
import UniversalInput from "components/Input/UniversalInput";
import FormContainerTemplate from "../FormContainer";
import {
    confirmInviteTokenService,
    getCurrentUser,
    checkInviteToken
} from 'services';
import { FORM_PATTERNS } from "../../../constants";
import { saveStorageObject } from "utils/storage";
import { setAuthorizationToken } from 'services/mainAxios';
import { setDomain, setCurrentUser } from "redux/authSlice";

import { ReactComponent as ProfestoLogo } from 'assets/svg/profesto_logo.svg';
import { ReactComponent as ToliaIcon } from 'assets/svg/tolia.svg'
import { ReactComponent as CloseIcon } from 'assets/svg/close-icon_thin.svg'


type Inputs = {
    password: any,
    confirmPassword: string
};

const StatusImg = (data: any) => {
    return data.status ? <StyledToliaIcon /> : <CloseIcon />
};

const Invite = () => {
    const history = useHistory();
    const { addToast } = useToasts();
    const dispatch = useDispatch();
    const { register, trigger, handleSubmit, watch, formState: { errors } } = useForm<Inputs>({
        defaultValues: {
            password: '',
            confirmPassword: ''
        }
    });

    const location = useLocation();
    let params = queryString.parse(location.search);
    const [companyName, setCompanyName] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorType, setErrorType] = useState<string>('error');
    const [errorText, setErrorText] = useState<string>('');
    const [errorDesc, setErrorDesc] = useState<string>('');

    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [isInvalid, setIsInvalid] = useState(false);

    const watchPassword = watch("password");
    const watchConfirmPassword = watch("confirmPassword");
    const passwordRef = useRef({});
    passwordRef.current = watch("password", "");

    const onSubmit = (data: Inputs) => {
        setIsLoading(true);
        setErrorText('');
        confirmInviteTokenService(params['token'], data.password,).then(res => {
            if (res.data.token) {
                setTimeout(() => {
                    addToast("Your password has been updated successfully!", {
                        appearance: 'success',
                        autoDismiss: true
                    });
                }, 200);
                setAuthorizationToken(res.data.token);
                saveStorageObject('token', res.data.token);
                saveStorageObject('refresh_token', res.data.refresh_token);
                getCurrentUser().then(res => {
                    dispatch(setDomain(res.data.company.id));
                    dispatch(setCurrentUser(res.data));
                });
                history.push({
                    pathname: '/people',
                })
            }
            setIsLoading(false);
        }).catch((err) => {
            setErrorText(err.response.data.errors[0].message);
            setErrorType('error');
            setIsLoading(false);
        })
    };

    useEffect(() => {
        checkInviteToken(params['token']).then((res: any) => {
            setCompanyName(res.data.company_name)
        }).catch((err) => {
            setIsInvalid(true);
            setErrorText(err.response.data.errors[0].message);
            if (err.response.data.errors[0].code === 6) {
                setErrorDesc("It has been more than 24 hours since we sent your account activation email.");
            } else {
                setErrorDesc("Link is Invalid");
            }
        })
    }, []);

    useEffect(() => {
        if (watchConfirmPassword) {
            trigger("confirmPassword");
        }
    }, [watchPassword, trigger, watchConfirmPassword]);

    if (isInvalid) {
        return <FormContainerTemplate>
            <FormTopContainer>
                <ProfestoLogo
                    width={274}
                    height={56}
                    id='profesto_logo'
                />
            </FormTopContainer>
            <MainErrorBox
                type={errorType}
                text={errorText}
            />
            {errorDesc ? <p
                style={{ margin: '40px', textAlign: 'left', fontSize: 16, color: '#000' }}>
                {errorDesc}
            </p> : null}
        </FormContainerTemplate>
    };

    return (
        <FormContainerTemplate>
            <FormTopContainer>
                <ProfestoLogo
                    width={274}
                    height={56}
                    id='profesto_logo'
                />
                {companyName && <h3>
                    Welcome to Profesto in {companyName}!<br />
                    Get started by setting a secure password.
                </h3>}
            </FormTopContainer>
            {errorText && <MainErrorBox
                type={errorType}
                text={errorText}
            />}
            <form onSubmit={handleSubmit(onSubmit)}>
                <div style={{ marginBottom: 22 }}>
                    <UniversalInput
                        maxLength='128'
                        placeholder='Password'
                        size='medium'
                        withEyeAdornment={true}
                        onEyeAdornmentClick={() => setShowPassword(!showPassword)}
                        type={showPassword ? `text` : 'password'}
                        errorText={errors.password ? errors.password.message : '' as any}
                        {...register("password", {
                            maxLength: 128,
                            required: "Please Enter A Valid Password", validate: (value) =>
                                FORM_PATTERNS.uppercase.value.test(value) &&
                                FORM_PATTERNS.lowercase.value.test(value) &&
                                FORM_PATTERNS.oneDigit.value.test(value) &&
                                FORM_PATTERNS.minEightChars.value.test(value)
                        })}
                    />
                </div>
                <div style={{ marginBottom: 22 }}>
                    <UniversalInput
                        maxLength='128'
                        size='medium'
                        placeholder='Confirm Password'
                        withEyeAdornment={true}
                        onEyeAdornmentClick={() => setShowPassword(!showPassword)}
                        errorText={errors.confirmPassword ? errors.confirmPassword.message : ''}
                        type={showPassword ? `text` : 'password'} {...register("confirmPassword", {
                            required: "Please Enter A Valid Password",
                            maxLength: 128,
                            validate: value => {
                                if (watchPassword !== value) {
                                    return "Passwords Do Not Match";
                                }
                            }
                        })}
                    />
                </div>
                {watchPassword && <>
                    <PasswordStatusContainer>
                        <span>
                            8 or More Character
                        </span>
                        <StatusImg status={watchPassword && watchPassword.match(FORM_PATTERNS.minEightChars.value)} />
                    </PasswordStatusContainer>
                    <PasswordStatusContainer>
                        <span>Uppercase </span>
                        <StatusImg status={watchPassword && watchPassword.match(FORM_PATTERNS.uppercase.value)} />
                    </PasswordStatusContainer>
                    <PasswordStatusContainer>
                        <span> Lowercase </span>
                        <StatusImg status={watchPassword && watchPassword.match(FORM_PATTERNS.lowercase.value)} />
                    </PasswordStatusContainer>
                    <PasswordStatusContainer>
                        <span>At Least 1 Number </span>
                        <StatusImg status={watchPassword && watchPassword.match(FORM_PATTERNS.oneDigit.value)} />
                    </PasswordStatusContainer>
                    <PasswordStatusContainer>
                        <span>Passwords Match </span>
                        <StatusImg status={watchPassword === watchConfirmPassword} />
                    </PasswordStatusContainer>
                </>}
                <LoadingButton
                    loading={isLoading}
                    sx={{ marginTop: 3, fontSize: 14, padding: 2 }}
                    fullWidth
                    type="submit"
                    variant="contained"
                >
                    Set Password
                </LoadingButton>
            </form>
        </FormContainerTemplate>
    );
};

export default Invite;

const StyledToliaIcon = styled(ToliaIcon)`
    & path {
        fill: #339966;
    }
`;

const FormTopContainer = styled.div`
  margin-bottom: 44px;
  text-align: center;
  p {
    color: var(--dark-gray);
    margin-top: 20px;
    }
  h3 {
    color: var(--dark-gray);
    max-width: 80%;
    font-family: 'Aspira Regular';
    font-weight:  900;
    font-size: 1.4rem;
    line-height: 2.2rem;
    margin: 20px auto 0;
    }
`;

const PasswordStatusContainer = styled.div`
    span {
      margin-right: 8px;
      margin-bottom: 6px;
      display: inline-block;
      width: 130px;
    }
`;