import Dialog from "components/Modal/Dialog";
import styled from "styled-components";

import { ReactComponent as TrashCanCircles } from 'assets/svg/trash-can_circles.svg';

interface IDeleteModal {
    closeModal: () => void,
    isOpen: boolean,
    handleDelete: () => void,
    actionLoading: boolean,
};

export const DeleteAvatar = ({ closeModal, isOpen, handleDelete, actionLoading }: IDeleteModal) => {
    return (
        <Dialog
            open={isOpen}
            onClose={() => closeModal()}
            title={'Delete Photo'}
            withButtons
            cancelButtonText="CANCEL"
            actionButtonText="DELETE PHOTO"
            actionButton={() => handleDelete()}
            actionLoading={actionLoading}
            fullWidth
        >
            <DeleteModalContainer>
                <div>
                    <TrashCanCircles />
                    <p>Are you sure you want to delete this photo?</p>
                </div>
            </DeleteModalContainer>
        </Dialog>
    )
};

const DeleteModalContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    & > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        & > svg {
            margin-bottom: 27px;
        }
        & > p {
            color: #676767;
            font-size: 14px;
        }
    }
`;