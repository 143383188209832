import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useForm, Controller } from 'react-hook-form';
import { useToasts } from "react-toast-notifications";
import { PatternFormat } from 'react-number-format';
import { calculateAge } from 'utils/common';
import UniversalInput from "components/Input/UniversalInput";
import EmpEditHeader from "../../editHeader";
import DialogModal from "components/Modal/Dialog";
import EnumDropdown from "components/Dropdowns/EnumDropdown";
import {
  createNationality,
  createLanguage,
  getCountryList,
  getLanguages,
  getNationalities,
  getEnum
} from "services";
import SelectDropdown from "components/Dropdowns/SelectDropdown";
import SelectWithAdd from "components/Dropdowns/SelectWithAdd";
import DatePicker from "components/DatePickers/DatePicker";
import { utcToZonedTime } from 'date-fns-tz';
import { FORM_PATTERNS } from "../../../../constants";

const PersonalInformationEdit = (props: any) => {
  const { addToast } = useToasts();
  const { register, handleSubmit, watch, setValue, setError, setFocus, control, formState: { errors } } = useForm({});
  const watchBirthdayValue = watch("birth_date");
  const { user, jobData } = props;
  const [genderData, setGenders] = useState([]);
  const [maritalStatusData, setMaritalStatus] = useState([]);

  useEffect(() => {
    getEnum(25, 1, 'Enum::Gender').then(res => setGenders(res.data.list));
    getEnum(25, 1, 'Enum::MaritalStatus').then(res => setMaritalStatus(res.data.list));
  }, [])

  useEffect(() => {
    if (user) {
      setValue('first_name', user.first_name);
      setValue('last_name', user.last_name);
      setValue('middle_name', user.middle_name);
      setValue('preferred_name', user && user.preferred_name);
      setValue('ssn', user && user.ssn?.replaceAll('-', ''));
      setValue('place_of_birth', user && user.place_of_birth)
      setValue('birth_date', user && user.birth_date ? utcToZonedTime(new Date(user.birth_date), 'UTC') : '')
      setValue('gender_id', user?.gender?.id ?? '')
      setValue('nationality', user?.nationality)
      setValue('citizenship', user && user.citizenship)
      setValue('preferred_language', user?.preferred_language)
      setValue('marital_status_id', user?.marital_status?.id ?? '')
      setValue('employee_languages', user?.employee_languages)
    }

  }, [user, props.isOpen])

  const onSubmit = (data: any) => {
    if (!data.birth_date) {
      setValue('birth_date', null);
    }

    props.onSubmit(data);
  };

  useEffect(() => {
    if (props.editErrors) {
      props.editErrors.forEach((item: any) => setError(item.field, { type: 'string', message: item.message }));
      setFocus(props.editErrors?.[0]?.field as any)
    }
  }, [props.editErrors])


  useEffect(() => {
    for (const prop of Object.getOwnPropertyNames(errors)) {
      delete errors[prop];
    }
    if (user && user.employee_languages) {
      setValue('employee_languages', user.employee_languages)
    }

  }, [props.isOpen]);

  const onError = (err: any) => {
    if (err) {
      addToast(<ToastContentContainer>
        <b>There were some errors with your form.</b> Please Fix The Highlighted Fields Below
      </ToastContentContainer>, {
        appearance: 'error',
        autoDismiss: true,
        placement: 'top-center'
      });
    }
  };

  return (
    <DialogModal
      open={props.isOpen}
      title={`Edit Personal Information`}
      onClose={() => { props.onModalClose() }}
      actionButton={handleSubmit(onSubmit, onError)}
      withButtons
      cancelButtonText='CANCEL'
      actionButtonText='SAVE'
      actionLoading={props.loadingRequest}
      nominalHeader={
        <EmpEditHeader
          employeeName={`${user?.first_name} ${user?.last_name}` ?? ''}
          avatarUuid={user?.uuid ?? null}
          employeeId={user?.id ?? null}
          jobData={jobData}
        />
      }
      fullWidth
    >
      <Wrapper>
        <form>
          <div className='body'>

            <div className='top'>
              <div className='input-item'>
                <label>First Name<sup>*</sup></label>
                <UniversalInput
                  errorText={
                    errors.first_name ? 'First Name is required' : ""
                  }
                  {...register('first_name', { required: true })}
                />
              </div>

              <div className='input-item'>
                <label>Middle Name</label>
                <UniversalInput
                  {...register("middle_name")}
                />
              </div>

              <div className='input-item'>
                <label>Last Name<sup>*</sup></label>
                <UniversalInput
                  errorText={
                    errors.last_name ? 'Last Name is required' : ""
                  }
                  {...register("last_name", { required: true })}
                />
              </div>

              <div className='input-item'>
                <label>Preferred Name</label>
                <UniversalInput
                  {...register("preferred_name")}
                />
              </div>

            </div>

            <div className='input-item'>
              <label>Birth Date</label>
              <div className='birth-date'>
                <div style={{ width: 200 }}>
                  <Controller
                    name="birth_date"
                    control={control}
                    rules={{ validate: value => value !== null || 'Please enter a valid date' }}
                    render={({ field: { onChange, value, ref } }) => (
                      <DatePicker
                        ref={ref}
                        selected={value}
                        onChange={onChange}
                        errorText={errors.birth_date ? errors.birth_date.message : ''}
                      />
                    )}
                  />
                </div>

                {watchBirthdayValue && <span className='age'>(Age: {calculateAge(watchBirthdayValue)})</span>}
              </div>
            </div>

            <div className='input-item'>
              <label>Place of Birth</label>
              <UniversalInput
                {...register('place_of_birth')}
              />
            </div>

            <div className='input-item'>
              <label>Gender</label>
              <Controller
                name="gender_id"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <EnumDropdown
                    placeholder='Select Gender'
                    onChange={onChange}
                    errorText={errors.gender_id ? 'Please Choose Gender' : ''}
                    value={value}
                    options={genderData}
                  />
                )}
              />
            </div>

            <div className='input-item'>
              <label>Marital Status</label>
              <Controller
                name="marital_status_id"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <EnumDropdown
                    placeholder='Select Marital Status'
                    onChange={onChange}
                    errorText={errors.marital_status_id ? 'Please Choose Marital Status' : ''}
                    value={value}
                    options={maritalStatusData}
                  />
                )}
              />
            </div>

            <div>
              <div className='input-item'>
                <label>SSN</label>
                <Controller
                  name="ssn"
                  control={control}
                  rules={{
                    pattern: FORM_PATTERNS.ssn
                  }}
                  render={({ field: { onChange, value } }) => (
                    <PatternFormat
                      type='tel'
                      format="###-##-####"
                      mask={"_"}
                      valueIsNumericString
                      value={value}
                      onValueChange={(e) => onChange(e.value)}
                      className={`form_control ${errors.ssn ? 'error-input' : ''}`}
                    />
                  )}
                />
                <span style={{
                  color: 'var(--red)',
                  marginTop: 6,
                  fontSize: 12,
                  display: 'inline-block'
                }}>{errors.ssn ? errors.ssn.message : ''} </span>
              </div>
            </div>

            <div className='input-item'>
              <label>Nationality</label>
              <Controller
                name="nationality"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <SelectWithAdd
                    inputPlaceholder='Select Nationality'
                    name='nationality'
                    inputValue={value}
                    loadRemoteData={() => getNationalities(100, 1)}
                    createRequest={createNationality}
                    onChange={onChange}
                  />
                )}
              />
            </div>

            <div className='input-item'>
              <label>Citizenship</label>
              <Controller
                name="citizenship"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <SelectDropdown
                    inputPlaceholder='Select Citizenship'
                    onChange={(_event: React.SyntheticEvent<Element, Event>, newValue: any) => onChange(newValue)}
                    value={value}
                    loadRemoteData={() => getCountryList(300, 1)}
                  />
                )}
              />
            </div>

            <div className='input-item'>
              <label>Preferred Language</label>
              <Controller
                name="preferred_language"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <SelectWithAdd
                    inputPlaceholder='Select Preferred Language'
                    name='preferred_language'
                    inputValue={value}
                    loadRemoteData={() => getLanguages(200, 1)}
                    createRequest={createLanguage}
                    onChange={onChange}
                  />
                )}
              />
            </div>
          </div>
        </form>
      </Wrapper>
    </DialogModal>
  );
};

export default PersonalInformationEdit;


const ToastContentContainer = styled.div`
    & > b {
        font-family: 'Aspira Demi';
    }
`;

const Wrapper = styled.div`
  .body{
    .form_control {
      width: 100%;
      border-radius: 4px;
      border: 1px solid #D6D6D6;
      padding: 11px 13px;
      
      &:focus, &:hover {
        border-color: #99CC33 !important;
      }
  }

  .error-input {
    border-color: var(--red);
  };

  .birth-date{
    display: flex;

    span.age{
      margin-top: 12px;
      margin-left: 10px;
    }
  }

  .input-item{
    max-width: 416px;
    margin-bottom: 15px;
    
    & > label{
      display: inline-block;
      margin-bottom: 6px;
      margin-top: 6px;
      & > sup {
        color: #C54343;
      }
    }
  }
  };
`;
