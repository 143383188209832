import { Link } from "react-router-dom";
import styled from "styled-components";

const Wrapper = styled.div`
  padding: 4rem 2rem;
`;

const Home = () => {
  return (
    <Wrapper>
          <Link to='/signup'>signup</Link> <br/>
          <Link to='/login'>login</Link><br/>
          <Link to='/recover'>recover</Link><br/>
          <Link to='/locked'>unlock</Link><br/>
          <Link to='/reset'>reset</Link>
    </Wrapper>
  );
};

export default Home;
