import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import Section, { ListItem } from "../section";
import AddressInformationEdit from './edit';
import { getEmployeeAddress, updateEmployeeAddress } from 'services'
import { useToasts } from "react-toast-notifications";

const Wrapper = styled.div`
    .section-body{
        padding: 15px;

        h4{
            color: #172B37;
            font-weight: bold;
            font-size: 14px;
        }
        
        .list-item .title{
            width: 170px;
            display: inline-block;
            text-align: right;
        }
    }
`;

const NoData = styled.div`
    padding-top: 13px;
    padding-left: 35px;
    font-size: 13px;
    color: #80888D;
    padding-bottom: 20px;
`;

const AddressInformation = ({ person, match, disabled }: any) => {
    const [loadingRequest, setLoadingRequest] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [AddressInfo, setAddressInfo] = useState<any>([]);
    const [editErrors, setEditErrors] = useState<any>([]);
    const { addToast } = useToasts();

    let homeAddress = AddressInfo?.find((item: any) => item.address_type.id_name === 'home_address')
    let mailingAddress = AddressInfo?.find((item: any) => item.address_type.id_name === 'mailing_address')

    useEffect(() => {
        if (match.params.id || person.id) {
            getEmployeeAddress(match.params.id ?? person.id).then(res => setAddressInfo(res.data))
        }
    }, [match.params.id, person.id]);

    const handleEditSubmit = (data: any) => {
        setLoadingRequest(true);
        updateEmployeeAddress(person.id, data).then(res => {
            setLoadingRequest(false);
            setAddressInfo(res.data)
            setIsOpen(false);
            addToast(`Your changes have been saved`, { appearance: 'success', autoDismiss: true })
        }).catch(err => {
            setLoadingRequest(false);
            setEditErrors(err.response.data.errors);
        });
    }

    return (
        <Wrapper style={{ height: 'calc(100% - 15px)' }}>
            <Section style={{ height: '100%' }} title='Address' onEditClick={() => setIsOpen(true)} disabled={disabled}>
                <div className='section-body'>

                    <div>
                        <h4>Home Address</h4>
                        <div>
                            {!homeAddress && <NoData style={{ paddingBottom: 85 }}>No Home Address Information entries have been added.</NoData>}
                            {homeAddress &&
                                <div>
                                    <ListItem title='Country' value={homeAddress.country.name} />
                                    <ListItem title='Address Line 1' value={homeAddress.address} />
                                    <ListItem title='Address Line 2' value={homeAddress.address_details} />
                                    <ListItem title='City' value={homeAddress.city} />
                                    {homeAddress.country && homeAddress.country.iso === 'US' ?
                                        <ListItem title='State / Province / Region' value={homeAddress.state && homeAddress.state.name} /> :
                                        <ListItem title='State / Province / Region' value={homeAddress.region} />}
                                    <ListItem title='Zip / Postal Code' value={homeAddress.postal_code} />
                                </div>
                            }
                        </div>

                        <br />

                        <h4>Mailing Address</h4>
                        <div>
                            {!mailingAddress && <NoData>No Mailing Address Information entries have been added.</NoData>}
                            {mailingAddress &&
                                <div>
                                    <ListItem title='Country' value={mailingAddress.country.name} />
                                    <ListItem title='Address Line 1' value={mailingAddress.address} />
                                    <ListItem title='Address Line 2' value={mailingAddress.address_details} />
                                    <ListItem title='City' value={mailingAddress.city} />
                                    {mailingAddress.country && mailingAddress.country.iso === 'US' ?
                                        <ListItem title='State / Province / Region'
                                            value={mailingAddress.state && mailingAddress.state.name} /> :
                                        <ListItem title='State / Province / Region' value={mailingAddress.region} />}
                                    <ListItem title='Zip / Postal Code' value={mailingAddress.postal_code} />
                                </div>
                            }
                        </div>
                    </div>

                </div>
            </Section>

            <AddressInformationEdit
                isOpen={isOpen}
                user={person}
                jobData={person.active_job_detail}
                editErrors={editErrors}
                AddressInfoData={AddressInfo}
                onModalClose={() => setIsOpen(false)}
                onSubmit={handleEditSubmit}
                loadingRequest={loadingRequest}
            />
        </Wrapper>
    );
};

export default withRouter(AddressInformation);
