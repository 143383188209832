
export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const FORM_PATTERNS = {
  required: {
    value: true,
    message: "Required field",
  },
  email: {
    value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: "Please enter valid Email",
  },
  phone: {
    value: /^[0-9]*$/,
    message: "Incorrect phone format",
  },
  password: {
    value: /^(?=(.*[a-zA-Z]){1})(?=(.*\d)).{6,64}$/,
    message: "min 8 symbols",
  },
  lowercase:{
    value: /(?=.*[a-z])/,
    message: 'no lowercase digits'
  },
  uppercase:{
    value: /(?=.*[A-Z])/,
    message: 'no uppercase digits'
  },
  oneDigit: {
    value: /(?=.*\d)/,
    message: 'no digits'
  },
  minEightChars:{
    value: /^.{8,}$/,
    message: 'min 8 chars'
  },
  domain:{
    value: /^[a-z0-9_\-]+$/,
    message: 'This Domain is not valid. Only letters and numbers are allowed.'
  },
  gpa:{
    value: /^([1-9]?\d|4)$/,
    message: 'GPA Must Be Between 0 and 4' 
  },
  ssn: {
    value: /^\d{9}$/,
    message: 'SSN must be 9 characters' 
  }
};
