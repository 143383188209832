import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import queryString from "query-string";
import { useLocation, useHistory } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { personActiveTabSelector } from '../../redux/selectors/index'
import { setActiveTab } from '../../redux/personSlice';
import 'react-tabs/style/react-tabs.css';
import Personal from "./personal";
import Job from './job';
import TimeOff from "./timeOff";
import Documents from "./documents";
import Emergency from "./emergency";
import OnboardingInfoBox from "./OnboardingInfoBox";

const RightCard = ({ person, refreshEmployeeInfo, disabled }: any) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const activeTab = useSelector(personActiveTabSelector);

  const queryParams: any = queryString.parse(location.search);
  const mapTabToIndex: { [key: string]: number } = { personal: 0, job: 1, documents: 2, emergency: 3 };

  return (
    <Wrapper>
      <div className='body'>
        {person.onboarding?.onboarding_status?.id_name ?
          <OnboardingInfoBox person={person} refreshEmployeeInfo={refreshEmployeeInfo} />
          : null}
        <Tabs
          selectedIndex={mapTabToIndex[queryParams?.tab] ?? activeTab}
          onSelect={index => { dispatch(setActiveTab(index)); history.push(`?tab=${Object.keys(mapTabToIndex)[index]}`) }}
        >
          <TabList>
            <Tab>Personal</Tab>
            <Tab>Job</Tab>
            {/*<Tab>Time Off</Tab>*/}
            <Tab>Documents</Tab>
            <Tab>Emergency</Tab>
          </TabList>
          <TabPanel>
            <Personal person={person} refreshEmployeeInfo={refreshEmployeeInfo} disabled={disabled} />
          </TabPanel>
          <TabPanel>
            <Job person={person} refreshEmployeeInfo={refreshEmployeeInfo} disabled={disabled} />
          </TabPanel>
          {/*<TabPanel>*/}
          {/*  <TimeOff employeeInfo={person} disabled={disabled} />*/}
          {/*</TabPanel>*/}
          <TabPanel>
            <Documents person={person} disabled={disabled} refreshEmployeeInfo={refreshEmployeeInfo} />
          </TabPanel>
          <TabPanel>
            <Emergency person={person} disabled={disabled} />
          </TabPanel>
        </Tabs>
      </div>
    </Wrapper>
  );
};

export default RightCard;

const Wrapper = styled.div`
  flex: 0 0 80%;
  max-width: 80%;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 12px;
    
  .body{
    background: #fff;
    padding: 15px;
    border-radius: 6px;
  
    .react-tabs__tab-list{
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #AEAEAE;
      margin-bottom: 15px;
  
      .react-tabs__tab{
        flex: 1;
        text-align: center;
        padding: 10.5px 10px;
        color: #00101A;
      }
  
      .react-tabs__tab:focus{
        box-shadow: none;
        outline: none !important;
      }
  
      .react-tabs__tab:focus:after{
        display: none;
      }
  
      .react-tabs__tab--selected{
        padding-bottom: 9px !important;
        border: none;
        color: #FF9933;
        border-bottom: 2px solid #FF9933;
      }
    }
  }
`;