import { renderToStaticMarkup } from "react-dom/server";
import styled from "styled-components";
import { ReactComponent as MailIcon } from 'assets/svg/reg-mail.svg';
import { ReactComponent as ArcIcon } from 'assets/svg/arc.svg';

const svgString = encodeURIComponent(renderToStaticMarkup(<ArcIcon />));

const EmailSent = () => {
  return (
    <PageContainer>
      <MailIcon style={{ maxWidth: 327, maxHeight: 184, marginTop: '8%' }} />
      <ContentContainer>
        <h4>Check your Email</h4>
        <br />
        <p>Almost there! Please check your email for a verification link.</p>
        <br />
        <p> We've sent a confirmation email to the address that you provided.</p>
        <p>Your account needs to be verified before you can access it. <br /> Your verification link will expire in 24 hours.</p>
        <br />
        <p style={{ fontWeight: 'bold' }}>If you do not see the email in your inbox in a few minutes, <br /> please check your “junk mail” folder or “spam” folder.</p>
      </ContentContainer>
    </PageContainer>
  );
};

export default EmailSent;

const PageContainer = styled('div')(({ theme }) => ({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  alignItems: "center",
  background: `#F8F8F7 url('data:image/svg+xml;utf8, ${svgString}') left center/cover no-repeat`,
  backgroundSize: 450,
  [theme.breakpoints.down('lg')]: {
    backgroundSize: 350,
  },
}));

const ContentContainer = styled('div')(({ theme }) => ({
  padding: 16,
  "& > p": {
    lineHeight: 1.5,
    fontSize: 18,
    color: "#676767",
    textAlign: "center",
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
  "& > h4": {
    fontSize: 30,
    textAlign: "center",
    color: "#676767",
    fontFamily: 'Aspira Demi',
    marginTop: "30px",
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
  },
  "& > span": { color: "black", fontWeight: 900 }
}));

